import React from 'react';
import { Frequency, ProductCodeContract } from '../api_client';

export enum DisplayMode {
    Analytics = 1,
    Simplified = 2
}

export const SettingsContext = React.createContext({
    locale: 'se',
    currency: 'SEK',
    availableCurrencies: ['USD'],
    setCurrency: (currency: string) => { },
    isLightTheme: true,
    setTheme: (isLight: any) => { },
    // currentFullScreenComponent: undefined,
    // previousFullScreenComponent: undefined,
    // gotoToFullScreenComponent: (newComponent) => {},
    // gotoPreviousToFullScreenComponent: () => {},
    errorMessage: undefined,
    setErrorMessage: () => { },
    rateRelativeSek: 1,
    currentScenarioSet: { name: 'Loading...', id: '' },
    setCurrentScenarioSet: (set: any) => { },
    availableScenarioSets: [{ name: 'Loading...', id: '' }],
    availableStressScenarioSets: [{ name: 'Loading...', id: '' }],
    mappedFunds: [] as ProductCodeContract[],
    resetDatabase: () => { },
    percentiles: [{ pecentile: 0.5, enabled: true }],
    setPercentiles: (newVal: any) => { },
    chartSettings: { fanFilled: true, resultAsValueOfToday: false, horizonInYears: 30 },
    setChartSettings: (newVal: any) => { },
    observationFrequency: Frequency.Annual,
    setObservationFrequency: (frequency: Frequency) => { },
    displayMode: DisplayMode.Analytics,
    setDisplayMode: (displayMode: DisplayMode) => { },
    allowNegativePersonalTax: true,
    setAllowNegativePersonalTax: (newVal: boolean) => { }
});
