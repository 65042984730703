import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Button, Grid, Typography } from '@mui/material';
import {
    AssetsAndDebtsRequest,
    FinancialPlanningV3Request,
    InvestmentAccountTaxationType,
    InvestmentStrategyType,
    ProductCodeType,
    InflationAdjustmentContract,
    FinancialPlanningV3Response,
    Frequency,
    ValidationErrorModel,
    CashFlowObservationsRequest,
    TransactionUnitRequest,
    CurrencyRequest,
    Domicile,
} from './api_client/api';
import { FinancialPlanningApi } from './api_client/index';
import { percentiles, wideOutcomePercentiles } from './theming/appConstants';
import PercentileFanChart from './visualization/PercentileFanChart';
import { ResultOutput } from './debugComponents/ResultOutput';
import { RequestInput } from './debugComponents/RequestInput';
import { useDebounce } from './hooks/useDebounce';
import GroupResultsChart from './visualization/GroupResultsChart';

export function FinancialPlanningDeveloper() {

    const [result, setResult] = useState(getDefaultResponse());
    const [scenarioSet, setScenarioSet] = useState("");
    const [productId, setProductId] = useState("");
    const [hasResult, setHasResult] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [error, setError] = useState(undefined as ValidationErrorModel);
    const [jsonRequest, setJsonRequest] = useState("");
    const debouncedRequest = useDebounce(jsonRequest, 2000);
    const [financialPlanningRequest, setFinancialPlanningRequest] = useState(undefined);
    const [token, setToken] = useState("");
    const [baseUri, setBaseUri] = useState("http://localhost:3001/");


    useEffect(() => {
        let request: any;

        try {
            let jsonObject = JSON.parse(debouncedRequest);
            request = jsonObject as FinancialPlanningV3Request;
        } catch (SyntaxError) {
            return;
        }

        setFinancialPlanningRequest(request);

    }, [debouncedRequest]);

    useEffect(() => {
        const client = getClient(baseUri);
        const options = getOptions(token);
        client.apiV3SimulationScenarioSetsGet(options)
            .then(r => {
                client.apiV3SimulationProductMappedProductsGet(r[0].scenarioSetId, options)
                    .then(p => {
                        setScenarioSet(r[0].scenarioSetId);
                        const productId = p.find(c => c.codeType === ProductCodeType.Isin).code;
                        setProductId(productId);
                        setJsonRequest(JSON.stringify(getDefaultRequest(r[0].scenarioSetId, productId), null, 2));
                    });
            });
    }, [baseUri, token]);

    useEffect(() => {

        if (financialPlanningRequest === undefined)
            return;

        const client = getClient(baseUri);
        const options = getOptions(token);

        client.apiV3FinancialPlanningPost(financialPlanningRequest, options).then(r => {
            setHasError(false);
            setResult(r);
            setHasResult(true);
            // (window.document.getElementById("resultIFrame") as any).contentWindow.postMessage({ type: "forecast", data: r }, '*');
        }).catch(async e => {
            setHasError(true);
            setError(await e.json());
            setHasResult(false);
        });

    }, [productId, scenarioSet, financialPlanningRequest, baseUri, token]);

    const portfolioDevelopmentTimeSeries = result.percentileForecast;

    const findAndStuff = () => {
        const req ={
            "startDate": {
              "year": 2024,
              "month": 6
            },
            "simulationSettings": {
              "scenarioSetId": "b591afc8-b84c-4758-87d7-612163d72aa2",
              "assetsAndDebtsItemGroups": [
                {
                  "id": "5bcc2f38-d5c4-4278-87ea-5bd65602192c",
                  "assetsAndDebtsItemIds": [
                    "5bcc2f38-d5c4-4278-87ea-5bd65602192c"
                  ]
                },
                {
                  "id": "fd1e9b7a-5f2d-4e73-9cb6-a9bcaed59747",
                  "assetsAndDebtsItemIds": [
                    "fd1e9b7a-5f2d-4e73-9cb6-a9bcaed59747"
                  ]
                }
              ],
              "percentiles": [
                0.1,
                0.5
              ],
              "cashFlowObservations": "Annual",
              "inflationAdjustment": "NoAdjustment",
              "observationTimeSteps": [
                0,
                12,
                24,
                36,
                48,
                60,
                72,
                84,
                96,
                108,
                120,
                132,
                144,
                156,
                168,
                180,
                192,
                204,
                216,
                228,
                240,
                252,
                264,
                276,
                288,
                300,
                312,
                324,
                336,
                348,
                360,
                372,
                384,
                396,
                408,
                420,
                432,
                444,
                456,
                468,
                480
              ]
            },
            "assetsAndDebts": {
              "investmentAccounts": [
                {
                  "displayState": 1,
                  "enabled": true,
                  "name": "ISK",
                  "id": "8bcc2f38-d5c4-4278-87ea-5bd65602192c",
                  "investmentAllocations": [
                    {
                      "spreadOverGrowthRate": 0,
                      "annualFee": 0.003,
                      "strategyWeight": 1,
                      "productCode": {
                        "name": "Skandia Asien",
                        "codeType": "Isin",
                        "code": "SE0000810756"
                      },
                      "currentValue": 5000000,
                      "acquisitionValue": 5000000
                    }
                  ],
                  "strategy": "RebalanceToPlan",
                  "taxationType": "SweCapitalGains",
                  "annualAccountFee": 0,
                  "weightCalculationType": 0,
                  "optimizationHorizonInYears": 10,
                  "optimizationRiskLevel": "MediumHighRisk",
                  "color": "#90cefe"
                }
              ],
              "pensionAccounts": {
                "sweOccupationalPensionAccounts": [
                  {
                    "displayState": 1,
                    "enabled": true,
                    "name": "Occupational pension 1",
                    "id": "fd1e9b7a-5f2d-4e73-9cb6-a9bcaed59747",
                    "investmentAllocations": [
                      {
                        "spreadOverGrowthRate": 0,
                        "annualFee": 0,
                        "strategyWeight": 1,
                        "productCode": {
                          "name": "Sweden/HighRiskPortfolio",
                          "codeType": "Custom",
                          "code": "Sweden/HighRiskPortfolio"
                        },
                        "currentValue": 0
                      }
                    ],
                    "strategy": "RebalanceToPlan",
                    "annualAccountFee": 0,
                    "fees": {
                      "fixedAnnualFee": 0,
                      "relativeAnnualFee": 0
                    },
                    "payoutScheme": {
                      "numberOfMonthsUntilPayout": 240,
                      "schemeType": "FixedPeriod",
                      "fixedPeriodInMonths": 120
                    },
                    "payoutStartUnit": "Annual",
                    "payoutPeriodUnit": "Annual",
                    "weightCalculationType": 0,
                    "optimizationHorizonInYears": 10,
                    "optimizationRiskLevel": "MediumHighRisk",
                    "color": "#d8cbf6"
                  }
                ]
              },
            },
            "cashFlows": {
              "other": {
                "recurringTransactions": [
                  {
                    "value": 75000,
                    "unit": "MonetaryAmountValueAsOfStartDate",
                    "startTimeStep": 0,
                    "numberOfTransactions": 10,
                    "frequency": "Annual",
                    "targetId": "9bcc2f38-d5c4-4278-87ea-5bd65602192c",
                    "sourceId": ""
                  },
                  {
                    "value": 150000,
                    "unit": "MonetaryAmountValueAsOfStartDate",
                    "startTimeStep": 0,
                    "numberOfTransactions": 40,
                    "frequency": "Annual",
                    "targetId": "",
                    "sourceId": "5bcc2f38-d5c4-4278-87ea-5bd65602192c"
                  }
                ],
              },
            },
            "taxInformation": {
              "sweTax": {
                "municipalityTaxRate": 0.31
              },
              "taxPaymentAssetsAndDebtsItemId": "5bcc2f38-d5c4-4278-87ea-5bd65602192c"
            },
            "domicile": "Swe",
            "baseCurrency": "SEK",
            "dateOfBirth": {
              "year": 1965,
              "month": 6
            },
          } as any;

    }

    return (
        <Box sx={{ flexGrow: 1, padding: 1 }} >
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <RequestInput jsonRequest={jsonRequest} setJsonRequest={setJsonRequest} token={token} setToken={setToken} baseUri={baseUri} setBaseUri={setBaseUri} />
                    <ResultOutput text={hasError ? error : result} />
                </Grid>
                <Grid item xs={12} md={6} alignItems="stretch">
                    <Typography variant='h4'>Result</Typography>
                    {(hasResult ? (<PercentileFanChart height={500} targetAmount={0} horizon={40} currentAge={0} portfolioDevelopmentTimeSeries={portfolioDevelopmentTimeSeries} />) : (<></>))}
                    {(hasResult ? (<GroupResultsChart height={500} groupResults={result.groupResults.map(r=>({forecast: r.forecast, name: undefined, color: undefined}))} horizon={40} observationFrequency={Frequency.Annual} />) : (<></>))}
                </Grid>
                {/* <Grid item xs={12} md={6} alignItems="stretch">
                    <iframe id="resultIFrame" title='resultIFrame' src='http://localhost:3001/chart'></iframe>
                </Grid> */}
            </Grid>
        </Box>
    );
}

function getClient(baseUri: string): FinancialPlanningApi {
    const client = new FinancialPlanningApi(undefined, baseUri);
    return client;
}

function getOptions(token: string): any {
    const bearer = `Bearer ${token}`;

    const options = {
        headers: {
            "Authorization": bearer
        }
    };

    return options;
};


function getDefaultResponse(): FinancialPlanningV3Response {
    const defaultResponse: FinancialPlanningV3Response = {
        percentileForecast: [{ percentile: wideOutcomePercentiles[1], outcomes: [{ timeStep: 0, value: 0 }] }],
        groupResults: [],
        cashFlows: []
    };
    return defaultResponse;
}

export function getDefaultRequest(scenarioSet: string, productId: string): FinancialPlanningV3Request {

    const now = moment();

    const observationDates = [...Array(41).keys()].map(index => now.clone().add(index, 'years').toDate());

    const initialAccount = "3fa85f64-5717-4562-b3fc-2c963f66afa6";

    const assetsAndDebts: AssetsAndDebtsRequest = {
        investmentAccounts: [
            {
                id: initialAccount,
                taxationType: InvestmentAccountTaxationType.NoTax,
                annualAccountFee: 0,
                investmentAllocations: [{
                    annualFee: 0,
                    productCode: { code: productId, codeType: ProductCodeType.Isin },
                    strategyWeight: 1,
                    currentValue: 1000,
                    spreadOverGrowthRate: 0
                }],
                strategy: InvestmentStrategyType.RebalanceToPlan,
            }
        ]
    };
    const financialPlanningRequest: FinancialPlanningV3Request = {
        startDate: { year: now.toDate().getFullYear(), month: now.toDate().getMonth() },
        simulationSettings: {
            percentiles: percentiles,
            inflationAdjustment: InflationAdjustmentContract.NoAdjustment,
            observationTimeSteps: observationDates.map((_, i) => i * 12),
            cashFlowObservations: CashFlowObservationsRequest.None,
            assetsAndDebtsItemGroups: [
                {
                    assetsAndDebtsItemIds: [initialAccount],
                    id: "2FE4B9FE-E435-41CB-B1C7-66C6B64F8834"
                }
            ]

        },
        assetsAndDebts: assetsAndDebts,
        cashFlows: {
            other: {
                oneTimeTransactions: [],
                recurringTransactions: [{ startTimeStep: 0, numberOfTransactions: observationDates.length * 12 -1, value: 1000, unit: TransactionUnitRequest.MonetaryAmount, frequency: Frequency.Monthly, targetId: initialAccount }],
                transactionArrays: []
            }
        },
        baseCurrency: CurrencyRequest.SEK,
        domicile: Domicile.Swe
    };
    return financialPlanningRequest;

}