import React, { useContext } from 'react';
import { NumericFormat } from 'react-number-format';
import { SettingsContext } from './settings/SettingsContext';
import { Typography } from '@mui/material';

function getThousandSeparator(currency: string) {
  if (['GBP', 'USD'].includes(currency)) return ',';
  if (currency === 'NOK') return '.';
  return ' ';
}

function getDecimalSeparator(currency: string) {
  if (['GBP', 'USD'].includes(currency)) return '.';
  return ',';
}

function getPrefix(currency: string) {
  if (currency === "EUR") return '€';
  if (currency === "GBP") return '£';
  if (currency === "USD") return '$';
  return currency + ' ';
}

export function NumberFormatCustom(props: any) {
  const settings = useContext(SettingsContext);

  const { inputRef, onChange, ...other } = props;
  return (<NumericFormat {...other} getInputRef={inputRef} onValueChange={(values: any) => {
    onChange({
      target: {
        value: values.floatValue,
      },
    });
  }}
    thousandSeparator={getThousandSeparator(settings.currency)}
    decimalSeparator={getDecimalSeparator(settings.currency)}
    decimalScale="2"
    valueIsNumericString />);
}

export function CurrencyFormatCustom(props: any) {
  const settings = useContext(SettingsContext);

  const { inputRef, onChange, ...other } = props;
  return (<NumericFormat {...other} getInputRef={inputRef} onValueChange={(values: any) => {
    onChange({
      target: {
        value: values.floatValue,
      },
    });
  }}
    thousandSeparator={getThousandSeparator(settings.currency)}
    decimalSeparator={getDecimalSeparator(settings.currency)}
    prefix={getPrefix(settings.currency)}
    decimalScale="2"
    valueIsNumericString />);
}

export function PercentFormatCustom(props: any) {
  const settings = useContext(SettingsContext);

  const { inputRef, onChange, ...other } = props;
  return (<NumericFormat {...other} getInputRef={inputRef} onValueChange={(values: any) => {
    onChange({
      target: {
        value: values.floatValue,
      },
    });
  }}
    thousandSeparator={getThousandSeparator(settings.currency)}
    decimalSeparator={getDecimalSeparator(settings.currency)}
    decimalScale={2}
    suffix='%'

    valueIsNumericString />);
}


export function TextNumberFormat(props: any) {
  const settings = useContext(SettingsContext);

  const { value, disableRounding, roundToNearest, hidePrefix, decimals, roundToUnit, suffix, ...other } = props;

  let roundedValue = value;

  if (!disableRounding) {
    const roundToNearestValue = roundToNearest === undefined ? 1000 : roundToNearest;
    roundedValue = (Math.round(roundedValue / roundToNearestValue) * roundToNearestValue).toFixed(decimals ?? 0);
  }

  let unitSuffix = undefined;
  if (roundToUnit) {
    if (roundedValue >= 100000) {
      unitSuffix = "mn";
      roundedValue /= 1000000;
    } else if (roundedValue >= 1000) {
      roundedValue /= 1000;
      unitSuffix = "k";
    }
    roundedValue = roundedValue.toFixed(decimals ?? 0)
  }
  // roundedValue = (disableRounding ? roundedValue : (Math.round(roundedValue / roundToNearestValue) * roundToNearestValue)).toFixed(decimals ?? 0);
  return (<NumericFormat value={roundedValue} {...other}
    thousandSeparator={getThousandSeparator(settings.currency)}
    decimalSeparator={getDecimalSeparator(settings.currency)}
    displayType={'text'}
    decimalScale="2"
    prefix={hidePrefix ? undefined : getPrefix(settings.currency)}
    suffix={suffix && unitSuffix ? suffix + unitSuffix : unitSuffix ? unitSuffix : suffix ? suffix : undefined}
    valueIsNumericString />);
}




export function FormattedNumberFormat(props: any) {
  const settings = useContext(SettingsContext);

  const { value, variant, disableRounding, roundToNearest, hidePrefix, decimals, roundToUnit, ...other } = props;

  let roundedValue = Number(value);

  if (!disableRounding) {
    const roundToNearestValue = roundToNearest === undefined ? 1000 : roundToNearest;
    roundedValue = (Math.round(roundedValue / roundToNearestValue) * roundToNearestValue);
  }

  let suffix = undefined;
  if (roundToUnit) {
    if (roundedValue >= 100000) {
      suffix = "mn";
      roundedValue /= 1000000;
    } else if (roundedValue >= 1000) {
      roundedValue /= 1000;
      suffix = "k";
    }
  }

  return ((
    <div>
      {hidePrefix ? <></> : <Typography color="text.secondary" display="inline">{getPrefix(settings.currency)}</Typography>}
      <Typography variant={variant ?? 'body1'} display="inline">
        <NumericFormat value={roundedValue} {...other}
          thousandSeparator={getThousandSeparator(settings.currency)}
          decimalSeparator={getDecimalSeparator(settings.currency)}
          displayType={'text'}
          decimalScale={decimals ?? 2} />
      </Typography>
      {suffix === undefined ? <></> : <Typography color="text.secondary" display="inline"> {suffix}</Typography>}
    </div>
  ))

  // roundedValue = (disableRounding ? roundedValue : (Math.round(roundedValue / roundToNearestValue) * roundToNearestValue)).toFixed(decimals ?? 0);
  // return (<NumericFormat value={roundedValue} {...other}
  //   thousandSeparator={getThousandSeparator(settings.currency)}
  //   decimalSeparator={getDecimalSeparator(settings.currency)}
  //   displayType={'text'}
  //   decimalScale="2"
  //   prefix={hidePrefix ? undefined : getPrefix(settings.currency)}
  //   suffix={suffix}
  //   valueIsNumericString />);
}
