import { ArrowDownward, ArrowUpward, Face, Face3, Hiking, Work, WorkOutlined } from "@mui/icons-material";
import { Box, Chip, Divider, Grid, Paper, Typography, useTheme } from "@mui/material";
import { CurrencyFormatCustom, FormattedNumberFormat, TextNumberFormat } from "../NumberFormatCustom";
import { PercentileForecastResponse } from "../api_client";
import { useAppColors } from "../theming/useAppColors";
import { calculateSumAssetsAndDebts, Portfolio } from "../financialPlanning/Portfolio";


function getResults(currentAge: number, ageOfRetirement: number, portfolioDevelopmentTimeSeries: PercentileForecastResponse[], percentile: number): { valueNow: number, valueAtRetirement: number, valueAtLifeExpectancy: number } {
    const valueNow = portfolioDevelopmentTimeSeries.find(s => s.percentile === percentile).outcomes[0].value;
    const valueAtRetirement = portfolioDevelopmentTimeSeries.find(s => s.percentile === percentile).outcomes.find(p => p.timeStep >= (ageOfRetirement - currentAge) * 12).value;
    const valueAtLifeExpectancy = portfolioDevelopmentTimeSeries.find(s => s.percentile === percentile).outcomes.find(p => p.timeStep >= (78 - currentAge) * 12).value;
    return { valueNow, valueAtRetirement, valueAtLifeExpectancy };
}

export default function LifetimeOverview(props: {
    // currentAge: number,
    // ageOfRetirement: number,
    portfolioDevelopmentTimeSeries: PercentileForecastResponse[],
    referencePortfolioDevelopmentTimeSeries: PercentileForecastResponse[],
    portfolio: Portfolio,
    referencePortfolio: Portfolio,
}) {


    const { portfolioDevelopmentTimeSeries, referencePortfolioDevelopmentTimeSeries, portfolio, referencePortfolio } = props;
    const theme = useTheme();

    if (portfolioDevelopmentTimeSeries?.find(s => s.percentile === 0.5) === undefined)
        return <></>;

    const portfolioPlannedRetirementAge = portfolio?.plannedRetirementAge ? portfolio.plannedRetirementAge : 68;
    const referencePortfolioPlannedRetirementAge = referencePortfolio?.plannedRetirementAge ? referencePortfolio?.plannedRetirementAge : 68;

    const currentAge = calculateAge(portfolio.dateOfBirth);

    const medianResult = getResults(currentAge, portfolioPlannedRetirementAge, portfolioDevelopmentTimeSeries, 0.5);
    const pessimisticResult = getResults(currentAge, portfolioPlannedRetirementAge, portfolioDevelopmentTimeSeries, 0.1);

    let referenceMedianResult = undefined;
    let referencePessimisticResult = undefined;
    let referenceCurrentAge = undefined;
    if (referencePortfolio?.dateOfBirth && referencePortfolioDevelopmentTimeSeries?.find(s => s.percentile === 0.5) !== undefined) {
        referenceCurrentAge = calculateAge(referencePortfolio.dateOfBirth);
          
        referenceMedianResult = getResults(referenceCurrentAge, referencePortfolioPlannedRetirementAge, referencePortfolioDevelopmentTimeSeries, 0.5);
        referencePessimisticResult = getResults(referenceCurrentAge, referencePortfolioPlannedRetirementAge, referencePortfolioDevelopmentTimeSeries, 0.1);
    }


    return (<>
        <Grid container direction='column' alignItems="center" spacing={2} style={{ padding: theme.spacing(2) }}>
            <Grid item>
                <Typography style={{ paddingBottom: theme.spacing(1), paddingTop: theme.spacing(1) }} fontFamily={'Roboto'} variant='h5'>{portfolio.name}</Typography>
            </Grid >
            <Grid item>
            </Grid>
            <Grid container spacing={4} justifyContent={'space-evenly'} alignItems='stretch'>
                <OutcomePaper
                    icons={<><Work fontSize='medium'></Work></>}
                    medianOutcome={medianResult.valueNow}
                    title={"Age " + currentAge}
                    portfolio={portfolio} />
                <OutcomePaper
                    icons={<><Hiking fontSize='medium'></Hiking></>}
                    medianOutcome={medianResult.valueAtRetirement}
                    pessimisticOutcome={pessimisticResult.valueAtRetirement}
                    title={"Age " + portfolioPlannedRetirementAge} />
                <OutcomePaper
                    icons={<><Face fontSize='medium'></Face><Face3 fontSize='medium'></Face3></>}
                    medianOutcome={medianResult.valueAtLifeExpectancy}
                    pessimisticOutcome={pessimisticResult.valueAtLifeExpectancy}
                    title={"Legacy"} />

            </Grid >
            {referenceMedianResult
                ? (<><Grid item>
                    <Typography style={{ paddingBottom: theme.spacing(1), paddingTop: theme.spacing(1) }} fontFamily={'Roboto'} variant='h5'>{referencePortfolio.name}</Typography>
                </Grid >
                    <Grid item>
                    </Grid>
                    <Grid container spacing={4} justifyContent={'space-evenly'} alignItems='stretch'>
                        <OutcomePaper
                            icons={<><Work fontSize='medium'></Work></>}
                            medianOutcome={referenceMedianResult.valueNow}
                            title={"Age " + referenceCurrentAge}
                            portfolio={referencePortfolio} />
                        <OutcomePaper
                            icons={<><Hiking fontSize='medium'></Hiking></>}
                            medianOutcome={referenceMedianResult.valueAtRetirement}
                            referenceMedianOutcome={medianResult.valueAtRetirement}
                            pessimisticOutcome={referencePessimisticResult.valueAtRetirement}
                            referencePessimisticOutcome={pessimisticResult.valueAtRetirement}
                            title={"Age " + referencePortfolioPlannedRetirementAge} />
                        <OutcomePaper
                            icons={<><Face fontSize='medium'></Face><Face3 fontSize='medium'></Face3></>}
                            medianOutcome={referenceMedianResult.valueAtLifeExpectancy}
                            referenceMedianOutcome={medianResult.valueAtLifeExpectancy}
                            pessimisticOutcome={referencePessimisticResult.valueAtLifeExpectancy}
                            referencePessimisticOutcome={pessimisticResult.valueAtLifeExpectancy}
                            title={"Legacy"} />

                    </Grid ></>)
                : <></>}

        </Grid>
    </>);
}

function calculateAge(birthDateInput?:Date) {
    if(birthDateInput === undefined)
        return 44;

    const birthDate = new Date(birthDateInput);
    const today = new Date();
    const birthYear = birthDate.getFullYear();
    const currentYear = today.getFullYear();
    let age = currentYear - birthYear;
  
    // Adjust if the birth date hasn't occurred yet this year
    const birthMonth = birthDate.getMonth();
    const birthDay = birthDate.getDate();
    const currentMonth = today.getMonth();
    const currentDay = today.getDate();
  
    if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
      age--;
    }
  
    if(age < 40)
        return 40;
    return age;
  }

function OutcomePaper(props: { title: string, icons: JSX.Element, medianOutcome: number, pessimisticOutcome?: number, referenceMedianOutcome?: number, referencePessimisticOutcome?: number, portfolio?: Portfolio }) {
    const { title, icons, medianOutcome, pessimisticOutcome, referenceMedianOutcome, referencePessimisticOutcome, portfolio } = props;
    const theme = useTheme();
    const colors = useAppColors();

    const referenceChip = (val?: number, reference?: number) => {
        const result = val && reference
            ? (val / reference - 1) * 100
            : undefined;
        return result
            ? <Grid item>
                <Chip icon={result < 0 ? <ArrowDownward fontSize="small" /> : <ArrowUpward fontSize="small" />} style={{ backgroundColor: result < 0 ? colors.outcomes.bad : colors.outcomes.good }} label={<><TextNumberFormat hidePrefix suffix='%' disableRounding value={Math.abs(result)} /></>} />
            </Grid>
            : <></>
    }

    const portfolioResult = portfolio ? calculateSumAssetsAndDebts(portfolio.assetsAndDebts) : undefined;

    return (
        <Grid item>
            <Paper style={{ padding: theme.spacing(2), maxWidth: theme.spacing(38), height: '100%', width: theme.spacing(30) }}>
                <Grid container justifyContent={'center'} direction='column' alignItems='center'>
                    <Box display="flex" alignItems="center">
                        {icons}
                    </Box>
                    <Typography style={{ paddingBottom: theme.spacing(1), paddingTop: theme.spacing(1) }} fontFamily={'Roboto'} variant='h4'>{title}</Typography>
                    <Grid container spacing={1} justifyContent='center' alignItems='center'>
                        <Grid item>
                            <FormattedNumberFormat variant='h3' roundToUnit decimals={2} value={medianOutcome} />
                        </Grid>
                        {referenceChip(medianOutcome, referenceMedianOutcome)}
                    </Grid>
                    {pessimisticOutcome === undefined
                        ? <></>
                        : (<><Typography style={{ paddingTop: theme.spacing(1) }} fontFamily={'Roboto'} color="text.secondary" variant='body1'>Pessimistic outcome:</Typography>
                            <Grid container spacing={1} justifyContent='center' alignItems='center'>
                                <Grid item><FormattedNumberFormat variant='h5' roundToUnit decimals={2} value={pessimisticOutcome} /></Grid>
                                {referenceChip(pessimisticOutcome, referencePessimisticOutcome)}
                            </Grid></>)}
                    {portfolioResult
                        ? <>
                            <Grid container>
                                <ListItem title="Assets:" value={portfolioResult.assets} width={theme.spacing(12)}></ListItem>
                                <ListItem title="Debt:" value={portfolioResult.debt} width={theme.spacing(12)}></ListItem>
                            </Grid>
                        </>
                        : <></>}
                </Grid>

            </Paper >
        </Grid>);
}

function ListItem(props: { title: string, value: number, width: string }) {
    const { title, value, width } = props;
    return (<>
        <Grid item xs>
            <Typography color='text.secondary' variant='h6' style={{ width: width }}>{title}</Typography>
        </Grid>
        <Grid item xs='auto'>
            <FormattedNumberFormat variant='h6' roundToUnit decimals={2} value={value} />
        </Grid>
        <Grid item xs={12}><Divider></Divider>
        </Grid>
    </>);
}