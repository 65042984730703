import React from 'react';
import { Portfolio } from '../financialPlanning/Portfolio';

export const PortfolioContext = React.createContext<PortfolioState>({
    portfolios: [] as Portfolio[], 
    setPortfolios: (newVal: Portfolio[]) => {},
    setPortfolios2: (mutator: (currentPortfolios:Portfolio[]) => void) => {},
    activePortfolioIndex: 0,
    setActivePortfolioIndex: (newVal: number) => {},
    referencePortfolioIndex: 1,
    setReferencePortfolioIndex: (newVal: number) => {},
    isLoaded: false
});

export interface PortfolioState {
    portfolios: Portfolio[];
    setPortfolios: (newVal: Portfolio[]) => void;
    setPortfolios2: (mutator: (currentPortfolios: Portfolio[]) => void) => void;
    activePortfolioIndex: number;
    setActivePortfolioIndex: (newVal: number) => void;
    referencePortfolioIndex: number;
    setReferencePortfolioIndex: (newVal: number) => void;
    isLoaded: boolean;
}