/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Financial Planning API
 * The Financial Planning API is a comprehensive and flexible API for simulating financial situations. It can be used for holistic and full-balance sheet financial planning as well as for building separate use cases within e.g. pension, investments, credit, insurance, tax or gap analysis.
 *
 * OpenAPI spec version: v3
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AssetsAndDebtsItemGroupRequest
 */
export interface AssetsAndDebtsItemGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof AssetsAndDebtsItemGroupRequest
     */
    id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssetsAndDebtsItemGroupRequest
     */
    assetsAndDebtsItemIds: Array<string>;
}
/**
 * 
 * @export
 * @interface AssetsAndDebtsRequest
 */
export interface AssetsAndDebtsRequest {
    /**
     * Investments in funds, stocks or other investment products. Tax wrapper, strategies and fees can be defined for the account and underlying allocations.
     * @type {Array<InvestmentAccountRequest>}
     * @memberof AssetsAndDebtsRequest
     */
    investmentAccounts?: Array<InvestmentAccountRequest>;
    /**
     * Real estate, e.g. private homes. In addition to the current value, you can also specify the acquisition value for taxation and return calculations.
     * @type {Array<RealEstateRequest>}
     * @memberof AssetsAndDebtsRequest
     */
    realEstate?: Array<RealEstateRequest>;
    /**
     * Current specifications of any mortgages. When the fixed rate period ends a variable interest rate from simulated interest rates will be used.
     * @type {Array<MortgageRequest>}
     * @memberof AssetsAndDebtsRequest
     */
    mortgages?: Array<MortgageRequest>;
    /**
     * Definition of bank/personal accounts, typically used for salaries, expenses, tax payments etc.
     * @type {Array<TransactionAccountRequest>}
     * @memberof AssetsAndDebtsRequest
     */
    transactionAccounts?: Array<TransactionAccountRequest>;
    /**
     * Defines the pension setup, divided into income, premium, and occupational pension (specific to the Swedish domicile). Pension accounts for other domiciles can be added upon request.
     * @type {PensionAccountsRequest}
     * @memberof AssetsAndDebtsRequest
     */
    pensionAccounts?: PensionAccountsRequest;
    /**
     * Any privately owned companies can be added here. In addition to the current value, you can also specify the acquisition value for taxation and return calculations.
     * @type {Array<UnlistedSharesRequestModel>}
     * @memberof AssetsAndDebtsRequest
     */
    unlistedShares?: Array<UnlistedSharesRequestModel>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CashFlowObservationsRequest {
    None = <any> 'None',
    Monthly = <any> 'Monthly',
    Annual = <any> 'Annual'
}
/**
 * 
 * @export
 * @interface CashFlowResponse
 */
export interface CashFlowResponse {
    /**
     * The id for the corresponding item in assetsAndDebts. Accounts created automatically in the simulation (e.g. taxes paid) will also be part of this output.
     * @type {string}
     * @memberof CashFlowResponse
     */
    assetsAndDebtsItemId?: string;
    /**
     * Average inflows and outflows for the period starting at each timeStep for the frquency defined by cashFlowObservations.
     * @type {Array<PointInTimeCashFlowResponse>}
     * @memberof CashFlowResponse
     */
    averageCashFlows?: Array<PointInTimeCashFlowResponse>;
}
/**
 * 
 * @export
 * @interface CashFlowsRequest
 */
export interface CashFlowsRequest {
    /**
     * Transactions that should be taxed as earned income, i.e. gross income.
     * @type {IncomeTransactionsRequest}
     * @memberof CashFlowsRequest
     */
    earnedIncome?: IncomeTransactionsRequest;
    /**
     * All transactions that should be considered dividend payouts.  These transactions can have a source defined if the simulation contains an account representing the source of the dividend.
     * @type {Array<DividendRequest>}
     * @memberof CashFlowsRequest
     */
    dividends?: Array<DividendRequest>;
    /**
     * Transactions including tax exempt income, cost/expenses, selling/buying assets and amortization.  These transactions can have only source (funds going out), only target (funds going in) or both (intra-account).
     * @type {OtherTransactionsRequest}
     * @memberof CashFlowsRequest
     */
    other?: OtherTransactionsRequest;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CurrencyRequest {
    SEK = <any> 'SEK',
    USD = <any> 'USD'
}
/**
 * 
 * @export
 * @interface DividendRequest
 */
export interface DividendRequest {
    /**
     * The type of dividend. Used for tax calculations.
     * @type {DividendType}
     * @memberof DividendRequest
     */
    type: DividendType;
    /**
     * One-time dividends.
     * @type {Array<OneTimeUnitTransaction>}
     * @memberof DividendRequest
     */
    oneTimeTransactions?: Array<OneTimeUnitTransaction>;
    /**
     * Recurring dividends, e.g. annual.
     * @type {Array<RecurringUnitTransaction>}
     * @memberof DividendRequest
     */
    recurringTransactions?: Array<RecurringUnitTransaction>;
    /**
     * A defined series of dividends.
     * @type {Array<TransactionUnitArray>}
     * @memberof DividendRequest
     */
    transactionArrays?: Array<TransactionUnitArray>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DividendType {
    SweDividendsK10 = <any> 'SweDividendsK10',
    SweDividendsK12 = <any> 'SweDividendsK12'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Domicile {
    Swe = <any> 'Swe',
    Nor = <any> 'Nor',
    Fin = <any> 'Fin',
    Dnk = <any> 'Dnk',
    Gbr = <any> 'Gbr',
    Unspecified = <any> 'Unspecified'
}
/**
 * 
 * @export
 * @interface EfficientFrontierSample
 */
export interface EfficientFrontierSample {
    /**
     * 
     * @type {PortfolioInformation}
     * @memberof EfficientFrontierSample
     */
    optimalPortfolio?: PortfolioInformation;
    /**
     * 
     * @type {number}
     * @memberof EfficientFrontierSample
     */
    maxReturn?: number;
    /**
     * 
     * @type {number}
     * @memberof EfficientFrontierSample
     */
    maxVolatility?: number;
}
/**
 * 
 * @export
 * @interface EfficientFrontierV3Request
 */
export interface EfficientFrontierV3Request {
    /**
     * Provided by /simulation/scenario-sets. The latest production set will be used if omitted (recommended).
     * @type {string}
     * @memberof EfficientFrontierV3Request
     */
    scenarioSetId?: string;
    /**
     * Time horizon in months. Has to be strictly positive.
     * @type {number}
     * @memberof EfficientFrontierV3Request
     */
    horizonInMonths: number;
    /**
     * The efficient frontier will be created with respect to these allowed investments.
     * @type {Array<ProductCodeContract>}
     * @memberof EfficientFrontierV3Request
     */
    allowedInvestments: Array<ProductCodeContract>;
}
/**
 * 
 * @export
 * @interface EfficientFrontierV3Response
 */
export interface EfficientFrontierV3Response {
    /**
     * Information about the optimal portfolios.
     * @type {Array<EfficientFrontierSample>}
     * @memberof EfficientFrontierV3Response
     */
    optimalPortfolios?: Array<EfficientFrontierSample>;
}
/**
 * 
 * @export
 * @interface FeesRequest
 */
export interface FeesRequest {
    /**
     * The relative annual fee expressed as fraction. Must be between 0 and 1.
     * @type {number}
     * @memberof FeesRequest
     */
    relativeAnnualFee: number;
    /**
     * An annual fee expressed as a fixed amount.
     * @type {number}
     * @memberof FeesRequest
     */
    fixedAnnualFee?: number;
}
/**
 * 
 * @export
 * @interface FieldErrorModel
 */
export interface FieldErrorModel {
    /**
     * 
     * @type {string}
     * @memberof FieldErrorModel
     */
    field?: string;
    /**
     * 
     * @type {string}
     * @memberof FieldErrorModel
     */
    message?: string;
    /**
     * 
     * @type {FieldErrorModelCode}
     * @memberof FieldErrorModel
     */
    code?: FieldErrorModelCode;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FieldErrorModelCode {
    Unspecified = <any> 'Unspecified',
    NegativeSalary = <any> 'NegativeSalary',
    DateOfBirthTooLow = <any> 'DateOfBirthTooLow',
    DateOfBirthTooHigh = <any> 'DateOfBirthTooHigh',
    RetirementAgeTooHigh = <any> 'RetirementAgeTooHigh',
    RetirementAgeTooLow = <any> 'RetirementAgeTooLow',
    NegativePensionCapital = <any> 'NegativePensionCapital',
    PensionCapitalTooHigh = <any> 'PensionCapitalTooHigh',
    NegativeDesiredPension = <any> 'NegativeDesiredPension',
    CurrentNationalPensionIsZero = <any> 'CurrentNationalPensionIsZero',
    NegativeMonthlyDeposit = <any> 'NegativeMonthlyDeposit',
    MonthlyDepositHigherThanSavingsCapacity = <any> 'MonthlyDepositHigherThanSavingsCapacity',
    TotalDepositsMustBePositive = <any> 'TotalDepositsMustBePositive',
    NegativeSavingsCapacity = <any> 'NegativeSavingsCapacity',
    NonpositivePensionInvestmentRiskAversion = <any> 'NonpositivePensionInvestmentRiskAversion',
    PensionInvestmentRiskAversionTooHigh = <any> 'PensionInvestmentRiskAversionTooHigh',
    MunicipalityKeyNotRecognized = <any> 'MunicipalityKeyNotRecognized',
    SalaryCapacityTooLow = <any> 'SalaryCapacityTooLow',
    InvalidGeneralRiskAversion = <any> 'InvalidGeneralRiskAversion',
    SumOfMonthlyDepositAndOneTimeDepositTooLow = <any> 'SumOfMonthlyDepositAndOneTimeDepositTooLow',
    InvestmentCapacityTooHigh = <any> 'InvestmentCapacityTooHigh',
    DesiredPensionTooHigh = <any> 'DesiredPensionTooHigh',
    UnknownScenarioSetId = <any> 'UnknownScenarioSetId',
    PecentileOutOfRange = <any> 'PecentileOutOfRange',
    GrossSalaryTooHigh = <any> 'GrossSalaryTooHigh',
    InvalidCurrencyCode = <any> 'InvalidCurrencyCode',
    NegativeFee = <any> 'NegativeFee',
    InvalidProductCode = <any> 'InvalidProductCode',
    InvalidAllocationWeight = <any> 'InvalidAllocationWeight',
    NegativeOutperformanceAssumption = <any> 'NegativeOutperformanceAssumption',
    UnknownSimulatedModelId = <any> 'UnknownSimulatedModelId',
    UnknownFactorId = <any> 'UnknownFactorId',
    NullArgument = <any> 'NullArgument',
    InvalidFormat = <any> 'InvalidFormat',
    OutOfRange = <any> 'OutOfRange',
    UnknownType = <any> 'UnknownType',
    ImportError = <any> 'ImportError',
    InvalidTransactionDate = <any> 'InvalidTransactionDate',
    InvalidMunicipalityKey = <any> 'InvalidMunicipalityKey',
    NoObservationDates = <any> 'NoObservationDates',
    IdenticalAccountIds = <any> 'IdenticalAccountIds',
    InvalidAccountId = <any> 'InvalidAccountId'
}
/**
 * 
 * @export
 * @interface FinancialPlanningV3Request
 */
export interface FinancialPlanningV3Request {
    /**
     * Settings for the simulation, e.g. observations, percentiles, scenario-set.
     * @type {SimulationSettingsRequest}
     * @memberof FinancialPlanningV3Request
     */
    simulationSettings: SimulationSettingsRequest;
    /**
     * Start date of the simulation, expressed as year (YYYY) and month (MM).
     * @type {YearMonthContract}
     * @memberof FinancialPlanningV3Request
     */
    startDate: YearMonthContract;
    /**
     * Date of birth, expressed as year (YYYY) and month (MM).
     * @type {YearMonthContract}
     * @memberof FinancialPlanningV3Request
     */
    dateOfBirth?: YearMonthContract;
    /**
     * The domicile of the simulation, involving rules for tax and pension calculations. \"Unspecified\" can be used for other domiciles if no tax rules are used in the simulation. Any domicile not yet supported can be added upon request.
     * @type {Domicile}
     * @memberof FinancialPlanningV3Request
     */
    domicile: Domicile;
    /**
     * The currency of amounts in the request. Also, returns of investments are also defined from the perspective of this currency.
     * @type {CurrencyRequest}
     * @memberof FinancialPlanningV3Request
     */
    baseCurrency: CurrencyRequest;
    /**
     * Information needed to calculate tax.
     * @type {TaxInformationRequest}
     * @memberof FinancialPlanningV3Request
     */
    taxInformation?: TaxInformationRequest;
    /**
     * The asset and debts to be included in the simulation. Supports full-balance sheet financial planning as well as stand-alone use cases for e.g. pension, investments or credit.
     * @type {AssetsAndDebtsRequest}
     * @memberof FinancialPlanningV3Request
     */
    assetsAndDebts: AssetsAndDebtsRequest;
    /**
     * The future cash flows and transactions to be included in the simulation.
     * @type {CashFlowsRequest}
     * @memberof FinancialPlanningV3Request
     */
    cashFlows?: CashFlowsRequest;
    /**
     * Strategies to be deployed on a portfolio level, e.g. rebalancing between different investment accounts.
     * @type {PortfolioStrategiesRequest}
     * @memberof FinancialPlanningV3Request
     */
    portfolioStrategies?: PortfolioStrategiesRequest;
}
/**
 * 
 * @export
 * @interface FinancialPlanningV3Response
 */
export interface FinancialPlanningV3Response {
    /**
     * Outcomes for the percentiles and observationTimeSteps specified in simulationSettings.
     * @type {Array<PercentileForecastResponse>}
     * @memberof FinancialPlanningV3Response
     */
    percentileForecast?: Array<PercentileForecastResponse>;
    /**
     * Average outcomes for the assetsAndDebtsItemGroups and observationTimeSteps specified in simulationSettings.
     * @type {Array<GroupResultResponse>}
     * @memberof FinancialPlanningV3Response
     */
    groupResults?: Array<GroupResultResponse>;
    /**
     * Average outcomes for cash flows for the accounts/items in the simulation. The frequency is defined in simulationSettings (cashFlowObservations) and starts from the first time step.
     * @type {Array<CashFlowResponse>}
     * @memberof FinancialPlanningV3Response
     */
    cashFlows?: Array<CashFlowResponse>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Frequency {
    Monthly = <any> 'Monthly',
    Annual = <any> 'Annual'
}
/**
 * 
 * @export
 * @interface GapFactorByTimeStepResponse
 */
export interface GapFactorByTimeStepResponse {
    /**
     * The time step of the outcome.
     * @type {number}
     * @memberof GapFactorByTimeStepResponse
     */
    timeStep?: number;
    /**
     * Factor to be multiplied with the cash flows amounts to reach the targetValue in the targetPercentile.
     * @type {number}
     * @memberof GapFactorByTimeStepResponse
     */
    factor: number;
    /**
     * Probability of reaching the targetValue given the financial situation specified in the request (without applying the multiplying factor).
     * @type {number}
     * @memberof GapFactorByTimeStepResponse
     */
    probabilityOfReachingTargetUsingExistingFunds: number;
}
/**
 * 
 * @export
 * @interface GapRequest
 */
export interface GapRequest {
    /**
     * Settings for the simulation, e.g. observations, percentiles, scenario-set.
     * @type {GapSimulationSettingsRequest}
     * @memberof GapRequest
     */
    simulationSettings: GapSimulationSettingsRequest;
    /**
     * Start date of the simulation, expressed as year (YYYY) and month (MM).
     * @type {YearMonthContract}
     * @memberof GapRequest
     */
    startDate: YearMonthContract;
    /**
     * Date of birth, expressed as year (YYYY) and month (MM).
     * @type {YearMonthContract}
     * @memberof GapRequest
     */
    dateOfBirth?: YearMonthContract;
    /**
     * The asset and debts to be included in the simulation. Supports full-balance sheet financial planning as well as stand-alone use cases for e.g. pension, investments or credit.
     * @type {AssetsAndDebtsRequest}
     * @memberof GapRequest
     */
    assetsAndDebts: AssetsAndDebtsRequest;
    /**
     * The future cash flows and transactions to be included in the simulation.
     * @type {CashFlowsRequest}
     * @memberof GapRequest
     */
    cashFlows: CashFlowsRequest;
    /**
     * Strategies to be deployed on a portfolio level, e.g. rebalancing between different investment accounts.
     * @type {PortfolioStrategiesRequest}
     * @memberof GapRequest
     */
    portfolioStrategies?: PortfolioStrategiesRequest;
    /**
     * The value of the savings goal.
     * @type {number}
     * @memberof GapRequest
     */
    targetValue: number;
    /**
     * The percentile of the savings goal. Percentiles are expressed as fractions, and must be between 0 and 1. Specifying 0.5 would correspond to reaching the savings goal in 50% of the simulated scenarios.
     * @type {number}
     * @memberof GapRequest
     */
    targetPercentile: number;
    /**
     * The item in assetsAndDebts for which we calculate the gap factor/extra savings required to reach the targetValue in the targetPercentile.<br />  For the calculation to work accurately, cash flows going in to the account cannot vary over time.<br />
     * @type {string}
     * @memberof GapRequest
     */
    itemIdForFactorInAssetsAndDebts: string;
    /**
     * The domicile of the simulation, involving rules for tax and pension calculations. \"Unspecified\" can be used for other domiciles if no tax rules are used in the simulation. Any domicile not yet supported can be added upon request.
     * @type {Domicile}
     * @memberof GapRequest
     */
    domicile?: Domicile;
    /**
     * Information needed to calculate tax.
     * @type {TaxInformationRequest}
     * @memberof GapRequest
     */
    taxInformation?: TaxInformationRequest;
    /**
     * The currency of amounts in the request. Also, returns of investments are also defined from the perspective of this currency.
     * @type {CurrencyRequest}
     * @memberof GapRequest
     */
    baseCurrency?: CurrencyRequest;
}
/**
 * 
 * @export
 * @interface GapResponse
 */
export interface GapResponse {
    /**
     * The factors that if multiplied with the cash flows into the itemIdForFactorInAssetsAndDebts will result in that the targetValue is reached in the targetPercentile for each observationTimeStep.
     * @type {Array<GapFactorByTimeStepResponse>}
     * @memberof GapResponse
     */
    gapFactors?: Array<GapFactorByTimeStepResponse>;
}
/**
 * 
 * @export
 * @interface GapSimulationSettingsRequest
 */
export interface GapSimulationSettingsRequest {
    /**
     * Provided by /simulation/scenario-sets. The latest production set will be used if omitted (recommended).
     * @type {string}
     * @memberof GapSimulationSettingsRequest
     */
    scenarioSetId?: string;
    /**
     * The time steps for which forecasted values will be returned in the response. We use monthly time steps in the simulation, so e.g. 12 means observing values one year ahead.
     * @type {Array<number>}
     * @memberof GapSimulationSettingsRequest
     */
    observationTimeSteps: Array<number>;
    /**
     * The way the output will be adjusted for inflation.
     * @type {InflationAdjustmentContract}
     * @memberof GapSimulationSettingsRequest
     */
    inflationAdjustment?: InflationAdjustmentContract;
    /**
     * If true (default), the tax year will start on the first time step (the month defined in start date), resulting in an initial full tax year. If false, the initial tax year will only consider the number of months left until the next calendar year.
     * @type {boolean}
     * @memberof GapSimulationSettingsRequest
     */
    calculateAnnualTaxRelativeStartTimeStep?: boolean;
    /**
     * If true (default), the tax amount can be negative in case the deduction due to losses is greater than income, i.e. an amount will be paid out from the tax calculation. E.g. there is a deduction on an endowment insurance but no income to apply the set-off against, byt it is still assumed that the deduction can be made.
     * @type {boolean}
     * @memberof GapSimulationSettingsRequest
     */
    allowNegativePersonalTax?: boolean;
    /**
     * If true, No base deduction (allowance) will be applied to ISK or endowment insurance accounts.
     * @type {boolean}
     * @memberof GapSimulationSettingsRequest
     */
    disableInvestmentAccountBaseDeduction?: boolean;
}
/**
 * 
 * @export
 * @interface GroupResultForecastResponse
 */
export interface GroupResultForecastResponse {
    /**
     * 
     * @type {number}
     * @memberof GroupResultForecastResponse
     */
    timeStep?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupResultForecastResponse
     */
    expectedValue?: number;
}
/**
 * 
 * @export
 * @interface GroupResultResponse
 */
export interface GroupResultResponse {
    /**
     * 
     * @type {string}
     * @memberof GroupResultResponse
     */
    groupId?: string;
    /**
     * The average outcomes for each observation in observationTimeSteps.
     * @type {Array<GroupResultForecastResponse>}
     * @memberof GroupResultResponse
     */
    forecast?: Array<GroupResultForecastResponse>;
}
/**
 * 
 * @export
 * @interface IncomePensionAccountRequest
 */
export interface IncomePensionAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof IncomePensionAccountRequest
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof IncomePensionAccountRequest
     */
    currentValue: number;
}
/**
 * 
 * @export
 * @interface IncomeTransactionsRequest
 */
export interface IncomeTransactionsRequest {
    /**
     * One-time earned income cash flows.
     * @type {Array<OneTimeMoneyTransaction>}
     * @memberof IncomeTransactionsRequest
     */
    oneTimeTransactions?: Array<OneTimeMoneyTransaction>;
    /**
     * Recurring earned income cash flows.
     * @type {Array<RecurringMoneyTransaction>}
     * @memberof IncomeTransactionsRequest
     */
    recurringTransactions?: Array<RecurringMoneyTransaction>;
    /**
     * A defined series of earned income cash flows. Typically used for a salary trajectory.
     * @type {Array<TransactionMoneyArray>}
     * @memberof IncomeTransactionsRequest
     */
    transactionArrays?: Array<TransactionMoneyArray>;
    /**
     * 
     * @type {boolean}
     * @memberof IncomeTransactionsRequest
     */
    hasTransactions?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum InflationAdjustmentContract {
    NoAdjustment = <any> 'NoAdjustment',
    ValueAsOfStartDate = <any> 'ValueAsOfStartDate'
}
/**
 * 
 * @export
 * @interface InvestmentAccountAllocation
 */
export interface InvestmentAccountAllocation {
    /**
     * The relative annual fee expressed as a fraction. Must be between 0 and 1.
     * @type {number}
     * @memberof InvestmentAccountAllocation
     */
    annualFee: number;
    /**
     * The spread expressed as a fraction over the growth rate of the underlying model. Must be between 0 and 1 if defined.
     * @type {number}
     * @memberof InvestmentAccountAllocation
     */
    spreadOverGrowthRate?: number;
    /**
     * The weight of the allocation in the investment strategy. Sum of all investment allocation weights must be 1.
     * @type {number}
     * @memberof InvestmentAccountAllocation
     */
    strategyWeight: number;
    /**
     * The identifying code of the product, e.g. ISIN.  Full list: /simulation/product/mapped-products.
     * @type {ProductCodeContract}
     * @memberof InvestmentAccountAllocation
     */
    productCode: ProductCodeContract;
    /**
     * 
     * @type {number}
     * @memberof InvestmentAccountAllocation
     */
    currentValue: number;
    /**
     * Acquisition value of the allocation. Used to calculate tax (applicable for SweCapitalGains).
     * @type {number}
     * @memberof InvestmentAccountAllocation
     */
    acquisitionValue?: number;
}
/**
 * 
 * @export
 * @interface InvestmentAccountRequest
 */
export interface InvestmentAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof InvestmentAccountRequest
     */
    id: string;
    /**
     * The holdings of the investment account.
     * @type {Array<InvestmentAccountAllocation>}
     * @memberof InvestmentAccountRequest
     */
    investmentAllocations: Array<InvestmentAccountAllocation>;
    /**
     * The type of taxation the account is subject to.
     * @type {InvestmentAccountTaxationType}
     * @memberof InvestmentAccountRequest
     */
    taxationType: InvestmentAccountTaxationType;
    /**
     * The investment strategy.
     * @type {InvestmentStrategyType}
     * @memberof InvestmentAccountRequest
     */
    strategy: InvestmentStrategyType;
    /**
     * The relative annual fee expressed as fraction for the account. Must be between 0 and 1 if defined.
     * @type {number}
     * @memberof InvestmentAccountRequest
     */
    annualAccountFee?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum InvestmentAccountTaxationType {
    NoTax = <any> 'NoTax',
    SweIsk = <any> 'SweIsk',
    SweEndowmentInsurance = <any> 'SweEndowmentInsurance',
    GbrIsa = <any> 'GbrIsa',
    GbrSipp = <any> 'GbrSipp',
    SweCapitalGains = <any> 'SweCapitalGains'
}
/**
 * 
 * @export
 * @interface InvestmentAllocation
 */
export interface InvestmentAllocation {
    /**
     * Current allocation weight. Weights must sum to 1.
     * @type {number}
     * @memberof InvestmentAllocation
     */
    currentWeight?: number;
    /**
     * 
     * @type {ProductCodeContract}
     * @memberof InvestmentAllocation
     */
    productCode?: ProductCodeContract;
}
/**
 * 
 * @export
 * @interface InvestmentAllocationRequest
 */
export interface InvestmentAllocationRequest {
    /**
     * The relative annual fee expressed as a fraction. Must be between 0 and 1.
     * @type {number}
     * @memberof InvestmentAllocationRequest
     */
    annualFee: number;
    /**
     * The spread expressed as a fraction over the growth rate of the underlying model. Must be between 0 and 1 if defined.
     * @type {number}
     * @memberof InvestmentAllocationRequest
     */
    spreadOverGrowthRate?: number;
    /**
     * The weight of the allocation in the investment strategy. Sum of all investment allocation weights must be 1.
     * @type {number}
     * @memberof InvestmentAllocationRequest
     */
    strategyWeight: number;
    /**
     * The identifying code of the product, e.g. ISIN.  Full list: /simulation/product/mapped-products.
     * @type {ProductCodeContract}
     * @memberof InvestmentAllocationRequest
     */
    productCode: ProductCodeContract;
    /**
     * 
     * @type {number}
     * @memberof InvestmentAllocationRequest
     */
    currentValue: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum InvestmentStrategyType {
    RebalanceToPlan = <any> 'RebalanceToPlan',
    BuyAndHold = <any> 'BuyAndHold'
}
/**
 * 
 * @export
 * @interface MaxReturnPortfolioOptimizationV3Request
 */
export interface MaxReturnPortfolioOptimizationV3Request {
    /**
     * Provided by /simulation/scenario-sets. The latest production set will be used if omitted (recommended).
     * @type {string}
     * @memberof MaxReturnPortfolioOptimizationV3Request
     */
    scenarioSetId?: string;
    /**
     * Time horizon in months. Has to be strictly positive.
     * @type {number}
     * @memberof MaxReturnPortfolioOptimizationV3Request
     */
    horizonInMonths: number;
    /**
     * Level of desired risk. If not feasible given the investment allocations, then next best level will be found. See OptimizationStatus in response.
     * @type {RiskLevel}
     * @memberof MaxReturnPortfolioOptimizationV3Request
     */
    riskLevel?: RiskLevel;
    /**
     * If an explicit volatility target is given, this overrides the risk level.
     * @type {number}
     * @memberof MaxReturnPortfolioOptimizationV3Request
     */
    explicitVolatilityTarget?: number;
    /**
     * The starting point for the optimization. Only instruments given here are considered when optimizing the portfolio.
     * @type {Array<InvestmentAllocation>}
     * @memberof MaxReturnPortfolioOptimizationV3Request
     */
    investmentAllocations: Array<InvestmentAllocation>;
}
/**
 * 
 * @export
 * @interface MortgageRequest
 */
export interface MortgageRequest {
    /**
     * 
     * @type {string}
     * @memberof MortgageRequest
     */
    id: string;
    /**
     * Length of the remaining rate fixing period.
     * @type {number}
     * @memberof MortgageRequest
     */
    fixedRatePeriodInMonths: number;
    /**
     * The current annual interest rate of the mortgage.
     * @type {number}
     * @memberof MortgageRequest
     */
    annualInterestRate: number;
    /**
     * The id of the item (typically a transaction account) in assetsAndDebts from which interest payments are drawn.
     * @type {string}
     * @memberof MortgageRequest
     */
    interestRatePaymentAccountId: string;
    /**
     * 
     * @type {number}
     * @memberof MortgageRequest
     */
    currentValue: number;
}
/**
 * 
 * @export
 * @interface OccupationalPayoutSchemeRequest
 */
export interface OccupationalPayoutSchemeRequest {
    /**
     * Number of months from startDate until the first payout will take place.
     * @type {number}
     * @memberof OccupationalPayoutSchemeRequest
     */
    numberOfMonthsUntilPayout: number;
    /**
     * The type of payout scheme.
     * @type {OccupationalPayoutSchemeType}
     * @memberof OccupationalPayoutSchemeRequest
     */
    schemeType: OccupationalPayoutSchemeType;
    /**
     * The length of the payout period if the type is FixedPeriod.
     * @type {number}
     * @memberof OccupationalPayoutSchemeRequest
     */
    fixedPeriodInMonths?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OccupationalPayoutSchemeType {
    LifeLong = <any> 'LifeLong',
    FixedPeriod = <any> 'FixedPeriod'
}
/**
 * 
 * @export
 * @interface OneTimeMoneyTransaction
 */
export interface OneTimeMoneyTransaction {
    /**
     * The id of the item in assetsAndDebts to which the amount will be added.
     * @type {string}
     * @memberof OneTimeMoneyTransaction
     */
    targetId: string;
    /**
     * The time step when the transaction will occur.
     * @type {number}
     * @memberof OneTimeMoneyTransaction
     */
    timeStep: number;
    /**
     * 
     * @type {number}
     * @memberof OneTimeMoneyTransaction
     */
    amount: number;
    /**
     * Defines how the amount will be adjusted for inflation.
     * @type {InflationAdjustmentContract}
     * @memberof OneTimeMoneyTransaction
     */
    inflationAdjustment: InflationAdjustmentContract;
}
/**
 * 
 * @export
 * @interface OneTimeUnitTransaction
 */
export interface OneTimeUnitTransaction {
    /**
     * The id of the item in assetsAndDebts from which the transaction will be withdrawn/sold.
     * @type {string}
     * @memberof OneTimeUnitTransaction
     */
    sourceId?: string;
    /**
     * The id of the item in assetsAndDebts to which the transaction will be added/bought.
     * @type {string}
     * @memberof OneTimeUnitTransaction
     */
    targetId?: string;
    /**
     * The time step when the transaction will occur.
     * @type {number}
     * @memberof OneTimeUnitTransaction
     */
    timeStep: number;
    /**
     * If unit = \"MonetaryAmount\" or \"MontetaryAmountValueAsOfStartDate\" this input takes any amount.<br />  If unit = \"ShareOfAssetsAndDebtsItem\" this input takes a percentage as a fraction, where value = 1 means 100%.<br />      - If sourceId is defined, value will reference the sourceId when determining the size of the transaction.<br />      - If only targetId is defined, value will reference the targetId when determining the size of the transaction.
     * @type {number}
     * @memberof OneTimeUnitTransaction
     */
    value: number;
    /**
     * The unit in which value is expressed.
     * @type {TransactionUnitRequest}
     * @memberof OneTimeUnitTransaction
     */
    unit: TransactionUnitRequest;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OptimizationStatus {
    FoundOptimalPortfolio = <any> 'FoundOptimalPortfolio',
    FoundHighestPossibleRiskBelowTarget = <any> 'FoundHighestPossibleRiskBelowTarget',
    FoundLowestPossibleRiskAboveTarget = <any> 'FoundLowestPossibleRiskAboveTarget'
}
/**
 * 
 * @export
 * @interface OtherTransactionsRequest
 */
export interface OtherTransactionsRequest {
    /**
     * One-time transactions/cash flows.
     * @type {Array<OneTimeUnitTransaction>}
     * @memberof OtherTransactionsRequest
     */
    oneTimeTransactions?: Array<OneTimeUnitTransaction>;
    /**
     * Recurring transactions/cash flows.
     * @type {Array<RecurringUnitTransaction>}
     * @memberof OtherTransactionsRequest
     */
    recurringTransactions?: Array<RecurringUnitTransaction>;
    /**
     * A defined series of transactions/cash flows.
     * @type {Array<TransactionUnitArray>}
     * @memberof OtherTransactionsRequest
     */
    transactionArrays?: Array<TransactionUnitArray>;
}
/**
 * 
 * @export
 * @interface OutcomeResponse
 */
export interface OutcomeResponse {
    /**
     * 
     * @type {number}
     * @memberof OutcomeResponse
     */
    timeStep?: number;
    /**
     * 
     * @type {number}
     * @memberof OutcomeResponse
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface PayoutSchemeRequest
 */
export interface PayoutSchemeRequest {
    /**
     * Start time step for the national pension payouts. The time step specified must correspond to a valid retirement date (based on domicile specific pension rules).
     * @type {number}
     * @memberof PayoutSchemeRequest
     */
    timeStep: number;
    /**
     * The rate of retirement. Must increase over time.
     * @type {RetirementRateRequest}
     * @memberof PayoutSchemeRequest
     */
    retirementRate: RetirementRateRequest;
}
/**
 * 
 * @export
 * @interface PensionAccountsRequest
 */
export interface PensionAccountsRequest {
    /**
     * Holdings and payout scheme of Swedish state pension.
     * @type {SweNationalPensionRequest}
     * @memberof PensionAccountsRequest
     */
    sweNationalPension?: SweNationalPensionRequest;
    /**
     * Holdings and payout scheme of Swedish occupational pension.
     * @type {Array<SweOccupationalPensionAccountRequest>}
     * @memberof PensionAccountsRequest
     */
    sweOccupationalPensionAccounts?: Array<SweOccupationalPensionAccountRequest>;
}
/**
 * 
 * @export
 * @interface PercentileForecastResponse
 */
export interface PercentileForecastResponse {
    /**
     * The percentile (in fraction) for the outcome.
     * @type {number}
     * @memberof PercentileForecastResponse
     */
    percentile?: number;
    /**
     * Outcomes for each observation in observationTimeSteps.
     * @type {Array<OutcomeResponse>}
     * @memberof PercentileForecastResponse
     */
    outcomes?: Array<OutcomeResponse>;
}
/**
 * 
 * @export
 * @interface PointInTimeCashFlowResponse
 */
export interface PointInTimeCashFlowResponse {
    /**
     * 
     * @type {number}
     * @memberof PointInTimeCashFlowResponse
     */
    timeStep?: number;
    /**
     * 
     * @type {number}
     * @memberof PointInTimeCashFlowResponse
     */
    _in?: number;
    /**
     * 
     * @type {number}
     * @memberof PointInTimeCashFlowResponse
     */
    out?: number;
    /**
     * 
     * @type {number}
     * @memberof PointInTimeCashFlowResponse
     */
    net?: number;
}
/**
 * 
 * @export
 * @interface PortfolioAllocation
 */
export interface PortfolioAllocation {
    /**
     * Allocation weight.
     * @type {number}
     * @memberof PortfolioAllocation
     */
    weight?: number;
    /**
     * 
     * @type {ProductCodeContract}
     * @memberof PortfolioAllocation
     */
    productCode?: ProductCodeContract;
}
/**
 * 
 * @export
 * @interface PortfolioInformation
 */
export interface PortfolioInformation {
    /**
     * Allocation weights of the portfolio.
     * @type {Array<PortfolioAllocation>}
     * @memberof PortfolioInformation
     */
    weights?: Array<PortfolioAllocation>;
    /**
     * Expected annual arithmetic return of the portfolio over the horizon specified in the request.
     * @type {number}
     * @memberof PortfolioInformation
     */
    empiricalArithmeticReturn?: number;
    /**
     * Expected annual geometric return of the portfolio over the horizon specified in the request.
     * @type {number}
     * @memberof PortfolioInformation
     */
    empiricalGeometricReturn?: number;
    /**
     * Annualized volatility of the arithmetic portfolio returns calculated using the covariance matrix.
     * @type {number}
     * @memberof PortfolioInformation
     */
    parametricVolatilty?: number;
    /**
     * Annualized volatility of the arithmetic portfolio returns.
     * @type {number}
     * @memberof PortfolioInformation
     */
    empiricalVolatilty?: number;
    /**
     * Annual value at risk of the portfolio at the 95th worst percentile of the arithmetic annual portfolio returns.
     * @type {number}
     * @memberof PortfolioInformation
     */
    empiricalValueAtRisk95?: number;
    /**
     * Annual expected shortfall (aka Conditional VaR) of the portfolio at the 95th worst percentile of the arithmetic annual portfolio returns.
     * @type {number}
     * @memberof PortfolioInformation
     */
    empricalExpectedShortfall95?: number;
    /**
     * Estimated correlation matrix.
     * @type {{ [key: string]: number; }}
     * @memberof PortfolioInformation
     */
    correlations?: { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface PortfolioOptimizationV3Response
 */
export interface PortfolioOptimizationV3Response {
    /**
     * Portfolio information for the original portfolio.
     * @type {PortfolioInformation}
     * @memberof PortfolioOptimizationV3Response
     */
    originalPortfolio?: PortfolioInformation;
    /**
     * Portfolio information for the optimized portfolio.
     * @type {PortfolioInformation}
     * @memberof PortfolioOptimizationV3Response
     */
    optimalPortfolio?: PortfolioInformation;
    /**
     * Information about what optimal result has been reached.
     * @type {OptimizationStatus}
     * @memberof PortfolioOptimizationV3Response
     */
    status?: OptimizationStatus;
}
/**
 * 
 * @export
 * @interface PortfolioStatisticsV3Request
 */
export interface PortfolioStatisticsV3Request {
    /**
     * Provided by /simulation/scenario-sets. The latest production set will be used if omitted (recommended).
     * @type {string}
     * @memberof PortfolioStatisticsV3Request
     */
    scenarioSetId?: string;
    /**
     * Time horizon in months. Has to be strictly positive.
     * @type {number}
     * @memberof PortfolioStatisticsV3Request
     */
    horizonInMonths: number;
    /**
     * The efficient frontier will be created with respect to these allowed investments.
     * @type {Array<InvestmentAllocation>}
     * @memberof PortfolioStatisticsV3Request
     */
    investmentAllocations: Array<InvestmentAllocation>;
}
/**
 * 
 * @export
 * @interface PortfolioStatisticsV3Response
 */
export interface PortfolioStatisticsV3Response {
    /**
     * Information about the portfolio.
     * @type {PortfolioInformation}
     * @memberof PortfolioStatisticsV3Response
     */
    portfolioInformation?: PortfolioInformation;
}
/**
 * 
 * @export
 * @interface PortfolioStrategiesRequest
 */
export interface PortfolioStrategiesRequest {
    /**
     * Tiered strategies where the portfolio will be rebalanced according to thresholds in the tiers.
     * @type {Array<WaterfallAllocationStrategyRequest>}
     * @memberof PortfolioStrategiesRequest
     */
    waterfallAllocationStrategies?: Array<WaterfallAllocationStrategyRequest>;
}
/**
 * 
 * @export
 * @interface PortfoliooptimizationEfficientfrontierBody
 */
export interface PortfoliooptimizationEfficientfrontierBody extends EfficientFrontierV3Request {
}
/**
 * 
 * @export
 * @interface PortfoliooptimizationEfficientfrontierBody1
 */
export interface PortfoliooptimizationEfficientfrontierBody1 extends EfficientFrontierV3Request {
}
/**
 * 
 * @export
 * @interface PortfoliooptimizationEfficientfrontierBody2
 */
export interface PortfoliooptimizationEfficientfrontierBody2 extends EfficientFrontierV3Request {
}
/**
 * 
 * @export
 * @interface PortfoliooptimizationEfficientfrontierBody3
 */
export interface PortfoliooptimizationEfficientfrontierBody3 extends EfficientFrontierV3Request {
}
/**
 * 
 * @export
 * @interface PortfoliooptimizationMaxreturnBody
 */
export interface PortfoliooptimizationMaxreturnBody extends MaxReturnPortfolioOptimizationV3Request {
}
/**
 * 
 * @export
 * @interface PortfoliooptimizationMaxreturnBody1
 */
export interface PortfoliooptimizationMaxreturnBody1 extends MaxReturnPortfolioOptimizationV3Request {
}
/**
 * 
 * @export
 * @interface PortfoliooptimizationMaxreturnBody2
 */
export interface PortfoliooptimizationMaxreturnBody2 extends MaxReturnPortfolioOptimizationV3Request {
}
/**
 * 
 * @export
 * @interface PortfoliooptimizationMaxreturnBody3
 */
export interface PortfoliooptimizationMaxreturnBody3 extends MaxReturnPortfolioOptimizationV3Request {
}
/**
 * 
 * @export
 * @interface PortfoliooptimizationPortfoliostatisticsBody
 */
export interface PortfoliooptimizationPortfoliostatisticsBody extends PortfolioStatisticsV3Request {
}
/**
 * 
 * @export
 * @interface PortfoliooptimizationPortfoliostatisticsBody1
 */
export interface PortfoliooptimizationPortfoliostatisticsBody1 extends PortfolioStatisticsV3Request {
}
/**
 * 
 * @export
 * @interface PortfoliooptimizationPortfoliostatisticsBody2
 */
export interface PortfoliooptimizationPortfoliostatisticsBody2 extends PortfolioStatisticsV3Request {
}
/**
 * 
 * @export
 * @interface PortfoliooptimizationPortfoliostatisticsBody3
 */
export interface PortfoliooptimizationPortfoliostatisticsBody3 extends PortfolioStatisticsV3Request {
}
/**
 * 
 * @export
 * @interface ProductCodeContract
 */
export interface ProductCodeContract {
    /**
     * Type of code or identifier.
     * @type {ProductCodeType}
     * @memberof ProductCodeContract
     */
    codeType: ProductCodeType;
    /**
     * The code or identifier, e.g. ISIN of a fund.
     * @type {string}
     * @memberof ProductCodeContract
     */
    code: string;
}
/**
 * 
 * @export
 * @interface ProductCodeContractResponse
 */
export interface ProductCodeContractResponse {
    /**
     * Type of code or identifier.
     * @type {ProductCodeType}
     * @memberof ProductCodeContractResponse
     */
    codeType: ProductCodeType;
    /**
     * The code or identifier, e.g. ISIN of a fund.
     * @type {string}
     * @memberof ProductCodeContractResponse
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCodeContractResponse
     */
    name: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ProductCodeType {
    Custom = <any> 'Custom',
    Isin = <any> 'Isin'
}
/**
 * 
 * @export
 * @interface RealEstateRequest
 */
export interface RealEstateRequest {
    /**
     * 
     * @type {string}
     * @memberof RealEstateRequest
     */
    id: string;
    /**
     * 
     * @type {TypeOfRealEstateRequest}
     * @memberof RealEstateRequest
     */
    typeOfProperty: TypeOfRealEstateRequest;
    /**
     * The spread expressed as a fraction over the growth rate of the underlying model. Must be between 0 and 1 if defined.
     * @type {number}
     * @memberof RealEstateRequest
     */
    spreadOverGrowthRate?: number;
    /**
     * Acquisition value of the allocation. Used to calculate tax.
     * @type {number}
     * @memberof RealEstateRequest
     */
    acquisitionValue?: number;
    /**
     * 
     * @type {number}
     * @memberof RealEstateRequest
     */
    currentValue: number;
}
/**
 * 
 * @export
 * @interface RecurringMoneyTransaction
 */
export interface RecurringMoneyTransaction {
    /**
     * The id of the item in assetsAndDebts to which the amount will be added.
     * @type {string}
     * @memberof RecurringMoneyTransaction
     */
    targetId: string;
    /**
     * The time step when the transactions will start to occur.
     * @type {number}
     * @memberof RecurringMoneyTransaction
     */
    startTimeStep: number;
    /**
     * The number of recurring transactions.
     * @type {number}
     * @memberof RecurringMoneyTransaction
     */
    numberOfTransactions: number;
    /**
     * The frequency of which the transactions will occur.
     * @type {Frequency}
     * @memberof RecurringMoneyTransaction
     */
    frequency: Frequency;
    /**
     * 
     * @type {number}
     * @memberof RecurringMoneyTransaction
     */
    amount: number;
    /**
     * Defines how the amounts will be adjusted for inflation.
     * @type {InflationAdjustmentContract}
     * @memberof RecurringMoneyTransaction
     */
    inflationAdjustment: InflationAdjustmentContract;
}
/**
 * 
 * @export
 * @interface RecurringUnitTransaction
 */
export interface RecurringUnitTransaction {
    /**
     * The id of the item in assetsAndDebts from which the transaction will be withdrawn/sold.
     * @type {string}
     * @memberof RecurringUnitTransaction
     */
    sourceId?: string;
    /**
     * The id of the item in assetsAndDebts to which the transaction will be added/bought.
     * @type {string}
     * @memberof RecurringUnitTransaction
     */
    targetId?: string;
    /**
     * The time step when the transactions will start to occur.
     * @type {number}
     * @memberof RecurringUnitTransaction
     */
    startTimeStep: number;
    /**
     * The number of recurring transactions.
     * @type {number}
     * @memberof RecurringUnitTransaction
     */
    numberOfTransactions: number;
    /**
     * The frequency of which the transactions will occur.
     * @type {Frequency}
     * @memberof RecurringUnitTransaction
     */
    frequency: Frequency;
    /**
     * If unit = \"MonetaryAmount\" or \"MontetaryAmountValueAsOfStartDate\" this input takes any amount.<br />  If unit = \"ShareOfAssetsAndDebtsItem\" this input takes a percentage as a fraction, where value = 1 means 100%.<br />      - If sourceId is defined, value will reference the sourceId when determining the size of the transaction.<br />      - If only targetId is defined, value will reference the targetId when determining the size of the transaction.
     * @type {number}
     * @memberof RecurringUnitTransaction
     */
    value: number;
    /**
     * The unit in which value is expressed.
     * @type {TransactionUnitRequest}
     * @memberof RecurringUnitTransaction
     */
    unit: TransactionUnitRequest;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum RetirementRateRequest {
    Percent25 = <any> 'Percent25',
    Percent50 = <any> 'Percent50',
    Percent75 = <any> 'Percent75',
    Percent100 = <any> 'Percent100'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum RiskLevel {
    NoRisk = <any> 'NoRisk',
    VeryLowRisk = <any> 'VeryLowRisk',
    LowRisk = <any> 'LowRisk',
    MediumLowRisk = <any> 'MediumLowRisk',
    MediumHighRisk = <any> 'MediumHighRisk',
    HighRisk = <any> 'HighRisk',
    VeryHighRisk = <any> 'VeryHighRisk'
}
/**
 * 
 * @export
 * @interface ScenarioSetResponseModel
 */
export interface ScenarioSetResponseModel {
    /**
     * 
     * @type {string}
     * @memberof ScenarioSetResponseModel
     */
    scenarioSetId?: string;
    /**
     * 
     * @type {Date}
     * @memberof ScenarioSetResponseModel
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof ScenarioSetResponseModel
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ScenarioSetResponseModel
     */
    usageType?: string;
}
/**
 * 
 * @export
 * @interface SimulationSettingsRequest
 */
export interface SimulationSettingsRequest {
    /**
     * Provided by /simulation/scenario-sets. The latest production set will be used if omitted (recommended).
     * @type {string}
     * @memberof SimulationSettingsRequest
     */
    scenarioSetId?: string;
    /**
     * The time steps for which forecasted values will be returned in the response. We use monthly time steps in the simulation, so e.g. 12 means observing values one year ahead.
     * @type {Array<number>}
     * @memberof SimulationSettingsRequest
     */
    observationTimeSteps: Array<number>;
    /**
     * The percentiles for which forecasted values will be returned in the response. The percentiles are expressed as fractions, and must be between 0 and 1. Percentile 0.5 corresponds to the median outcome.
     * @type {Array<number>}
     * @memberof SimulationSettingsRequest
     */
    percentiles: Array<number>;
    /**
     * Define if and for what frequency cash flows should be added to the output.
     * @type {CashFlowObservationsRequest}
     * @memberof SimulationSettingsRequest
     */
    cashFlowObservations?: CashFlowObservationsRequest;
    /**
     * Groups of accounts to be added to the output (common to group e.g. real estate and mortgage together). Expected values over time will be added to the response for all groups specified.
     * @type {Array<AssetsAndDebtsItemGroupRequest>}
     * @memberof SimulationSettingsRequest
     */
    assetsAndDebtsItemGroups?: Array<AssetsAndDebtsItemGroupRequest>;
    /**
     * The way the output will be adjusted for inflation.
     * @type {InflationAdjustmentContract}
     * @memberof SimulationSettingsRequest
     */
    inflationAdjustment?: InflationAdjustmentContract;
    /**
     * If true (default), the tax year will start on the first time step (the month defined in start date), resulting in an initial full tax year. If false, the initial tax year will only consider the number of months left until the next calendar year.
     * @type {boolean}
     * @memberof SimulationSettingsRequest
     */
    calculateAnnualTaxRelativeStartTimeStep?: boolean;
    /**
     * If true (default), the tax amount can be negative in case the deduction due to losses is greater than income, i.e. an amount will be paid out from the tax calculation. E.g. there is a deduction on an endowment insurance but no income to apply the set-off against, byt it is still assumed that the deduction can be made.
     * @type {boolean}
     * @memberof SimulationSettingsRequest
     */
    allowNegativePersonalTax?: boolean;
    /**
     * If true, No base deduction (allowance) will be applied to ISK or endowment insurance accounts.
     * @type {boolean}
     * @memberof SimulationSettingsRequest
     */
    disableInvestmentAccountBaseDeduction?: boolean;
}
/**
 * 
 * @export
 * @interface SweAllocationPensionAccountRequest
 */
export interface SweAllocationPensionAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof SweAllocationPensionAccountRequest
     */
    id: string;
    /**
     * The holdings of the pension account.
     * @type {Array<InvestmentAllocationRequest>}
     * @memberof SweAllocationPensionAccountRequest
     */
    investmentAllocations: Array<InvestmentAllocationRequest>;
    /**
     * The investment strategy.
     * @type {InvestmentStrategyType}
     * @memberof SweAllocationPensionAccountRequest
     */
    strategy: InvestmentStrategyType;
}
/**
 * 
 * @export
 * @interface SweNationalPensionRequest
 */
export interface SweNationalPensionRequest {
    /**
     * The payout scheme specifies when to retire and the rate of retirement.
     * @type {Array<PayoutSchemeRequest>}
     * @memberof SweNationalPensionRequest
     */
    payoutScheme: Array<PayoutSchemeRequest>;
    /**
     * The account for Swedish income pension.
     * @type {IncomePensionAccountRequest}
     * @memberof SweNationalPensionRequest
     */
    incomePensionAccount: IncomePensionAccountRequest;
    /**
     * The account and investment allocations for Swedish premium pension.
     * @type {SweAllocationPensionAccountRequest}
     * @memberof SweNationalPensionRequest
     */
    premiumPensionAccount: SweAllocationPensionAccountRequest;
}
/**
 * 
 * @export
 * @interface SweOccupationalPensionAccountRequest
 */
export interface SweOccupationalPensionAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof SweOccupationalPensionAccountRequest
     */
    id: string;
    /**
     * The holdings of the pension account.
     * @type {Array<InvestmentAllocationRequest>}
     * @memberof SweOccupationalPensionAccountRequest
     */
    investmentAllocations: Array<InvestmentAllocationRequest>;
    /**
     * The investment strategy.
     * @type {InvestmentStrategyType}
     * @memberof SweOccupationalPensionAccountRequest
     */
    strategy: InvestmentStrategyType;
    /**
     * The fees associated with the account.
     * @type {FeesRequest}
     * @memberof SweOccupationalPensionAccountRequest
     */
    fees: FeesRequest;
    /**
     * Defines when the payout starts and the length of the payout period.
     * @type {OccupationalPayoutSchemeRequest}
     * @memberof SweOccupationalPensionAccountRequest
     */
    payoutScheme: OccupationalPayoutSchemeRequest;
}
/**
 * 
 * @export
 * @interface SweTaxRequest
 */
export interface SweTaxRequest {
    /**
     * Swedish specific municipality tax rate. A 30% municipality tax rate would be specified as 0.3. If omitted a default tax rate will be used (weighted average based on number of residents in each municipality).
     * @type {number}
     * @memberof SweTaxRequest
     */
    municipalityTaxRate: number;
}
/**
 * 
 * @export
 * @interface TaxInformationRequest
 */
export interface TaxInformationRequest {
    /**
     * Tax information specific to the Swedish domicile.
     * @type {SweTaxRequest}
     * @memberof TaxInformationRequest
     */
    sweTax?: SweTaxRequest;
    /**
     * The account from which to deduct tax throughout the simulation.
     * @type {string}
     * @memberof TaxInformationRequest
     */
    taxPaymentAssetsAndDebtsItemId: string;
}
/**
 * 
 * @export
 * @interface TransactionAccountRequest
 */
export interface TransactionAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof TransactionAccountRequest
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionAccountRequest
     */
    currentValue: number;
    /**
     * The currency of this account. Current value is also expressed in this currency.
     * @type {CurrencyRequest}
     * @memberof TransactionAccountRequest
     */
    currency: CurrencyRequest;
}
/**
 * 
 * @export
 * @interface TransactionMoneyArray
 */
export interface TransactionMoneyArray {
    /**
     * The id of the item in assetsAndDebts to which the amount will be added.
     * @type {string}
     * @memberof TransactionMoneyArray
     */
    targetId: string;
    /**
     * The time step when the transactions will start to occur.
     * @type {number}
     * @memberof TransactionMoneyArray
     */
    startTimeStep: number;
    /**
     * The frequency of which the transactions will occur.
     * @type {Frequency}
     * @memberof TransactionMoneyArray
     */
    frequency: Frequency;
    /**
     * The series of amounts.
     * @type {Array<number>}
     * @memberof TransactionMoneyArray
     */
    amounts: Array<number>;
    /**
     * Defines how the amounts will be adjusted for inflation.
     * @type {InflationAdjustmentContract}
     * @memberof TransactionMoneyArray
     */
    inflationAdjustment: InflationAdjustmentContract;
}
/**
 * 
 * @export
 * @interface TransactionUnitArray
 */
export interface TransactionUnitArray {
    /**
     * The id of the item in assetsAndDebts from which the transaction will be withdrawn/sold.
     * @type {string}
     * @memberof TransactionUnitArray
     */
    sourceId?: string;
    /**
     * The id of the item in assetsAndDebts to which the transaction will be added/bought.
     * @type {string}
     * @memberof TransactionUnitArray
     */
    targetId?: string;
    /**
     * The time step when the transactions will start to occur.
     * @type {number}
     * @memberof TransactionUnitArray
     */
    startTimeStep: number;
    /**
     * The frequency of which the transactions will occur.
     * @type {Frequency}
     * @memberof TransactionUnitArray
     */
    frequency: Frequency;
    /**
     * The series of values.<br />  If unit = \"MonetaryAmount\" or \"MontetaryAmountValueAsOfStartDate\" this input takes any amount.<br />  If unit = \"ShareOfAssetsAndDebtsItem\" this input takes a percentage as a fraction, where value = 1 means 100%.<br />      - If sourceId is defined, value will reference the sourceId when determining the size of the transaction.<br />      - If only targetId is defined, value will reference the targetId when determining the size of the transaction.
     * @type {Array<number>}
     * @memberof TransactionUnitArray
     */
    values: Array<number>;
    /**
     * The unit in which values are expressed.
     * @type {TransactionUnitRequest}
     * @memberof TransactionUnitArray
     */
    unit: TransactionUnitRequest;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TransactionUnitRequest {
    MonetaryAmount = <any> 'MonetaryAmount',
    MonetaryAmountValueAsOfStartDate = <any> 'MonetaryAmountValueAsOfStartDate',
    ShareOfAssestsAndDebtsItem = <any> 'ShareOfAssestsAndDebtsItem'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TypeOfRealEstateRequest {
    SwePrivateHome = <any> 'SwePrivateHome'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TypeOfSharesRequest {
    SweK10 = <any> 'SweK10',
    SweK12 = <any> 'SweK12'
}
/**
 * 
 * @export
 * @interface UnlistedSharesRequestModel
 */
export interface UnlistedSharesRequestModel {
    /**
     * 
     * @type {string}
     * @memberof UnlistedSharesRequestModel
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof UnlistedSharesRequestModel
     */
    currentValue?: number;
    /**
     * Acquisition value of the allocation. Used to calculate tax when shares are sold.
     * @type {number}
     * @memberof UnlistedSharesRequestModel
     */
    acquisitionValue?: number;
    /**
     * Type of share. Used to calculate tax when shares are sold.
     * @type {TypeOfSharesRequest}
     * @memberof UnlistedSharesRequestModel
     */
    typeOfShares: TypeOfSharesRequest;
    /**
     * The identifying code of the product, e.g. ISIN. Full list: /simulation/product/mapped-products.
     * @type {ProductCodeContract}
     * @memberof UnlistedSharesRequestModel
     */
    productCode: ProductCodeContract;
}
/**
 * 
 * @export
 * @interface V3FinancialplanningBody
 */
export interface V3FinancialplanningBody extends FinancialPlanningV3Request {
}
/**
 * 
 * @export
 * @interface V3FinancialplanningBody1
 */
export interface V3FinancialplanningBody1 extends FinancialPlanningV3Request {
}
/**
 * 
 * @export
 * @interface V3FinancialplanningBody2
 */
export interface V3FinancialplanningBody2 extends FinancialPlanningV3Request {
}
/**
 * 
 * @export
 * @interface V3FinancialplanningBody3
 */
export interface V3FinancialplanningBody3 extends FinancialPlanningV3Request {
}
/**
 * 
 * @export
 * @interface V3FinancialplanninggapBody
 */
export interface V3FinancialplanninggapBody extends GapRequest {
}
/**
 * 
 * @export
 * @interface V3FinancialplanninggapBody1
 */
export interface V3FinancialplanninggapBody1 extends GapRequest {
}
/**
 * 
 * @export
 * @interface V3FinancialplanninggapBody2
 */
export interface V3FinancialplanninggapBody2 extends GapRequest {
}
/**
 * 
 * @export
 * @interface V3FinancialplanninggapBody3
 */
export interface V3FinancialplanninggapBody3 extends GapRequest {
}
/**
 * 
 * @export
 * @interface ValidationErrorModel
 */
export interface ValidationErrorModel {
    /**
     * 
     * @type {Array<FieldErrorModel>}
     * @memberof ValidationErrorModel
     */
    errors?: Array<FieldErrorModel>;
    /**
     * 
     * @type {string}
     * @memberof ValidationErrorModel
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface WaterfallAllocationStrategyRequest
 */
export interface WaterfallAllocationStrategyRequest {
    /**
     * The tiers of the strategy
     * @type {Array<WaterfallAllocationTierRequest>}
     * @memberof WaterfallAllocationStrategyRequest
     */
    tiers: Array<WaterfallAllocationTierRequest>;
}
/**
 * 
 * @export
 * @interface WaterfallAllocationTierRequest
 */
export interface WaterfallAllocationTierRequest {
    /**
     * Id of the item in assetsAndDebts for this tier.
     * @type {string}
     * @memberof WaterfallAllocationTierRequest
     */
    assetsAndDebtsItemId: string;
    /**
     * Priority of this tier, where lower value means higher priority.
     * @type {number}
     * @memberof WaterfallAllocationTierRequest
     */
    priority: number;
    /**
     * The value of the item to be reached before rebalancing to the next tier.
     * @type {number}
     * @memberof WaterfallAllocationTierRequest
     */
    targetValue: number;
    /**
     * Defines how the target value will be adjusted for inflation.
     * @type {InflationAdjustmentContract}
     * @memberof WaterfallAllocationTierRequest
     */
    inflationAdjustment?: InflationAdjustmentContract;
}
/**
 * 
 * @export
 * @interface YearMonthContract
 */
export interface YearMonthContract {
    /**
     * 
     * @type {number}
     * @memberof YearMonthContract
     */
    year: number;
    /**
     * 
     * @type {number}
     * @memberof YearMonthContract
     */
    month: number;
}
/**
 * FinancialPlanningApi - fetch parameter creator
 * @export
 */
export const FinancialPlanningApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Similar to financial-planning, but instead of returning forecasted values it is used for gap analysis. It can be used to find the (savings) gap  for a holistic financial situation or for a separate use case within e.g. pension, investments or credit.<br /><br />   The API comes with a granular cash flow model, capable of modeling future salary curves, savings levels, pension and tax payout strategies etc. in great detail.
         * @summary Financial Planning Gap
         * @param {V3FinancialplanninggapBody} body The body contains simulation settings, domicile/tax information and detailed information of a person&#x27;s asset, debts and cash flows.
            You also specify the target (savings goal) to be reached in a specific percentile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3FinancialPlanningGapPost(body: V3FinancialplanninggapBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling apiV3FinancialPlanningGapPost.');
            }
            const localVarPath = `/api/v3/financial-planning-gap`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V3FinancialplanninggapBody3" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Our most comprehensive and flexible API for simulating a person’s financial situation. It can be used for holistic and   full-balance sheet financial planning as well as for building separate use cases within pension, investments, credit, insurance or tax.<br /><br />  The API comes with a granular cash flow model, capable of modeling future salary curves, savings levels, pension and tax payout strategies etc. in great detail.
         * @summary Financial Planning
         * @param {V3FinancialplanningBody} body The body contains simulation settings, domicile/tax information and detailed information of a person&#x27;s asset, debts and cash flows.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3FinancialPlanningPost(body: V3FinancialplanningBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling apiV3FinancialPlanningPost.');
            }
            const localVarPath = `/api/v3/financial-planning`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V3FinancialplanningBody3" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns available historical stress scenario sets that can be used in simulation endpoints.
         * @summary Scenario Sets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3SimulationHistoricalStressScenarioSetsGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/v3/simulation/historical-stress-scenario-sets`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all configured investment products (e.g. mutual funds) and synthetic products that can be used in simulations for a given scenario set.
         * @summary Mapped Products
         * @param {string} scenarioSetId Provided by /simulation/scenario-sets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3SimulationProductMappedProductsGet(scenarioSetId: string, options: any = {}): FetchArgs {
            // verify required parameter 'scenarioSetId' is not null or undefined
            if (scenarioSetId === null || scenarioSetId === undefined) {
                throw new RequiredError('scenarioSetId','Required parameter scenarioSetId was null or undefined when calling apiV3SimulationProductMappedProductsGet.');
            }
            const localVarPath = `/api/v3/simulation/product/mapped-products`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (scenarioSetId !== undefined) {
                localVarQueryParameter['scenarioSetId'] = scenarioSetId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all available scenario sets that can be used in simulation endpoints.
         * @summary Scenario Sets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3SimulationScenarioSetsGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/v3/simulation/scenario-sets`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FinancialPlanningApi - functional programming interface
 * @export
 */
export const FinancialPlanningApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Similar to financial-planning, but instead of returning forecasted values it is used for gap analysis. It can be used to find the (savings) gap  for a holistic financial situation or for a separate use case within e.g. pension, investments or credit.<br /><br />   The API comes with a granular cash flow model, capable of modeling future salary curves, savings levels, pension and tax payout strategies etc. in great detail.
         * @summary Financial Planning Gap
         * @param {V3FinancialplanninggapBody} body The body contains simulation settings, domicile/tax information and detailed information of a person&#x27;s asset, debts and cash flows.
            You also specify the target (savings goal) to be reached in a specific percentile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3FinancialPlanningGapPost(body: V3FinancialplanninggapBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GapResponse> {
            const localVarFetchArgs = FinancialPlanningApiFetchParamCreator(configuration).apiV3FinancialPlanningGapPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Our most comprehensive and flexible API for simulating a person’s financial situation. It can be used for holistic and   full-balance sheet financial planning as well as for building separate use cases within pension, investments, credit, insurance or tax.<br /><br />  The API comes with a granular cash flow model, capable of modeling future salary curves, savings levels, pension and tax payout strategies etc. in great detail.
         * @summary Financial Planning
         * @param {V3FinancialplanningBody} body The body contains simulation settings, domicile/tax information and detailed information of a person&#x27;s asset, debts and cash flows.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3FinancialPlanningPost(body: V3FinancialplanningBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FinancialPlanningV3Response> {
            const localVarFetchArgs = FinancialPlanningApiFetchParamCreator(configuration).apiV3FinancialPlanningPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Returns available historical stress scenario sets that can be used in simulation endpoints.
         * @summary Scenario Sets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3SimulationHistoricalStressScenarioSetsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ScenarioSetResponseModel>> {
            const localVarFetchArgs = FinancialPlanningApiFetchParamCreator(configuration).apiV3SimulationHistoricalStressScenarioSetsGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Returns all configured investment products (e.g. mutual funds) and synthetic products that can be used in simulations for a given scenario set.
         * @summary Mapped Products
         * @param {string} scenarioSetId Provided by /simulation/scenario-sets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3SimulationProductMappedProductsGet(scenarioSetId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ProductCodeContractResponse>> {
            const localVarFetchArgs = FinancialPlanningApiFetchParamCreator(configuration).apiV3SimulationProductMappedProductsGet(scenarioSetId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Returns all available scenario sets that can be used in simulation endpoints.
         * @summary Scenario Sets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3SimulationScenarioSetsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ScenarioSetResponseModel>> {
            const localVarFetchArgs = FinancialPlanningApiFetchParamCreator(configuration).apiV3SimulationScenarioSetsGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * FinancialPlanningApi - factory interface
 * @export
 */
export const FinancialPlanningApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Similar to financial-planning, but instead of returning forecasted values it is used for gap analysis. It can be used to find the (savings) gap  for a holistic financial situation or for a separate use case within e.g. pension, investments or credit.<br /><br />   The API comes with a granular cash flow model, capable of modeling future salary curves, savings levels, pension and tax payout strategies etc. in great detail.
         * @summary Financial Planning Gap
         * @param {V3FinancialplanninggapBody} body The body contains simulation settings, domicile/tax information and detailed information of a person&#x27;s asset, debts and cash flows.
            You also specify the target (savings goal) to be reached in a specific percentile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3FinancialPlanningGapPost(body: V3FinancialplanninggapBody, options?: any) {
            return FinancialPlanningApiFp(configuration).apiV3FinancialPlanningGapPost(body, options)(fetch, basePath);
        },
        /**
         * Our most comprehensive and flexible API for simulating a person’s financial situation. It can be used for holistic and   full-balance sheet financial planning as well as for building separate use cases within pension, investments, credit, insurance or tax.<br /><br />  The API comes with a granular cash flow model, capable of modeling future salary curves, savings levels, pension and tax payout strategies etc. in great detail.
         * @summary Financial Planning
         * @param {V3FinancialplanningBody} body The body contains simulation settings, domicile/tax information and detailed information of a person&#x27;s asset, debts and cash flows.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3FinancialPlanningPost(body: V3FinancialplanningBody, options?: any) {
            return FinancialPlanningApiFp(configuration).apiV3FinancialPlanningPost(body, options)(fetch, basePath);
        },
        /**
         * Returns available historical stress scenario sets that can be used in simulation endpoints.
         * @summary Scenario Sets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3SimulationHistoricalStressScenarioSetsGet(options?: any) {
            return FinancialPlanningApiFp(configuration).apiV3SimulationHistoricalStressScenarioSetsGet(options)(fetch, basePath);
        },
        /**
         * Returns all configured investment products (e.g. mutual funds) and synthetic products that can be used in simulations for a given scenario set.
         * @summary Mapped Products
         * @param {string} scenarioSetId Provided by /simulation/scenario-sets.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3SimulationProductMappedProductsGet(scenarioSetId: string, options?: any) {
            return FinancialPlanningApiFp(configuration).apiV3SimulationProductMappedProductsGet(scenarioSetId, options)(fetch, basePath);
        },
        /**
         * Returns all available scenario sets that can be used in simulation endpoints.
         * @summary Scenario Sets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3SimulationScenarioSetsGet(options?: any) {
            return FinancialPlanningApiFp(configuration).apiV3SimulationScenarioSetsGet(options)(fetch, basePath);
        },
    };
};

/**
 * FinancialPlanningApi - object-oriented interface
 * @export
 * @class FinancialPlanningApi
 * @extends {BaseAPI}
 */
export class FinancialPlanningApi extends BaseAPI {
    /**
     * Similar to financial-planning, but instead of returning forecasted values it is used for gap analysis. It can be used to find the (savings) gap  for a holistic financial situation or for a separate use case within e.g. pension, investments or credit.<br /><br />   The API comes with a granular cash flow model, capable of modeling future salary curves, savings levels, pension and tax payout strategies etc. in great detail.
     * @summary Financial Planning Gap
     * @param {V3FinancialplanninggapBody} body The body contains simulation settings, domicile/tax information and detailed information of a person&#x27;s asset, debts and cash flows.
            You also specify the target (savings goal) to be reached in a specific percentile.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialPlanningApi
     */
    public apiV3FinancialPlanningGapPost(body: V3FinancialplanninggapBody, options?: any) {
        return FinancialPlanningApiFp(this.configuration).apiV3FinancialPlanningGapPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * Our most comprehensive and flexible API for simulating a person’s financial situation. It can be used for holistic and   full-balance sheet financial planning as well as for building separate use cases within pension, investments, credit, insurance or tax.<br /><br />  The API comes with a granular cash flow model, capable of modeling future salary curves, savings levels, pension and tax payout strategies etc. in great detail.
     * @summary Financial Planning
     * @param {V3FinancialplanningBody} body The body contains simulation settings, domicile/tax information and detailed information of a person&#x27;s asset, debts and cash flows.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialPlanningApi
     */
    public apiV3FinancialPlanningPost(body: V3FinancialplanningBody, options?: any) {
        return FinancialPlanningApiFp(this.configuration).apiV3FinancialPlanningPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * Returns available historical stress scenario sets that can be used in simulation endpoints.
     * @summary Scenario Sets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialPlanningApi
     */
    public apiV3SimulationHistoricalStressScenarioSetsGet(options?: any) {
        return FinancialPlanningApiFp(this.configuration).apiV3SimulationHistoricalStressScenarioSetsGet(options)(this.fetch, this.basePath);
    }

    /**
     * Returns all configured investment products (e.g. mutual funds) and synthetic products that can be used in simulations for a given scenario set.
     * @summary Mapped Products
     * @param {string} scenarioSetId Provided by /simulation/scenario-sets.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialPlanningApi
     */
    public apiV3SimulationProductMappedProductsGet(scenarioSetId: string, options?: any) {
        return FinancialPlanningApiFp(this.configuration).apiV3SimulationProductMappedProductsGet(scenarioSetId, options)(this.fetch, this.basePath);
    }

    /**
     * Returns all available scenario sets that can be used in simulation endpoints.
     * @summary Scenario Sets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialPlanningApi
     */
    public apiV3SimulationScenarioSetsGet(options?: any) {
        return FinancialPlanningApiFp(this.configuration).apiV3SimulationScenarioSetsGet(options)(this.fetch, this.basePath);
    }

}
/**
 * PortfolioAnalysisApi - fetch parameter creator
 * @export
 */
export const PortfolioAnalysisApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PortfoliooptimizationEfficientfrontierBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3PortfolioOptimizationEfficientFrontierPost(body: PortfoliooptimizationEfficientfrontierBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling apiV3PortfolioOptimizationEfficientFrontierPost.');
            }
            const localVarPath = `/api/v3/portfolio-optimization/efficient-frontier`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PortfoliooptimizationEfficientfrontierBody3" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Optimizes portfolio weights by maximizing returns given a risk level or an explicit volatility. Returns the optimal portfolio  weights and some statistical measures for the current and optimal portfolios.
         * @summary Portfolio Optimization Max Return
         * @param {PortfoliooptimizationMaxreturnBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3PortfolioOptimizationMaxReturnPost(body: PortfoliooptimizationMaxreturnBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling apiV3PortfolioOptimizationMaxReturnPost.');
            }
            const localVarPath = `/api/v3/portfolio-optimization/max-return`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PortfoliooptimizationMaxreturnBody3" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PortfoliooptimizationPortfoliostatisticsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3PortfolioOptimizationPortfolioStatisticsPost(body: PortfoliooptimizationPortfoliostatisticsBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling apiV3PortfolioOptimizationPortfolioStatisticsPost.');
            }
            const localVarPath = `/api/v3/portfolio-optimization/portfolio-statistics`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PortfoliooptimizationPortfoliostatisticsBody3" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortfolioAnalysisApi - functional programming interface
 * @export
 */
export const PortfolioAnalysisApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PortfoliooptimizationEfficientfrontierBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3PortfolioOptimizationEfficientFrontierPost(body: PortfoliooptimizationEfficientfrontierBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EfficientFrontierV3Response> {
            const localVarFetchArgs = PortfolioAnalysisApiFetchParamCreator(configuration).apiV3PortfolioOptimizationEfficientFrontierPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Optimizes portfolio weights by maximizing returns given a risk level or an explicit volatility. Returns the optimal portfolio  weights and some statistical measures for the current and optimal portfolios.
         * @summary Portfolio Optimization Max Return
         * @param {PortfoliooptimizationMaxreturnBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3PortfolioOptimizationMaxReturnPost(body: PortfoliooptimizationMaxreturnBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PortfolioOptimizationV3Response> {
            const localVarFetchArgs = PortfolioAnalysisApiFetchParamCreator(configuration).apiV3PortfolioOptimizationMaxReturnPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {PortfoliooptimizationPortfoliostatisticsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3PortfolioOptimizationPortfolioStatisticsPost(body: PortfoliooptimizationPortfoliostatisticsBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PortfolioStatisticsV3Response> {
            const localVarFetchArgs = PortfolioAnalysisApiFetchParamCreator(configuration).apiV3PortfolioOptimizationPortfolioStatisticsPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PortfolioAnalysisApi - factory interface
 * @export
 */
export const PortfolioAnalysisApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {PortfoliooptimizationEfficientfrontierBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3PortfolioOptimizationEfficientFrontierPost(body: PortfoliooptimizationEfficientfrontierBody, options?: any) {
            return PortfolioAnalysisApiFp(configuration).apiV3PortfolioOptimizationEfficientFrontierPost(body, options)(fetch, basePath);
        },
        /**
         * Optimizes portfolio weights by maximizing returns given a risk level or an explicit volatility. Returns the optimal portfolio  weights and some statistical measures for the current and optimal portfolios.
         * @summary Portfolio Optimization Max Return
         * @param {PortfoliooptimizationMaxreturnBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3PortfolioOptimizationMaxReturnPost(body: PortfoliooptimizationMaxreturnBody, options?: any) {
            return PortfolioAnalysisApiFp(configuration).apiV3PortfolioOptimizationMaxReturnPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {PortfoliooptimizationPortfoliostatisticsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3PortfolioOptimizationPortfolioStatisticsPost(body: PortfoliooptimizationPortfoliostatisticsBody, options?: any) {
            return PortfolioAnalysisApiFp(configuration).apiV3PortfolioOptimizationPortfolioStatisticsPost(body, options)(fetch, basePath);
        },
    };
};

/**
 * PortfolioAnalysisApi - object-oriented interface
 * @export
 * @class PortfolioAnalysisApi
 * @extends {BaseAPI}
 */
export class PortfolioAnalysisApi2 extends BaseAPI {
    /**
     * 
     * @param {PortfoliooptimizationEfficientfrontierBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioAnalysisApi
     */
    public apiV3PortfolioOptimizationEfficientFrontierPost(body: PortfoliooptimizationEfficientfrontierBody, options?: any) {
        return PortfolioAnalysisApiFp(this.configuration).apiV3PortfolioOptimizationEfficientFrontierPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * Optimizes portfolio weights by maximizing returns given a risk level or an explicit volatility. Returns the optimal portfolio  weights and some statistical measures for the current and optimal portfolios.
     * @summary Portfolio Optimization Max Return
     * @param {PortfoliooptimizationMaxreturnBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioAnalysisApi
     */
    public apiV3PortfolioOptimizationMaxReturnPost(body: PortfoliooptimizationMaxreturnBody, options?: any) {
        return PortfolioAnalysisApiFp(this.configuration).apiV3PortfolioOptimizationMaxReturnPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {PortfoliooptimizationPortfoliostatisticsBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioAnalysisApi
     */
    public apiV3PortfolioOptimizationPortfolioStatisticsPost(body: PortfoliooptimizationPortfoliostatisticsBody, options?: any) {
        return PortfolioAnalysisApiFp(this.configuration).apiV3PortfolioOptimizationPortfolioStatisticsPost(body, options)(this.fetch, this.basePath);
    }

}
