import { AddCircleOutline, Delete, Done, Edit, Expand, ExpandMore, Propane, SignalCellularAlt, Sync, TrendingUp } from "@mui/icons-material";
import { Badge, Box, Button, Collapse, Divider, FormControl, Grid, IconButton, IconButtonProps, InputLabel, MenuItem, Paper, Select, Slider, styled, Tab, Table, TableBody, TableCell, TableRow, Tabs, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import moment from "moment";
import React, { Fragment, useState, useContext, useEffect, Dispatch, SetStateAction } from "react";
import { CashFlowObservationsRequest, CurrencyRequest, Domicile, EfficientFrontierV3Response, FinancialPlanningV3Request, InflationAdjustmentContract, InvestmentAccountAllocation, InvestmentAccountTaxationType, InvestmentAllocationRequest, InvestmentStrategyType, ProductCodeContract, ProductCodeType, RiskLevel } from "../../api_client";
import { callFinancialPlanning, curatedClusterIsins, getCluster, getComplementingFunds, getDefaultInvestmentGoalPlanningResult, getEfficientFrontier, getStressScenarios, InvestmentGoalPlanningResult, optimizePortfolio } from "../../api_client/tsClient";
import { ITimeSeriesPoint } from "../../historicalApiClient";
import { staticFundData } from "../../historicalApiClient/funds";
import { useDebounce } from "../../hooks/useDebounce";
import useHistoricalApi from "../../hooks/useHistoricalApi";
import SliderInput from "../../MultiGoal/SliderInput";
import { PercentFormatCustom } from "../../NumberFormatCustom";
import { PortfolioStressScenarioResponse } from "../../portfolioAnalysisApiClient";
import { SettingsContext } from "../../settings/SettingsContext";
import { useAppColors } from "../../theming/useAppColors";
import EfficientFrontierChart from "../../visualization/EfficientFrontierChart";
import PercentileFanChart from "../../visualization/PercentileFanChart";
import { ProductCodeVM, WeightCalculationType } from "../Portfolio";
import { addAllocationAsync, calculateWeights, calculateWeightsAsync } from "./InvestmentAllocationsView";
import { PortfolioStats } from "./PortfolioStats";
import { FundInfo } from "./ProductView";

const staticData = staticFundData.funds[0];

const fundInfo = {
    isin: staticData.isin,
    rank: 1,
    name: staticData.name,
    nav: 123,
    dayReturn: 3.4,
    yearReturn: 12.7,
    regions: staticData?.geographical_regions,
    fee: staticData?.yearly_fee_pp,
    assetClasses: staticData?.asset_classes,
    holdings: staticData?.holdings.slice(0, 10),
    industries: staticData?.sectors,
    rating: staticData?.morningstar_rating,
    riskLevel: staticData?.risk,
    sustainabilityRating: 3,
    description: staticData?.description,
    currency: staticData?.currecy
} as FundInfo;



export function InvestmentPortfolioView(props: {
    investmentAllocations: InvestmentAccountAllocation[] | InvestmentAllocationRequest[];
    setInvestmentAllocations: (
        newValue: InvestmentAccountAllocation[] | InvestmentAllocationRequest[],
        strategySettings: {
            weightCalculationType: WeightCalculationType;
            optimizationHorizonInYears: number;
            optimizationRiskLevel: RiskLevel;
            investmentStrategy: InvestmentStrategyType;
        }) => void;
    strategySettings: {
        weightCalculationType: WeightCalculationType;
        optimizationHorizonInYears: number;
        optimizationRiskLevel: RiskLevel;
        investmentStrategy: InvestmentStrategyType;
    };
    referenceInvestmentAllocations: InvestmentAccountAllocation[] | InvestmentAllocationRequest[];
}) {
    const theme = useTheme();
    const { investmentAllocations, setInvestmentAllocations, referenceInvestmentAllocations } = props;

    const debouncedInvestmentAllocations: InvestmentAccountAllocation[] | InvestmentAllocationRequest[] = useDebounce(investmentAllocations, 800);

    const colors = useAppColors();
    const settings = useContext(SettingsContext);
    const { priceIndex } = useHistoricalApi();


    //inspo
    const [selectedPanel, setSelectedPanel] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedPanel(newValue);
    };
    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [selectedAllocation, setSelectedAllocation] = useState(undefined as ProductCodeVM);
    const [efficientFrontier, setEfficientFrontier] = useState(undefined as EfficientFrontierV3Response);
    const [investmentAllocationsStatistics, setInvestmentAllocationsStatistics] = useState(undefined as { horizon: number, cagr: number, volatility: number }[]);
    const [portfolioStressScenarios, setPortfolioStressScenarios] = useState(undefined as PortfolioStressScenarioResponse[]);


    const hasInvalidWeight = Math.abs(1 - (investmentAllocations as any)?.reduce((a: any, b: any) => a + b.strategyWeight, 0)) > 0.01;

    const errorText = hasInvalidWeight ? 'Total weight of allocations must be 100%. ' : '';

    const [portfolioInfo, setPortfolioInfo] = useState({
        isin: staticData.isin,
        rank: 1,
        name: staticData.name,
        nav: 123,
        dayReturn: 3.4,
        yearReturn: 12.7,
        regions: staticData?.geographical_regions,
        fee: staticData?.yearly_fee_pp,
        assetClasses: staticData?.asset_classes,
        holdings: staticData?.holdings.slice(0, 10),
        industries: staticData?.sectors,
        rating: staticData?.morningstar_rating,
        riskLevel: staticData?.risk,
        sustainabilityRating: 3,
        description: staticData?.description,
        currency: staticData?.currecy
    } as FundInfo);

    const [referencePortfolioInfo, setReferencePortfolioInfo] = useState({
        isin: staticData.isin,
        rank: 1,
        name: staticData.name,
        nav: 123,
        dayReturn: 3.4,
        yearReturn: 12.7,
        regions: staticData?.geographical_regions,
        fee: staticData?.yearly_fee_pp,
        assetClasses: staticData?.asset_classes,
        holdings: staticData?.holdings.slice(0, 10),
        industries: staticData?.sectors,
        rating: staticData?.morningstar_rating,
        riskLevel: staticData?.risk,
        sustainabilityRating: 3,
        description: staticData?.description,
        currency: staticData?.currecy
    } as FundInfo);

    const [cluster, setCluster] = useState(undefined as FundInfo[]);
    const [complementingFunds, setComplementingFunds] = useState([fundInfo] as FundInfo[]);
    const [smallCapFunds, setSmallCapFunds] = useState([fundInfo] as FundInfo[]);
    const [globalFunds, setGlobalFunds] = useState([fundInfo] as FundInfo[]);
    const [techFunds, setTechFunds] = useState([fundInfo] as FundInfo[]);


    const [horizon, setHorizon] = useState(10);

    const [resultState, setResultState] = useState<{ forecast: InvestmentGoalPlanningResult | undefined, historicalTimeSeries: ITimeSeriesPoint[] }>(
        {
            forecast: getDefaultInvestmentGoalPlanningResult(),
            historicalTimeSeries: undefined
        });

    const [referenceResultState, setReferenceResultState] = useState<{ forecast: InvestmentGoalPlanningResult | undefined, historicalTimeSeries: ITimeSeriesPoint[] }>(
        {
            forecast: getDefaultInvestmentGoalPlanningResult(),
            historicalTimeSeries: undefined
        });

    const [strategySettingsOpen, setStrategySettingsOpen] = useState(false);

    useEffect(() => {
        if (settings.mappedFunds === undefined || settings.mappedFunds.length === 0)
            return;

        getCluster(curatedClusterIsins.sweSmallCap)
            .then(async result => {
                if (result === undefined) return;
                const cluster = result
                    .splice(0, 5)
                    .map(async c => {
                        const series = await priceIndex(c.isin);
                        return getFundInfo(series, c.isin, c.rank, settings.mappedFunds);
                    });
                Promise.all(cluster).then(c => {
                    setSmallCapFunds(c.filter(f => f.name))
                });
            }
            );

        getCluster(curatedClusterIsins.global)
            .then(async result => {
                if (result === undefined) return;
                const cluster = result
                    .splice(0, 5)
                    .map(async c => {
                        const series = await priceIndex(c.isin);
                        return getFundInfo(series, c.isin, c.rank, settings.mappedFunds);
                    });

                Promise.all(cluster).then(c => {
                    setGlobalFunds(c.filter(f => f.name))
                });
            }
            );

        getCluster(curatedClusterIsins.tech)
            .then(async result => {
                if (result === undefined) return;
                const cluster = result
                    .splice(0, 5)
                    .map(async c => {
                        const series = await priceIndex(c.isin);
                        return getFundInfo(series, c.isin, c.rank, settings.mappedFunds);
                    });

                Promise.all(cluster).then(c => {
                    setTechFunds(c.filter(f => f.name))
                });
            }
            );


    }, [settings.mappedFunds]);

    useEffect(() => {

        if (settings.currentScenarioSet === null || settings.currentScenarioSet === undefined || debouncedInvestmentAllocations === undefined || debouncedInvestmentAllocations.length === 0)
            return;

        getPortfolioResults(debouncedInvestmentAllocations, settings.currentScenarioSet.id, setResultState, getFundInfo, setPortfolioInfo, settings.mappedFunds, priceIndex);

        getStressScenarios(settings.currentScenarioSet.id, debouncedInvestmentAllocations.map(a => ({ code: a.productCode.code, codeType: a.productCode.codeType as any, name: a.productCode.code, weight: a.strategyWeight })))
            .then(r => setPortfolioStressScenarios(r as any))
            .catch(r => console.log(r))

        // const isOnlyIsins = debouncedInvestmentAllocations.filter(a => a.productCode?.codeType === ProductCodeType.Custom).length === 0;
        getComplementingFunds(debouncedInvestmentAllocations.map(a => a.productCode?.code))
            .then(async result => {
                const cluster = result
                    .map(async c => {
                        const series = await priceIndex(c.isin);
                        return getFundInfo(series, c.isin, c.rank, settings.mappedFunds);
                    });

                Promise.all(cluster).then(c => {
                    setComplementingFunds(c.filter(f => f.name))
                });
            }
            );
        if (selectedAllocation !== undefined) {
            getCluster(selectedAllocation.code)
                .then(async result => {
                    const cluster = result
                        .filter(c => c.isin !== selectedAllocation.code)
                        .slice(0, 5)
                        .map(async c => {
                            const series = await priceIndex(c.isin);
                            return getFundInfo(series, c.isin, c.rank, settings.mappedFunds);
                        });

                    Promise.all(cluster).then(c => {
                        setCluster(c)
                    });
                }
                )
                .catch(e => { });
        }

    }, [settings.currentScenarioSet, selectedAllocation, settings.currentScenarioSet.id, settings.mappedFunds, debouncedInvestmentAllocations])

    useEffect(() => {

        if (settings.currentScenarioSet === null || settings.currentScenarioSet === undefined || debouncedInvestmentAllocations === undefined || debouncedInvestmentAllocations.length === 0)
            return;

        const efficientFrontierHorizon = 5;
        let frontierUniverse = settings.mappedFunds?.map(f => f.code).includes('Sweden/VeryLowRiskPortfolio') && !debouncedInvestmentAllocations.map(f => f.productCode.code).includes('Sweden/VeryLowRiskPortfolio')
            ? [...debouncedInvestmentAllocations,
            {
                productCode: { codeType: ProductCodeType.Custom, code: "Sweden/VeryLowRiskPortfolio" },
                strategyWeight: 0,
                annualFee: 0,
                currentValue: 0
            } as InvestmentAllocationRequest]
            : debouncedInvestmentAllocations;

        frontierUniverse = settings.mappedFunds?.map(f => f.code).includes('Sweden/VeryHighRiskPortfolio') && !frontierUniverse.map(f => f.productCode.code).includes('Sweden/VeryHighRiskPortfolio')
            ? [...frontierUniverse,
            {
                productCode: { codeType: ProductCodeType.Custom, code: "Sweden/VeryHighRiskPortfolio" },
                strategyWeight: 0,
                annualFee: 0,
                currentValue: 0
            } as InvestmentAllocationRequest]
            : frontierUniverse;
        getEfficientFrontier(frontierUniverse, efficientFrontierHorizon, settings.currentScenarioSet.id).then(r => setEfficientFrontier(r));
        const optimizations = [1, 3, 5, 10].map(async h => ({ horizon: h, result: await optimizePortfolio(debouncedInvestmentAllocations, h, RiskLevel.MediumLowRisk, settings.currentScenarioSet.id) }));
        Promise.all(optimizations).then(r => {
            const results = r.map(o => ({ horizon: o.horizon, cagr: o.result.statisticsCurrent?.cagr, volatility: o.result.statisticsCurrent?.volatility }));

            setInvestmentAllocationsStatistics(results);
        });


    }, [settings.currentScenarioSet, settings.currentScenarioSet.id, settings.mappedFunds, debouncedInvestmentAllocations])

    useEffect(() => {
        if (settings.currentScenarioSet?.id === undefined || referenceInvestmentAllocations === undefined || referenceInvestmentAllocations.length === 0)
            return;
        getPortfolioResults(referenceInvestmentAllocations, settings.currentScenarioSet.id, setReferenceResultState, getFundInfo, setReferencePortfolioInfo, settings.mappedFunds, priceIndex);
    }, [settings.currentScenarioSet.id, referenceInvestmentAllocations, settings.mappedFunds])

    const marks = [
        {
            value: 0,
            label: 'No risk',
            riskLevel: RiskLevel.NoRisk
        },
        {
            value: 1,
            label: 'Low',
            riskLevel: RiskLevel.LowRisk
        },
        {
            value: 2,
            label: 'Low-medium',
            riskLevel: RiskLevel.MediumLowRisk
        },
        {
            value: 3,
            label: 'Medium',
            riskLevel: RiskLevel.MediumHighRisk
        },
        {
            value: 4,
            label: 'Medium-high',
            riskLevel: RiskLevel.HighRisk
        },
        {
            value: 5,
            label: 'High',
            riskLevel: RiskLevel.VeryHighRisk
        },
    ];

    return (
        <Grid container spacing={2} alignItems='stretch'>
            <Grid item sm={8} xs={12}>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        {portfolioInfo !== undefined ? portfolioHeader(portfolioInfo, referencePortfolioInfo, resultState.forecast, referenceResultState.forecast) : <></>}
                        <PercentileFanChart
                            leftPadding={10}
                            valueTitle="Value"
                            referenceTitle="Original portfolio"
                            height={theme.spacing(36)}
                            historicalTimeSeries={resultState?.historicalTimeSeries ? resultState?.historicalTimeSeries : []}
                            historicalReferenceTimeSeries={referenceResultState?.historicalTimeSeries}
                            targetAmount={0}
                            horizon={horizon}
                            hideHorizon={true}
                            currentAge={0}
                            portfolioDevelopmentTimeSeries={resultState?.forecast?.financialPlanningResponseModel?.percentileForecast}
                            referencePortfolioDevelopmentTimeSeries={referenceResultState?.forecast?.financialPlanningResponseModel?.percentileForecast}
                            filled={true} />


                    </Grid>
                    <Grid item xs={12}>
                        <Paper variant='outlined' style={{ padding: theme.spacing(1) }}>
                            <Grid container spacing={2} >
                                <Grid item sm={6} xs={12}>
                                    <div style={{ paddingTop: theme.spacing(1), paddingLeft: theme.spacing(1) }}>
                                        <Collapse in={!strategySettingsOpen} timeout="auto" unmountOnExit>
                                            <Grid container>
                                                <Grid item xs>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <Typography color='text.secondary' variant="caption">Strategy</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography color='text.secondary' variant="caption">Weights</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography color='text.primary' variant="caption">{props.strategySettings.investmentStrategy === InvestmentStrategyType.BuyAndHold ? 'Buy and hold' : 'Rebalance to plan'}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography color='text.primary' variant="caption">{mapWeightCalculationType(props.strategySettings.weightCalculationType)}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs='auto'>
                                                    <IconButton style={{ color: theme.palette.text.secondary, marginLeft: 3, marginTop: 8 }} size="small" onClick={() => setStrategySettingsOpen(s => !s)}>
                                                        {strategySettingsOpen ? <Done fontSize="small" /> : <Edit fontSize="small" />}
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Collapse>
                                        <Collapse in={strategySettingsOpen} timeout="auto" unmountOnExit>
                                            <Grid container>
                                                <Grid xs>
                                                    <FormControl fullWidth={true} size="small">
                                                        <InputLabel id="strategy-select">Strategy</InputLabel>
                                                        <Select label="Strategy"
                                                            labelId="strategy-select"
                                                            fullWidth={true} value={props.strategySettings.investmentStrategy} onChange={e => {
                                                                props.setInvestmentAllocations(investmentAllocations, { ...props.strategySettings, investmentStrategy: e.target.value as InvestmentStrategyType });
                                                            }}>
                                                            <MenuItem value={InvestmentStrategyType.BuyAndHold}>Buy and hold</MenuItem>
                                                            <MenuItem value={InvestmentStrategyType.RebalanceToPlan}>Rebalance to plan</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid xs='auto'>
                                                    <IconButton style={{ color: theme.palette.text.secondary, marginLeft: 3, marginTop: 3 }} size="small" onClick={() => setStrategySettingsOpen(s => !s)}>
                                                        {strategySettingsOpen ? <Done fontSize="small" /> : <Edit fontSize="small" />}
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                            <div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
                                                <Typography color='text.secondary' variant="caption">{props.strategySettings.investmentStrategy === InvestmentStrategyType.BuyAndHold ? 'When funds are added or removed, the simulation will buy and sell holdings specified by the weights, but will otherwise leave holdings unchanged.' : 'The simulation will continuously buy and sell holdings to maintain the specified weights in the account.'}</Typography>
                                            </div>
                                            <div style={{ paddingTop: theme.spacing(1) }}></div>
                                            <FormControl fullWidth={true} size="small">
                                                <InputLabel id="Weights-select">Weights</InputLabel>

                                                <Select label="Weights"
                                                    labelId="Weights-select"
                                                    fullWidth={true}
                                                    value={props.strategySettings.weightCalculationType}
                                                    onChange={e => {
                                                        props.setInvestmentAllocations(calculateWeights(e.target.value as WeightCalculationType, investmentAllocations), { ...props.strategySettings, weightCalculationType: e.target.value as WeightCalculationType });
                                                    }}>
                                                    <MenuItem key={WeightCalculationType.DeriveWeightsFromCurrentValue} value={WeightCalculationType.DeriveWeightsFromCurrentValue}>Use current value as weights</MenuItem>
                                                    <MenuItem key={WeightCalculationType.Optimized} value={WeightCalculationType.Optimized}>OutRank optimized</MenuItem>
                                                    <MenuItem key={WeightCalculationType.NoCalculation} value={WeightCalculationType.NoCalculation}>Specify weights</MenuItem>
                                                </Select>

                                            </FormControl>
                                            <div style={{ paddingTop: theme.spacing(2) }}></div>
                                            {props.strategySettings.weightCalculationType === WeightCalculationType.Optimized ?

                                                <div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
                                                    <Typography id="risklevel">
                                                        Risk Level
                                                    </Typography>
                                                    <Slider
                                                        min={0}
                                                        max={5}
                                                        value={marks.find(m => m.riskLevel === props.strategySettings.optimizationRiskLevel).value}
                                                        onChange={(e, v) => {
                                                            props.setInvestmentAllocations(investmentAllocations, { ...props.strategySettings, optimizationRiskLevel: marks[Number(v)].riskLevel });
                                                        }}
                                                        marks={marks}
                                                        aria-labelledby="risklevel" />
                                                    <SliderInput value={props.strategySettings.optimizationHorizonInYears}
                                                        onChange={(newValue: any) => {
                                                            props.setInvestmentAllocations(investmentAllocations, { ...props.strategySettings, optimizationHorizonInYears: newValue });
                                                        }}
                                                        minValue={1}
                                                        maxValue={40} title={'Horizon'} />



                                                </div>

                                                : <></>}
                                        </Collapse>
                                        <div style={{ marginTop: theme.spacing(1) }}>
                                            <Grid key='header' container>
                                                <Grid item xs zeroMinWidth style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center"
                                                }}>
                                                    <Typography variant='caption' style={{ textTransform: "uppercase" }} color='text.secondary'>Fund</Typography>
                                                </Grid>
                                                <Grid item xs='auto'>
                                                    <div style={{ width: theme.spacing(10) }}>
                                                        <Typography variant='caption' style={{ textTransform: "uppercase" }} color='text.secondary'>Weight</Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item xs='auto'>
                                                    <Tooltip title={errorText}>
                                                        <Badge color='warning' badgeContent={errorText === '' ? undefined : '!'}>
                                                            <IconButton
                                                                style={{ visibility: "hidden", color: theme.palette.text.secondary, marginLeft: 3, height: 1 }}
                                                                size='small'>
                                                                <Delete />
                                                            </IconButton>
                                                        </Badge>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        {investmentAllocations.map((a, i) => {
                                            return (
                                                <div style={selectedAllocation?.code === a?.productCode?.code ? { background: theme.palette.action.selected, marginLeft: theme.spacing(-1), borderRadius: 2 } : { marginLeft: theme.spacing(-1), borderRadius: 2 }} key={a?.productCode?.code} onClick={() => setSelectedAllocation(a?.productCode as ProductCodeVM)}>
                                                    <div style={{ padding: theme.spacing(1) }}>
                                                        <Grid key={a?.productCode?.code} container>
                                                            <Grid item xs zeroMinWidth style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: "center"
                                                            }}>
                                                                <Typography color={props.strategySettings.weightCalculationType === WeightCalculationType.DeriveWeightsFromCurrentValue ? 'text.secondary' : 'text.primary'} style={{ overflowWrap: 'break-word', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{(a.productCode as ProductCodeVM)?.name}</Typography>
                                                            </Grid>
                                                            <Grid item xs='auto'>
                                                                <TextField
                                                                    disabled={props.strategySettings.weightCalculationType !== WeightCalculationType.NoCalculation}
                                                                    sx={{ width: theme.spacing(10) }}
                                                                    error={a.strategyWeight < 0}
                                                                    InputLabelProps={{ shrink: true }}
                                                                    InputProps={{ inputComponent: PercentFormatCustom }}
                                                                    margin='none'
                                                                    size="small"
                                                                    style={{ paddingLeft: theme.spacing(1) }}
                                                                    onChange={(e) => setInvestmentAllocations(investmentAllocations.map((ia, ii) => i === ii ? { ...a, strategyWeight: Number(e.target.value) / 100 } : ia), props.strategySettings)}
                                                                    value={a.strategyWeight * 100} />
                                                            </Grid>
                                                            <Grid item xs='auto'>
                                                                <IconButton
                                                                    style={{ color: theme.palette.text.secondary, marginTop: 3, marginLeft: 3 }}
                                                                    size='small'
                                                                    onClick={() => {
                                                                        let newAllocations = investmentAllocations.filter((ia, ii) => i !== ii);
                                                                        calculateWeightsAsync(props.strategySettings.weightCalculationType, newAllocations, settings.currentScenarioSet.id, props.strategySettings)
                                                                            .then(r => setInvestmentAllocations(r, props.strategySettings));

                                                                    }}>
                                                                    <Delete />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </div>
                                            );
                                        }
                                        )}
                                    </div>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    {/* <Paper variant='outlined'
                            sx={{ height: { sm: theme.spacing(24) }, }}
                            style={{ padding: theme.spacing(1), marginTop: theme.spacing(1) }}> */}
                                    <div style={{ minHeight: theme.spacing(24), paddingTop: theme.spacing(1), paddingLeft: theme.spacing(1) }}>
                                        <Typography variant='caption' style={{ textTransform: "uppercase" }} color='text.secondary'>Similar funds</Typography>
                                        {cluster !== undefined && selectedAllocation !== undefined && investmentAllocations.map(a => a.productCode.code).includes(selectedAllocation.code) ? compactInfo(cluster, false, true) : <><br /><Typography variant='caption'>Select a fund to see similar funds.</Typography></>}
                                    </div>
                                    {/* </Paper> */}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper variant='outlined' style={{ padding: theme.spacing(1) }}>
                            <PortfolioStats statistics={investmentAllocationsStatistics} />
                        </Paper>
                        <Paper variant='outlined' style={{ marginTop: theme.spacing(1), padding: theme.spacing(1) }}>
                            {portfolioStressScenarioTable(portfolioStressScenarios)}
                        </Paper>
                    </Grid>
                </Grid>
            </Grid >
            <Grid item sm={4} xs={12} style={{ display: "flex", flexDirection: "column" }}>
                <Paper variant='outlined' style={{ marginTop: theme.spacing(1), paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) }}>
                    <Typography variant='caption' style={{ textTransform: "uppercase" }} color='text.secondary'>Efficient frontier</Typography>
                    <EfficientFrontierChart
                        currentPortfolio={investmentAllocationsStatistics ? ({ cagr: investmentAllocationsStatistics.find(s => s.horizon === 5).cagr, volatility: investmentAllocationsStatistics.find(s => s.horizon === 5).volatility }) : undefined}
                        efficientFrontier={efficientFrontier}
                        showXAxis={true}
                        height={theme.spacing(34)} />
                </Paper>
                <Paper variant='outlined' style={{ marginTop: theme.spacing(1), padding: theme.spacing(1) }}>
                    <Grid container style={{ paddingTop: 2 }}>
                        <Grid item xs={12}>
                            <Typography variant='caption' style={{ textTransform: "uppercase" }} color='text.secondary'>Asset classes</Typography>
                        </Grid>
                        {portfolioInfo?.assetClasses?.map(a => <Fragment key={a.name}><Grid item xs>
                            <Typography variant='caption'>{a.name}</Typography>
                        </Grid>
                            <Grid item xs='auto'>
                                <Typography>{a.percentage}%</Typography>
                            </Grid>
                            <Grid item xs={12}>
                            </Grid></Fragment>)}
                    </Grid>
                </Paper>
                <LookThroughView portfolioInfo={portfolioInfo} />
                <Paper variant='outlined'
                    sx={{ height: { sm: theme.spacing(24) }, }}
                    style={{ padding: theme.spacing(1), marginTop: theme.spacing(1) }}>
                    <Typography variant='caption' style={{ textTransform: "uppercase" }} color='text.secondary'>Complementing funds</Typography>
                    {complementingFunds !== undefined ? compactInfo(complementingFunds, false) : <></>}
                </Paper>
                <Paper variant='outlined'
                    sx={{ height: { sm: theme.spacing(30) }, }}
                    style={{ padding: theme.spacing(1), marginTop: theme.spacing(1) }}>
                    <Typography variant='caption' style={{ textTransform: "uppercase" }} color='text.secondary'>Inspiration</Typography>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs sx={{ minHeight: 'auto', height: 'auto' }}
                            variant="fullWidth" value={selectedPanel} onChange={handleChange} aria-label="inpspolabel">
                            <Tab sx={{ minHeight: 'auto', height: 'auto' }} label={<Typography variant='caption'>Small cap</Typography>} {...a11yProps(0)} />
                            <Tab sx={{ minHeight: 'auto', height: 'auto' }} label={<Typography variant='caption'>Global</Typography>} {...a11yProps(1)} />
                            <Tab sx={{ minHeight: 'auto', height: 'auto' }} label={<Typography variant='caption'>Tech</Typography>} {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={selectedPanel} index={0}>
                        {smallCapFunds !== undefined ? compactInfo(smallCapFunds, false) : <></>}
                    </TabPanel>
                    <TabPanel value={selectedPanel} index={1}>
                        {globalFunds !== undefined ? compactInfo(globalFunds, false) : <></>}
                    </TabPanel>
                    <TabPanel value={selectedPanel} index={2}>
                        {techFunds !== undefined ? compactInfo(techFunds, false) : <></>}
                    </TabPanel>
                </Paper>
            </Grid>
        </Grid >
    );

    function portfolioStressScenarioTable(portfolioStressScenarios: PortfolioStressScenarioResponse[]) {
        const itemWidth = 7 * 4;

        return <Grid container>
            <Grid item xs>
                <Typography variant='caption' color='text.secondary'>Historical event</Typography><br />
            </Grid>
            <Grid item xs='auto'>
                <div style={{ width: theme.spacing(itemWidth) }}>
                    <Typography variant='caption' color='text.secondary'>Max drawdowm</Typography><br />
                </div>
            </Grid>
            <Grid item xs={12}></Grid>
            {portfolioStressScenarios ? portfolioStressScenarios.map((s: PortfolioStressScenarioResponse) => <><Grid item xs>
                <Typography variant='caption'>{s.scenarioName}</Typography><br />
            </Grid>
                <Grid item xs='auto'>
                    <div style={{ width: theme.spacing(itemWidth) }}>
                        <Typography variant='caption'>{round((1 - s.value) * 100)}%</Typography><br />
                    </div>
                </Grid>
                <Grid item xs={12}></Grid>

            </>)
                : <></>}
        </Grid>;
    }

    function portfolioHeader(portfolioInfo: FundInfo, referencePortfolioInfo: FundInfo, forecast: InvestmentGoalPlanningResult, referenceForecast: InvestmentGoalPlanningResult) {

        const round = (val: number) => Math.round((val + Number.EPSILON) * 10) / 10;
        const color = (val: number) => round(val) === 0 ? theme.palette.text.secondary : val > 0 ? colors.spendingItems.investmentAmount : colors.spendingItems.interestPayment;

        const itemWidth = 12;

        return portfolioInfo !== undefined ?
            <Grid container style={{ paddingLeft: theme.spacing(1) }}>
                <Grid item xs={12} md='auto'>
                    <Grid container style={{ width: theme.spacing(itemWidth * 2) }}>
                        <Grid item xs='auto'>
                            <div style={{ width: theme.spacing(itemWidth) }}>
                                <Typography variant='caption' color='text.secondary'>1Y %</Typography><br />
                            </div>
                        </Grid>
                        <Grid item xs='auto'>
                            <div style={{ width: theme.spacing(itemWidth) }}>
                                <Typography variant='caption' color='text.secondary'>Fee %</Typography><br />
                            </div>
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs='auto'>
                            <div style={{ width: theme.spacing(itemWidth) }}>
                                <span style={{ color: color(portfolioInfo.yearReturn ? portfolioInfo.yearReturn : 0) }}>{portfolioInfo.yearReturn ? round(portfolioInfo.yearReturn) : '-'}% </span> <span style={{ color: theme.palette.text.secondary, fontSize: 12 }}> [{referencePortfolioInfo?.yearReturn ? round(referencePortfolioInfo.yearReturn) : '-'}%]</span>
                            </div>
                        </Grid>
                        <Grid item xs='auto'>
                            <div style={{ width: theme.spacing(itemWidth) }}>
                                {portfolioInfo?.fee !== undefined ? portfolioInfo?.fee + '%' : '--'} <span style={{ color: theme.palette.text.secondary, fontSize: 12 }}> [{referencePortfolioInfo?.fee !== undefined ? referencePortfolioInfo.fee + '%' : '--'}]</span>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md>
                    <Grid container>
                        <Grid item xs={'auto'}>
                            <div style={{ width: theme.spacing(itemWidth) }}>
                                <Typography variant='caption' color='text.secondary'>Forecast 5Y <SignalCellularAlt style={{ fontSize: 14, verticalAlign: -2 }}></SignalCellularAlt></Typography><br />
                            </div>
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs='auto'>
                            <div>
                                <span style={{ color: theme.palette.text.secondary, fontSize: 12 }}>10%:</span> {forecast.outcomesAtHorizon.bad} <span style={{ color: theme.palette.text.secondary, fontSize: 12 }}>[{forecast.outcomesAtHorizon.bad - referenceForecast.outcomesAtHorizon.bad > 0 ? '+' : ''}{round(forecast.outcomesAtHorizon.bad - referenceForecast.outcomesAtHorizon.bad)}]</span>,
                                <span style={{ color: theme.palette.text.secondary, fontSize: 12 }}> 50%:</span> {forecast.outcomesAtHorizon.median} <span style={{ color: theme.palette.text.secondary, fontSize: 12 }}>[{forecast.outcomesAtHorizon.median - referenceForecast.outcomesAtHorizon.median > 0 ? '+' : ''}{round(forecast.outcomesAtHorizon.median - referenceForecast.outcomesAtHorizon.median)}]</span>,
                                <span style={{ color: theme.palette.text.secondary, fontSize: 12 }}> 90%:</span> {forecast.outcomesAtHorizon.good} <span style={{ color: theme.palette.text.secondary, fontSize: 12 }}>[{forecast.outcomesAtHorizon.good - referenceForecast.outcomesAtHorizon.good > 0 ? '+' : ''}{round(forecast.outcomesAtHorizon.good - referenceForecast.outcomesAtHorizon.good)}]</span>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> : <></>;
    }



    function compactInfo(fundInfos: FundInfo[], hideNames: boolean, isReplace: boolean = false) {

        const round = (val: number) => Math.round((val + Number.EPSILON) * 100) / 100;
        const color = (val: number) => round(val) === 0 ? theme.palette.text.secondary : val > 0 ? colors.spendingItems.investmentAmount : colors.spendingItems.interestPayment;

        const itemWidth = 6;

        const style = hideNames ? { width: theme.spacing(28) } : {};

        return fundInfo !== undefined ? <Grid container style={style}>
            {hideNames ? <></> : <Grid item xs>
                <Typography variant='caption' color='text.secondary'>Name</Typography><br />
            </Grid>}
            {/* <Grid item xs='auto'>
                <div style={{ width: theme.spacing(itemWidth) }}>
                    <Typography variant='caption' color='text.secondary'>1D %</Typography><br />
                </div>
            </Grid> */}
            {/* <Grid item xs='auto'>
                <div style={{ width: theme.spacing(itemWidth) }}>
                    <Typography variant='caption' color='text.secondary'>1Y %</Typography><br />
                </div>
            </Grid> */}
            <Grid item xs='auto'>
                <div style={{ width: theme.spacing(itemWidth) }}>
                    <Typography variant='caption' color='text.secondary'>Fee %</Typography><br />
                </div>
            </Grid>
            {/* 
            <Grid item xs='auto'>
                <div style={{ width: theme.spacing(itemWidth) }}>
                    <Typography variant='caption' color='text.secondary'>NAV</Typography><br />
                </div>
            </Grid> */}
            <Grid item xs='auto'>
                <div style={{ width: theme.spacing(3) }}>
                    {/* <Typography variant='caption' color='text.secondary'>NAV</Typography><br /> */}
                </div>
            </Grid>

            <Grid item xs={12}></Grid>
            {fundInfos.map(fundInfo =>
                <>
                    {hideNames ? <></> : <Grid item xs>
                        <Typography variant='caption'>{fundInfo.name}</Typography>
                    </Grid>}
                    {/* <Grid item xs='auto'>
                        <div style={{ width: theme.spacing(itemWidth) }}>
                            <span style={{ color: color(fundInfo.dayReturn) }}>{round(fundInfo.dayReturn)}%</span>
                        </div>
                    </Grid> */}
                    {/* <Grid item xs='auto'>
                        <div style={{ width: theme.spacing(itemWidth) }}>
                            <span style={{ color: color(fundInfo.yearReturn) }}>{round(fundInfo.yearReturn)}%</span>
                        </div>
                    </Grid> */}
                    <Grid item xs='auto'>
                        <div style={{ width: theme.spacing(itemWidth) }}>
                            {fundInfo.fee !== undefined ? fundInfo.fee + '%' : '--'}
                        </div>
                    </Grid>
                    {/* <Grid item xs='auto'>
                        <div style={{ width: theme.spacing(itemWidth) }}>
                            {fundInfo.nav}
                        </div>
                    </Grid> */}
                    <Grid item xs='auto'>
                        <div style={{ width: theme.spacing(3) }}>
                            <Button
                                title={isReplace ? 'Replace selected' : 'Add'}
                                style={{ padding: 0, minWidth: theme.spacing(3) }}
                                onClick={() => addAllocationAsync(
                                    investmentAllocations,
                                    setInvestmentAllocations,
                                    props.strategySettings,
                                    { codeType: ProductCodeType.Isin, code: fundInfo.isin, name: fundInfo.name } as ProductCodeVM,
                                    settings.currentScenarioSet.id,
                                    isReplace ? selectedAllocation : undefined)}>
                                {isReplace ? <Sync fontSize='small' /> : '+'}
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12}></Grid>
                </>
            )}
        </Grid> : <></>;
    }
}

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}
const ExpandMoreButton = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} style={{ padding: 0, marginLeft: 'auto', float: 'right' }} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>{children}</>
            )}
        </div>
    );
}
function LookThroughView(props: { portfolioInfo: FundInfo }) {
    const theme = useTheme();

    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedPanel, setSelectedPanel] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedPanel(newValue);
    };
    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    const portfolioInfo = props.portfolioInfo;

    return <Paper variant='outlined' style={{ marginTop: theme.spacing(1), padding: theme.spacing(1) }}>
        <Collapse collapsedSize={theme.spacing(24)} in={isExpanded} timeout="auto">
            <div>
                <Typography variant='caption' style={{ textTransform: "uppercase" }} color='text.secondary'>Exposure</Typography>
                <ExpandMoreButton
                    expand={isExpanded}
                    onClick={() => setIsExpanded(!isExpanded)}
                    aria-expanded={isExpanded}
                    aria-label="show more">
                    <ExpandMore fontSize="small" />
                </ExpandMoreButton>
            </div>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs sx={{ minHeight: 'auto', height: 'auto' }}
                    variant="fullWidth" value={selectedPanel} onChange={handleChange} aria-label="basic tabs example">
                    <Tab sx={{ minHeight: 'auto', height: 'auto' }} label={<Typography variant='caption'>Regions</Typography>} {...a11yProps(0)} />
                    <Tab sx={{ minHeight: 'auto', height: 'auto' }} label={<Typography variant='caption'>Industries</Typography>} {...a11yProps(1)} />
                    <Tab sx={{ minHeight: 'auto', height: 'auto' }} label={<Typography variant='caption'>Holdings</Typography>} {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={selectedPanel} index={0}>
                <Grid container>
                    {portfolioInfo?.regions?.map(r => <><Grid item xs>
                        <Typography variant='caption'>{r.name}</Typography>
                    </Grid>
                        <Grid item xs='auto'>
                            <Typography>{r.percentage}%</Typography>
                        </Grid>
                        <Grid item xs={12}>
                        </Grid></>)}

                </Grid>
            </TabPanel>
            <TabPanel value={selectedPanel} index={1}>
                <Grid container>
                    {portfolioInfo?.industries?.map(r => <><Grid item xs>
                        <Typography variant='caption'>{r.name}</Typography>
                    </Grid>
                        <Grid item xs='auto'>
                            <Typography>{r.percentage}%</Typography>
                        </Grid>
                        <Grid item xs={12}>
                        </Grid></>)}
                </Grid>

            </TabPanel>
            <TabPanel value={selectedPanel} index={2}>
                <Grid container>

                    {portfolioInfo?.holdings?.map(r => <><Grid item xs>
                        <Typography variant='caption'>{r.name}</Typography>
                    </Grid>
                        <Grid item xs='auto'>
                            <Typography>{r.percentage}%</Typography>
                        </Grid>
                        <Grid item xs={12}>
                        </Grid></>)}
                </Grid>

            </TabPanel>
        </Collapse>
    </Paper >;
}

function getPortfolioResults(
    investmentAllocations: InvestmentAccountAllocation[] | InvestmentAllocationRequest[],
    currentScenarioSetId: string,
    setResultState: React.Dispatch<React.SetStateAction<{
        forecast: InvestmentGoalPlanningResult | undefined;
        historicalTimeSeries: ITimeSeriesPoint[];
    }>>,
    getFundInfo: (series: ITimeSeriesPoint[], isin: string, rank: number, mappedFunds: ProductCodeContract[]) => FundInfo,
    setPortfolioInfo: Dispatch<SetStateAction<FundInfo>>,
    mappedFunds: ProductCodeContract[],
    priceIndex: any
) {

    const isOnlyIsins = investmentAllocations.filter(a => a.productCode?.codeType === ProductCodeType.Custom).length === 0;
    if (isOnlyIsins) {

        const indexResults = investmentAllocations.map(async (c) => {
            let priceIndexResult = [] as ITimeSeriesPoint[];
            try {
                priceIndexResult = await priceIndex(c.productCode.code);
            } catch (error) {
            }
            return { code: c.productCode.code, weight: c.strategyWeight, result: priceIndexResult };
        });



        Promise.all(indexResults).then(c => {
            // if(c.filter(f => f.result.length > 1).length === 0) return;
            let resultingSeries = [] as ITimeSeriesPoint[];
            try {
                if (getFundInfo !== undefined) {
                    const resultsWithMetadata = c.filter(f => staticFundData.funds.map(s => s.isin).includes(f.code) && f.result.length > 1);
                    const portfolioInfos = resultsWithMetadata.map(r => {
                        const info = getFundInfo(r.result, r.code, 0, mappedFunds);

                        info.assetClasses = info.assetClasses.map(c => ({ ...c, percentage: c.percentage * r.weight }));
                        info.holdings = info.holdings.map(c => ({ ...c, percentage: c.percentage * r.weight }));
                        info.industries = info.industries.map(c => ({ ...c, percentage: c.percentage * r.weight }));
                        info.regions = info.regions.map(c => ({ ...c, percentage: c.percentage * r.weight }));
                        info.fee = info.fee * r.weight;
                        info.yearReturn = (info.yearReturn / 100 + 1) * r.weight;

                        return info;
                    })
                    if (portfolioInfos.length !== 0) {
                        const portfolioInfo = portfolioInfos
                            .reduce((a: FundInfo, b: FundInfo) => {
                                const groupBySum = (values: { name: string, percentage: number }[]) => {
                                    const results = new Map<string, number>();
                                    for (const { name, percentage } of values)
                                        results.set(name, (results.get(name) || 0) + percentage);
                                    return new Array(...results)
                                        .map(a => ({ name: a[0], percentage: Math.round(a[1] * 10) / 10 }))
                                        .sort((a, b) => a.percentage < b.percentage ? 1 : -1);
                                }
                                b.assetClasses = groupBySum(a.assetClasses.concat(b.assetClasses));
                                b.holdings = groupBySum(a.holdings.concat(b.holdings));
                                b.industries = groupBySum(a.industries.concat(b.industries));
                                b.regions = groupBySum(a.regions.concat(b.regions));
                                b.fee = a.fee + b.fee;
                                b.yearReturn = a.yearReturn + b.yearReturn;
                                return b;
                            });
                        portfolioInfo.fee = Math.round((portfolioInfo.fee) * 100) / 100;
                        portfolioInfo.yearReturn = Math.round((portfolioInfo.yearReturn - 1) * 1000) / 10;
                        setPortfolioInfo(portfolioInfo);
                    }
                }
                const shortestSeriesLength = Math.min(...c.map(r => r.result.length));
                const shortestSeries = c.find(r => r.result.length === shortestSeriesLength);
                var resultsAsDicts = c.map(series => {
                    var dict = {} as any;
                    const normalizationFactor = 100 / series.result[series.result.length - 1].value;
                    series.result.forEach((el, index) => {
                        dict[el.pointInTime as any] = el.value * normalizationFactor * series.weight;
                    });
                    return { code: series.code, values: dict };
                });
                resultingSeries = shortestSeries.result
                    .map((p, i) => {
                        var value = 0;
                        var isValid = true;
                        resultsAsDicts.forEach(r => {
                            var v = r.values[p.pointInTime as any];
                            if (v === undefined)
                                isValid = false;

                            else
                                value += v;
                        }
                        );
                        return {
                            value: Math.round(value * 100) / 100,
                            pointInTime: p.pointInTime,
                            isValid: isValid
                        };
                    })
                    .filter(r => r.isValid);
            } catch (e) {
                console.log(e);
            }
            const request = createRequest(investmentAllocations, currentScenarioSetId);

            callFinancialPlanning(5, request)
                .then(async (result2) => {
                    try {

                        setResultState({ forecast: result2 as InvestmentGoalPlanningResult, historicalTimeSeries: resultingSeries });
                    } finally {
                    }
                }).catch(async (e) => {
                });
        }).catch(async (e) => {
        });
    }
    else {
        const request = createRequest(investmentAllocations, currentScenarioSetId);
        callFinancialPlanning(5, request)
            .then(async (result2) => {
                try {
                    setResultState({ forecast: result2 as InvestmentGoalPlanningResult, historicalTimeSeries: undefined });
                    setPortfolioInfo(undefined);
                } finally {
                }
            }).catch(async (e) => {
            });

    }

}

function mapWeightCalculationType(type: WeightCalculationType): string {
    return type === WeightCalculationType.DeriveWeightsFromCurrentValue ? 'Use current value as weights'
        : type === WeightCalculationType.Optimized ? 'OutRank optimized'
            : 'Specify weights';
}

function createRequest(investmentAllocations: InvestmentAccountAllocation[] | InvestmentAllocationRequest[], scenarioSet: string): FinancialPlanningV3Request {
    const monthlyContributionAccountId = 'c43fca7d-ab42-4efc-9b7f-1463a3a5c795';

    const numYears = 40;

    return {
        baseCurrency: CurrencyRequest.SEK,
        simulationSettings: {
            scenarioSetId: scenarioSet,
            percentiles: [0.9, 0.5, 0.1],
            cashFlowObservations: CashFlowObservationsRequest.None,
            inflationAdjustment: InflationAdjustmentContract.NoAdjustment,
            observationTimeSteps: [...Array(numYears + 1).keys()].map((_, i) => i * 12)
        },
        startDate: { year: 2023, month: 5 },
        assetsAndDebts: {
            investmentAccounts: [{
                taxationType: InvestmentAccountTaxationType.NoTax,
                annualAccountFee: 0,
                id: monthlyContributionAccountId,
                strategy: InvestmentStrategyType.RebalanceToPlan,
                investmentAllocations: investmentAllocations.map(a => ({
                    acquisitionValue: a.strategyWeight * 100,
                    annualFee: 0,
                    currentValue: a.strategyWeight * 100,
                    productCode: a.productCode,
                    spreadOverGrowthRate: 0,
                    strategyWeight: a.strategyWeight
                }))
            }]
        },
        cashFlows: {
        },
        taxInformation: {
            sweTax: { municipalityTaxRate: 0.31 },
            taxPaymentAssetsAndDebtsItemId: monthlyContributionAccountId,
        },
        domicile: Domicile.Swe,
        dateOfBirth: { year: 1978, month: 9 },
    };
}

function round(val: number) {
    return Math.round((val + Number.EPSILON) * 10) / 10;
}


const getFundInfo = (series: ITimeSeriesPoint[], isin: string, rank: number, mappedFunds: ProductCodeContract[]) => {
    series.sort((a, b) => a.pointInTime > b.pointInTime ? 1 : -1);
    const lastPoint = series[series.length - 1];
    const previousYearDate = moment(lastPoint.pointInTime).add(-1, 'years').toDate();
    const previousYear = series.find(p => {
        return moment(p.pointInTime).toDate() >= previousYearDate;
    }
    );

    const staticData = staticFundData.funds.find((f: any) => f.isin === isin);

    const mappedFund = (mappedFunds.find(f => f.code === isin) as ProductCodeVM);

    return ({
        isin: isin,
        rank: rank,
        name: mappedFund?.name,
        nav: lastPoint.value,
        navOneYearAgo: previousYear.value,
        dayReturn: ((lastPoint.value / series[series.length - 2].value) - 1) * 100,
        yearReturn: ((lastPoint.value / previousYear.value) - 1) * 100,
        regions: staticData?.geographical_regions,
        fee: staticData?.yearly_fee_pp,
        assetClasses: staticData?.asset_classes,
        holdings: staticData?.holdings.slice(0, 10),
        industries: staticData?.sectors,
        rating: staticData?.morningstar_rating,
        riskLevel: staticData?.risk,
        sustainabilityRating: 3,
        description: staticData?.description,
        currency: staticData?.currecy
    } as FundInfo);
}

