import { Add, OpenInNew, Settings } from "@mui/icons-material";
import { Button, Checkbox, Collapse, Dialog, DialogActions, DialogTitle, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem, TextField, Theme, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AssetsAndDebtsItemGroupRequest, AssetsAndDebtsRequest, CurrencyRequest, Frequency, InvestmentAccountRequest, InvestmentAccountTaxationType, InvestmentStrategyType, MortgageRequest, OccupationalPayoutSchemeType, ProductCodeType, RealEstateRequest, RetirementRateRequest, RiskLevel, SweNationalPensionRequest, SweOccupationalPensionAccountRequest, TransactionAccountRequest, TypeOfRealEstateRequest } from "../../api_client";
import { AccountType, AssetsAndDebtsItemVM, DisplayState, getAccounts, getAccountsWithSetFunction, GroupVM, InvestmentAccountVM, MortgageVM, NationalPensionVM, OccupationalPensionAccountVM, PayoutSchemeVM, RealEstateVM, TransactionAccountVM, WeightCalculationType } from "../Portfolio";
import InvestmentAccountView from "./InvestmentAccountView";
import TransactionAccountView from "./TransactionAccountView";
import { v4 as uuidv4 } from 'uuid';
import RealEstateView from "./RealEstateView";
import MortgageView from "./MortgageView";
import { rallyBlueArray, rallyDarkGreen, rallyDarkGreenArray, rallyGreenArray, rallyOrangeArray, rallyPurple, rallyPurpleArray, rallyYellowArray, useHeaderStyle } from "../../theming/useAppColors";
import { OccupationalPensionAccountView } from "./OccupationalPensionAccountView";
import { NationalPensionView } from "./NationalPensionView";
import { GroupDisplayHeader } from "./GroupDisplayHeader";


export default function AssetsAndDebtsView(props: {
    assetsAndDebts: AssetsAndDebtsRequest,
    setAssetsAndDebts: (assetsAndDebts: AssetsAndDebtsRequest) => void,
    groups: GroupVM[],
    setGroups: (newGroups: GroupVM[]) => void,
    isLoaded: boolean
}) {

    const assetsAndDebts = props.assetsAndDebts ? props.assetsAndDebts : { investmentAccounts: [], transactionAccounts: [], mortgages: [], realEstate: [] } as AssetsAndDebtsRequest;
    const theme = useTheme();
    const headerStyle = useHeaderStyle();

    const { groups } = props;

    const [groupEditorState, setGroupEditorState] = useState(undefined as GroupVM);
    const [groupEditorOpen, setGroupEditorOpen] = useState(false);

    const [anchorAddEl, setAnchorAddEl] = React.useState<null | HTMLElement>(null);
    const addOpen = Boolean(anchorAddEl);
    const handleAddClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorAddEl(event.currentTarget);
    };
    const handleAddClose = () => {
        setAnchorAddEl(null);
    };


    const addGroup = () => {
        const newGroup = {
            isExpanded: false,
            name: '',
            color: getColor(rallyBlueArray, assetsAndDebts.investmentAccounts as any),
            id: uuidv4(),
            assetsAndDebtsItemIds: []
        } as GroupVM;
        props.setGroups(props.groups ? [...props.groups, newGroup] : [newGroup]);
        handleAddClose();
        setGroupEditorState({ ...newGroup, assetsAndDebtsItemIds: [...newGroup.assetsAndDebtsItemIds] });
        setGroupEditorOpen(true);
    };

    const addInvestmentAccount = () => {
        props.setAssetsAndDebts({
            ...assetsAndDebts, investmentAccounts: [...assetsAndDebts.investmentAccounts, {
                displayState: DisplayState.Edit,
                enabled: true,
                name: 'Investment account ' + (assetsAndDebts.investmentAccounts.length + 1),
                id: uuidv4(),
                investmentAllocations: [],
                strategy: InvestmentStrategyType.RebalanceToPlan,
                taxationType: InvestmentAccountTaxationType.SweIsk,
                annualAccountFee: 0.0,
                // deriveWeightsFromCurrentValue: true
                weightCalculationType: WeightCalculationType.DeriveWeightsFromCurrentValue,
                optimizationHorizonInYears: 10,
                optimizationRiskLevel: RiskLevel.MediumHighRisk,
                color: getColor(rallyBlueArray, assetsAndDebts.investmentAccounts as any, 3)
            } as InvestmentAccountVM]
        });
        handleAddClose();
    };

    const accounts = getAccountsWithSetFunction(assetsAndDebts, props.setAssetsAndDebts);
    const accountsInGroups = groups ? groups.map(g => g.assetsAndDebtsItemIds).reduce((a, b) => a.concat(b), []) : [];
    const accountsNotInGroups = accounts !== undefined ? accounts.map(a => a.id).filter(a => !accountsInGroups.includes(a)) : [];

    const addTransactionAccount = () => {
        props.setAssetsAndDebts({
            ...assetsAndDebts, transactionAccounts: [...assetsAndDebts.transactionAccounts, {
                displayState: DisplayState.Edit,
                enabled: true,
                name: 'Account ' + (assetsAndDebts.transactionAccounts.length + 1),
                id: uuidv4(),
                currentValue: 0,
                currency: CurrencyRequest.SEK,
                color: getColor(rallyGreenArray, assetsAndDebts.transactionAccounts as any, 3)
            } as TransactionAccountVM]
        });
        handleAddClose();
    };

    const addRealEstate = () => {
        props.setAssetsAndDebts({
            ...assetsAndDebts, realEstate: [...assetsAndDebts.realEstate, {
                displayState: DisplayState.Edit,
                name: 'Real estate ' + (assetsAndDebts.transactionAccounts.length + 1),
                enabled: true,
                id: uuidv4(),
                currentValue: 0,
                typeOfProperty: TypeOfRealEstateRequest.SwePrivateHome,
                color: getColor(rallyYellowArray, assetsAndDebts.realEstate as RealEstateVM[], 3)
            } as RealEstateVM]
        });
        handleAddClose();
    };

    const addMortgage = () => {
        props.setAssetsAndDebts({
            ...assetsAndDebts, mortgages: [...assetsAndDebts.mortgages, {
                displayState: DisplayState.Edit,
                name: 'Mortgage ' + (assetsAndDebts.mortgages.length + 1),
                enabled: true,
                id: uuidv4(),
                currentValue: 0,
                annualInterestRate: 0,
                fixedRatePeriodInMonths: 0,
                interestRatePaymentAccountId: accounts[0]?.id,
                minimumMonthlyAmortization: 0,
                durationUnit: Frequency.Annual,
                color: getColor(rallyOrangeArray, assetsAndDebts.mortgages as MortgageVM[], 2)
            } as MortgageVM]
        });
        handleAddClose();
    };

    const addOccupationalPensionAccount = () => {
        props.setAssetsAndDebts({
            ...assetsAndDebts,
            pensionAccounts: {
                ...assetsAndDebts.pensionAccounts,
                sweOccupationalPensionAccounts: [...assetsAndDebts.pensionAccounts.sweOccupationalPensionAccounts,
                {
                    displayState: DisplayState.Edit,
                    enabled: true,
                    name: 'Occupational pension ' + (assetsAndDebts.pensionAccounts.sweOccupationalPensionAccounts.length + 1),
                    id: uuidv4(),
                    investmentAllocations: [],
                    strategy: InvestmentStrategyType.RebalanceToPlan,
                    annualAccountFee: 0.0,
                    fees: { fixedAnnualFee: 0, relativeAnnualFee: 0.0 },
                    payoutScheme: { fixedPeriodInMonths: 120, numberOfMonthsUntilPayout: 240, schemeType: OccupationalPayoutSchemeType.FixedPeriod },
                    payoutStartUnit: Frequency.Annual,
                    payoutPeriodUnit: Frequency.Annual,
                    // deriveWeightsFromCurrentValue: true
                    weightCalculationType: WeightCalculationType.DeriveWeightsFromCurrentValue,
                    optimizationHorizonInYears: 10,
                    optimizationRiskLevel: RiskLevel.MediumHighRisk,
                    color: getColor(rallyPurpleArray, assetsAndDebts.pensionAccounts.sweOccupationalPensionAccounts as OccupationalPensionAccountVM[])
                } as OccupationalPensionAccountVM]
            }
        });
        handleAddClose();
    };

    const addNationalPension = () => {
        props.setAssetsAndDebts({
            ...assetsAndDebts,
            pensionAccounts: {
                ...assetsAndDebts.pensionAccounts,
                sweNationalPension:
                    {
                        enabled: true,
                        displayState: DisplayState.Edit,
                        name: 'National pension',
                        id: uuidv4(),
                        incomePensionAccount: {
                            currentValue: 0,
                            id: uuidv4(),
                        },
                        premiumPensionAccount: {
                            id: uuidv4(),
                            investmentAllocations: [],
                            strategy: InvestmentStrategyType.BuyAndHold,
                            annualAccountFee: 0.0
                        },
                        payoutScheme: [{ retirementRate: RetirementRateRequest.Percent100, ageOfRetirement: 66, timeStep: 250 } as PayoutSchemeVM],
                        // deriveWeightsFromCurrentValue: true
                        weightCalculationType: WeightCalculationType.DeriveWeightsFromCurrentValue,
                        optimizationHorizonInYears: 10,
                        optimizationRiskLevel: RiskLevel.MediumHighRisk,
                        color: rallyPurple[200]
                    } as NationalPensionVM
            }
        });
        handleAddClose();
    };

    return (
        <>
            <Grid container>
                <Grid item xs sx={headerStyle}>
                    <Typography style={{ paddingTop: theme.spacing(1), paddingLeft: theme.spacing(1), maxHeight: theme.spacing(5) }} variant='body1'>Asset and debt</Typography>
                    <GroupEditorDialog
                        open={groupEditorOpen}
                        handleClose={() => {
                            props.setGroups(props.groups.map(g => g.id === groupEditorState.id ? groupEditorState : g)) // TODO remove from other groups
                            setGroupEditorOpen(state => !state)
                        }
                        }
                        handleDelete={() => {
                            props.setGroups(props.groups.filter(g => g.id !== groupEditorState.id))
                            setGroupEditorOpen(state => !state)
                        }
                        }
                        group={groupEditorState}
                        setGroup={setGroupEditorState}
                        accounts={accounts} />
                </Grid>
                <Grid item xs='auto' sx={headerStyle}>
                    <Button onClick={handleAddClick} style={{ padding: theme.spacing(1) }} sx={headerStyle}>
                        <Add />add
                    </Button>
                    <Menu
                        id="addportfolio-menu"
                        anchorEl={anchorAddEl}
                        open={addOpen}
                        onClose={handleAddClose}
                    >
                        <MenuItem onClick={addInvestmentAccount}>Investment account</MenuItem>
                        <MenuItem onClick={addTransactionAccount}>Transaction (cash) account</MenuItem>
                        <MenuItem onClick={addRealEstate}>Real estate</MenuItem>
                        <MenuItem onClick={addMortgage}>Mortgage</MenuItem>
                        <MenuItem disabled={assetsAndDebts.pensionAccounts?.sweNationalPension?.incomePensionAccount !== undefined} onClick={addNationalPension}>National pension</MenuItem>
                        <MenuItem onClick={addOccupationalPensionAccount}>Occupational pension</MenuItem>
                        <Divider></Divider>
                        <MenuItem onClick={addGroup}>New group</MenuItem>
                        {/* <MenuItem onClick={addGroup}>Portfolio strategy</MenuItem> */}
                    </Menu>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                {props.groups?.map(g => <>
                    <GroupDisplayHeader name={g.name} currentValue={0}
                        edit={() => {
                            setGroupEditorState({ ...g, assetsAndDebtsItemIds: [...g.assetsAndDebtsItemIds] });
                            setGroupEditorOpen(true)
                        }}
                        setIsExpanded={() => props.setGroups(props.groups.map(gg => g.id === gg.id ? { ...g, isExpanded: !g.isExpanded } : gg))}
                        color={g.color} isExpanded={g.isExpanded}></GroupDisplayHeader>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Collapse in={g.isExpanded} timeout="auto">
                            {renderAccounts(theme, props, assetsAndDebts, accounts, g.assetsAndDebtsItemIds)}
                        </Collapse>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </>)}


            </Grid>
            {renderAccounts(theme, props, assetsAndDebts, accounts, accountsNotInGroups)}
        </>
    )
}

function renderAccounts(
    theme: Theme,
    props: { assetsAndDebts: AssetsAndDebtsRequest; setAssetsAndDebts: (assetsAndDebts: AssetsAndDebtsRequest) => void; },
    assetsAndDebts: AssetsAndDebtsRequest,
    accounts: { name: string; id: string; enabled: boolean; }[],
    idsToRender: string[]) {
    return <Grid container>
        <Grid item xs={12}>
            {assetsAndDebts.transactionAccounts.filter(a => idsToRender.includes(a.id)).map((a, i) => <div key={a.id}><div style={{ padding: theme.spacing(1) }}>
                <TransactionAccountView key={a.id}
                    transactionAccount={a as TransactionAccountVM}
                    setTransactionAccount={(account) => {
                        props.setAssetsAndDebts({ ...assetsAndDebts, transactionAccounts: assetsAndDebts.transactionAccounts.map((aa) => aa.id === account.id ? account : aa) });
                    }}
                    onDelete={() => {
                        props.setAssetsAndDebts({ ...assetsAndDebts, transactionAccounts: assetsAndDebts.transactionAccounts.filter((aa) => aa.id !== a.id) });
                    }} />

            </div>
                <Divider />
            </div>)}
            {assetsAndDebts.investmentAccounts.filter(a => idsToRender.includes(a.id)).map((a, i) => <div key={a.id}>
                <div style={{ padding: theme.spacing(1) }}><InvestmentAccountView
                    investmentAccount={a as InvestmentAccountVM}
                    setInvestmentAccount={(account) => {
                        props.setAssetsAndDebts({ ...assetsAndDebts, investmentAccounts: assetsAndDebts.investmentAccounts.map((aa) => aa.id === account.id ? account : aa) });
                    }}
                    onDelete={() => {
                        props.setAssetsAndDebts({ ...assetsAndDebts, investmentAccounts: assetsAndDebts.investmentAccounts.filter((aa) => aa.id !== a.id) });
                    }} /></div>
                <Divider /></div>
            )}

            {assetsAndDebts.realEstate?.filter(a => idsToRender.includes(a.id)).map((a, i) => <div key={a.id}><div style={{ padding: theme.spacing(1) }}>
                <RealEstateView key={a.id}
                    realEstate={a as RealEstateVM}
                    setRealEstate={(account) => {
                        props.setAssetsAndDebts({ ...assetsAndDebts, realEstate: assetsAndDebts.realEstate.map((aa) => aa.id === account.id ? account : aa) });
                    }}
                    onDelete={() => {
                        props.setAssetsAndDebts({ ...assetsAndDebts, realEstate: assetsAndDebts.realEstate.filter((aa) => aa.id !== a.id) });
                    }} />

            </div>
                <Divider />
            </div>)}
            {assetsAndDebts.mortgages?.filter(a => idsToRender.includes(a.id)).map((a, i) => <div key={a.id}><div style={{ padding: theme.spacing(1) }}>
                <MortgageView key={a.id}
                    mortgage={a as MortgageVM}
                    setMortgage={(account) => {
                        props.setAssetsAndDebts({ ...assetsAndDebts, mortgages: assetsAndDebts.mortgages.map((aa) => aa.id === account.id ? account : aa) });
                    }}
                    accounts={accounts.filter(aa => aa.id !== a.id)}
                    onDelete={() => {
                        props.setAssetsAndDebts({ ...assetsAndDebts, mortgages: assetsAndDebts.mortgages.filter((aa) => aa.id !== a.id) });
                    }} />

            </div>
                <Divider />
            </div>)}
            {assetsAndDebts.pensionAccounts?.sweNationalPension?.premiumPensionAccount !== undefined && idsToRender.includes(assetsAndDebts.pensionAccounts?.sweNationalPension?.premiumPensionAccount.id)
                ? (<><div style={{ padding: theme.spacing(1) }}>
                    <NationalPensionView key={'national'}
                        nationalPension={assetsAndDebts.pensionAccounts?.sweNationalPension as NationalPensionVM}
                        setNationalPension={(account) => {
                            props.setAssetsAndDebts({
                                ...assetsAndDebts,
                                pensionAccounts: {
                                    ...assetsAndDebts.pensionAccounts,
                                    sweNationalPension: account
                                }
                            });
                        }}
                        onDelete={() => {
                            props.setAssetsAndDebts({
                                ...assetsAndDebts,
                                pensionAccounts: { ...assetsAndDebts.pensionAccounts, sweNationalPension: undefined }
                            });
                        }} />

                </div>
                    <Divider />
                </>)
                : (<></>)}
            {assetsAndDebts.pensionAccounts?.sweOccupationalPensionAccounts?.filter(a => idsToRender.includes(a.id)).map((a) => <div key={a.id}><div style={{ padding: theme.spacing(1) }}>
                <OccupationalPensionAccountView key={a.id}
                    occupationalPensionAccount={a as OccupationalPensionAccountVM}
                    setOccupationalPensionAccount={(account) => {
                        props.setAssetsAndDebts({
                            ...assetsAndDebts,
                            pensionAccounts: {
                                ...assetsAndDebts.pensionAccounts,
                                sweOccupationalPensionAccounts: assetsAndDebts.pensionAccounts.sweOccupationalPensionAccounts.map((aa) => aa.id === account.id ? account : aa)
                            }
                        });
                    }}
                    onDelete={() => {
                        props.setAssetsAndDebts({
                            ...assetsAndDebts,
                            pensionAccounts: {
                                ...assetsAndDebts.pensionAccounts,
                                sweOccupationalPensionAccounts: assetsAndDebts.pensionAccounts.sweOccupationalPensionAccounts.filter((aa) => aa.id !== a.id)
                            }
                        });
                    }} />

            </div>
                <Divider />
            </div>)}
        </Grid>
    </Grid>;
}

function GroupEditorDialog(props: {
    open: boolean,
    handleClose: () => void,
    handleDelete: () => void,
    group: GroupVM,
    setGroup: (group: GroupVM) => void,
    accounts: { name: string, id: string, type: AccountType }[]
}) {

    const theme = useTheme();
    const pensionAccounts = props.accounts.filter(a => a.type === AccountType.IncomePensionAccount || a.type === AccountType.PremiumPensionAccount).map(a => a.id);

    return (<Dialog PaperProps={{ style: { background: theme.palette.background.default } }}
        // fullScreen 
        maxWidth={'xs'} onClose={props.handleClose} aria-labelledby="simulation-dialog-title" open={props.open}>
        <DialogTitle id="simulation-dialog-title">Edit group</DialogTitle>
        <Divider></Divider>

        <div style={{ padding: theme.spacing(1) }}>
            <TextField
                variant='filled'
                style={{ marginBottom: theme.spacing(2) }}
                margin='none'
                size='small'
                fullWidth={true}
                required label={'Name'}
                onChange={(e) => {
                    props.setGroup({ ...props.group, name: e.target.value });
                }}
                value={props.group?.name}
            />
            <List sx={{ width: '100%', bgcolor: 'background.paper' }} subheader={<ListSubheader>Group items</ListSubheader>}>
                {props.accounts?.map((value, i) => {
                    const labelId = `checkbox-list-label-${i}`;
                    const inGroup = props.group?.assetsAndDebtsItemIds.includes(value.id);
                    const isNationalPension = value.type === AccountType.IncomePensionAccount || value.type === AccountType.PremiumPensionAccount;
                    return (
                        <ListItem
                            key={i}
                            disablePadding
                        >
                            <ListItemButton
                                role={undefined}
                                onClick={() => {
                                    props.setGroup(
                                        {
                                            ...props.group,
                                            assetsAndDebtsItemIds: inGroup
                                                ? props.group.assetsAndDebtsItemIds.filter(i => isNationalPension ? !pensionAccounts.includes(i) : i !== value.id)
                                                : isNationalPension ? [...props.group.assetsAndDebtsItemIds, ...pensionAccounts] : [...props.group.assetsAndDebtsItemIds, value.id]
                                        })
                                }
                                }
                                dense>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={inGroup}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={value.name} />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        </div>
        <DialogActions>
            <Button onClick={props.handleDelete} color="info">
                Delete
            </Button>
            <Button onClick={props.handleClose} color="primary" variant="contained">
                Ok
            </Button>
        </DialogActions>
    </Dialog>
    );
}

export function getColor(colors: string[], items: AssetsAndDebtsItemVM[], offset: number = 0): string {
    let index = offset;
    let i = 0;
    while (i < 5) {
        let color = colors[index];
        if (items.find(i => i.color === color)) {
            index += 3;
            if (index > 9)
                index = 0;
            i++;
        }
        else {
            return color;
        }
    }
    return colors[index];
}
