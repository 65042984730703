import { useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import { SettingsContext } from '../settings/SettingsContext';
// import { blue, red, cyan, orange, green, blueGrey, teal, deepOrange, purple } from '@mui/material/colors';

export const rallyGreen = {
    900: '#1e381f',
    800: '#365e37',
    700: '#487c53',
    600: '#5c9b6a',
    500: '#71b782',
    400: '#89e3aa',
    300: '#96edbc',
    200: '#a5f6d3',
    100: '#b9fde2',
    50: '#d1fef2',
};
export const rallyGreenArray = Object.values(rallyGreen);

export const rallyDarkGreen = {
    900: '#19312a',
    800: '#284940',
    700: '#365c56',
    600: '#4d827b',
    500: '#68ada4',
    400: '#7fd0c7',
    300: '#8ed0ca',
    200: '#a9d7d9',
    100: '#c6e1e1',
    50: '#e3eeee',

};
export const rallyDarkGreenArray = Object.values(rallyDarkGreen);

export const rallyOrange = {
    900: '#510401',
    800: '#750800',
    700: '#970e00',
    600: '#bb1400',
    500: '#d61b00',
    400: '#d83b1f',
    300: '#dc6b58',
    200: '#e0877c',
    100: '#eab4a6',
    50: '#f3d7d0',
};
export const rallyOrangeArray = Object.values(rallyOrange);

export const rallyYellow = {
    900: '#c43900',
    800: '#d35800',
    700: '#dd7203',
    600: '#e08609',
    500: '#e7ac1e',
    400: '#ebbb33',
    300: '#f0ce4a',
    200: '#f3db7b',
    100: '#f8eab1',
    50: '#fdf8e5',
};
export const rallyYellowArray = Object.values(rallyYellow);

export const rallyPurple = {
    900: '#3a0751',
    800: '#530f7b',
    700: '#6b169d',
    600: '#7c1ec6',
    500: '#8825ef',
    400: '#8d28fd',
    300: '#9241fd',
    200: '#9e64fd',
    100: '#be9df6',
    50: '#d8cbf6',

};
export const rallyPurpleArray = Object.values(rallyPurple);

export const rallyBlue = {
    900: '#395fcb',
    800: '#4a7ae3',
    700: '#5185f9',
    600: '#609dfe',
    500: '#72b5fe',
    400: '#83c6fe',
    300: '#90cefe',
    200: '#a1deff',
    100: '#caf2ff',
    50: '#f1fdff',
};
export const rallyBlueArray = Object.values(rallyBlue);

export const rallyPrimary = rallyGreen[500];

// https://material.io/inline-tools/color/ #60D3F6 / #003F6D
export function useAppColors() {
    const theme = useTheme();
    const settings = useContext(SettingsContext);

    const blue200 = settings.isLightTheme ? "#8fc1d9" : "#60d3f6";
    const blue500 = "#00a6f1";
    const blue700 = settings.isLightTheme ? "#1a6b9d" : "#0086d0"
    const blue900 = settings.isLightTheme ? "#00406d" : "#00559d";
    const green200 = settings.isLightTheme ? "#4eb9b4" : "#60f6ce";
    const green700 = settings.isLightTheme ? "#007d74" : "#00bb7d";
    const green900 = "#008755";
    const red300 = settings.isLightTheme ? "#d95686" : "#f68360";
    const red700 = settings.isLightTheme ? "#a6004e" : "#db4013";
    const deepBlue500 = "#0052e8";
    const purple300 = settings.isLightTheme ? "#d95686" : "#f660d3";
    const yellow700 = "#fbc02d";
    return ({
        // fanChart: {
        //     narrow: cyan[700],
        //     medium: cyan[200],
        //     wide: cyan[100]
        // },
        // spendingItems: {
        //     investmentAmount: green[200],
        //     personalAccount: green[600],
        //     monthlyAmortisation: cyan[500],
        //     interestPayment: red[700]
        // },
        chart: {
            axisLines: settings.isLightTheme ? theme.palette.grey[900] : theme.palette.grey[300]
        },
        fanChart: {
            narrow: rallyBlue[700],
            medium: rallyBlue[500],
            wide: rallyBlue[200],
            referenceLine: settings.isLightTheme ? theme.palette.grey[900] : theme.palette.grey[200]
        },
        outcomes: {
            bad: rallyOrange[300],
            median: rallyBlue[700],
            good: rallyDarkGreen[500]
        },
        spendingItems: {
            taxes: rallyBlue[700],
            ordinaryConsumption: rallyPurple[300],
            maintenanceCost: rallyOrange[700],
            investmentAmount: rallyDarkGreen[500],
            personalAccount: rallyDarkGreen[700],
            monthlyAmortisation: rallyBlue[200],
            interestPayment: rallyOrange[300]
        },
        pensionPots: {
            nationalIncome: rallyDarkGreen[700],
            nationalPremium: rallyOrange[700],
            occupational: rallyDarkGreen[200],
            currentPrivate: rallyBlue[500],
            newPrivate: rallyBlue[200]
        },
        status: {
            ok: rallyDarkGreen[700],
            notify: rallyYellow[700],
            error: rallyOrange[700]
        },
        effectiveFrontier: {
            optimalPortfolio: rallyBlue[200],
            currentPortfolio: rallyGreen[500]
        }
    });
}

export function useHeaderStyle() {
    const theme = useTheme();
    return theme.palette.mode === "light" ? { backgroundColor: theme.palette.grey[300] } : {};
}