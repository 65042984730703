import {
    useState,
    useCallback,
} from 'react';

import { InteractionType, PopupRequest } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { loginRequest } from '../authConfig';
import { historicalUri } from '../api_client/tsClient';
import moment from 'moment';
import { Configuration, HistoricalDataController2Api, ITimeSeriesPoint } from '../historicalApiClient';


const priceIndexCache = {} as any;

/**
 * Custom hook to call a web API using bearer token obtained from MSAL
 * @param {PopupRequest} msalRequest
 * @returns
 */
const useHistoricalApi = () => {
    const { instance } = useMsal();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const tokenRequest = {
        account: instance.getActiveAccount(),
        scopes: loginRequest.scopes
    }

    const getClient = () => instance.acquireTokenSilent(tokenRequest).then((response) => {
        // Call your API with the access token and return the data you need to save in state
        const customFetch: WindowOrWorkerGlobalScope['fetch'] = async (input: RequestInfo, init?: RequestInit) => {
            const bearer = `Bearer ${response.accessToken}`;
            
            const customHeaders = {
                'Authorization': bearer
            };

            if (init && init.headers) {
                init.headers = new Headers(init.headers);
                Object.entries(customHeaders).forEach(([key, value]) => {
                    (init.headers as Headers).append(key, value);
                });
            } else {
                init = { ...init, headers: customHeaders };
            }

            return fetch(input, init);
        };
        return new HistoricalDataController2Api(undefined, historicalUri, customFetch);
        // return response;
    }).catch(async (e) => {
        instance.acquireTokenRedirect(tokenRequest).then(r=>console.log('got token'));
        throw e;
    });

    const priceIndex = async (isin: string): Promise<ITimeSeriesPoint[]> => {
        const proxyIsin = isinMap.find(f=>f.isin === isin);
        if(proxyIsin)
        {
            isin = proxyIsin.proxyIsin;
        }
        if (priceIndexCache[isin] !== undefined) {
            return Promise.resolve(priceIndexCache[isin]);
        }
        else {
            try {
                setIsLoading(true);
                const client = await getClient();
                const result = (await client.apiV2TimeSeriesMarketContractGet(isin, moment().add(-10, 'years').toDate(), moment().toDate()))?.[0];
                priceIndexCache[isin] = result;
                return result;
            } catch (e) {
                console.log(e);
                setError(e);
                setIsLoading(false);
                throw e;
            }
        }
    };
    return {
        isLoading,
        error,
        data,
        priceIndex: useCallback(priceIndex, [])
    };
};

export default useHistoricalApi;

const isinMap = [
    {
        "contractName": "Block A",
        "isin": "US8522341036",
        "proxyName": "Skandia USA",
        "proxyIsin": "SE0000810798"
    },
    {
        "contractName": "Palantir Technologies A",
        "isin": "US69608A1088",
        "proxyName": "Skandia USA",
        "proxyIsin": "SE0000810798"
    },
    {
        "contractName": "Spotify Technology",
        "isin": "LU1778762911",
        "proxyName": "Skandia USA",
        "proxyIsin": "SE0000810798"
    },
    {
        "contractName": "Amazon Com",
        "isin": "US0231351067",
        "proxyName": "Skandia USA",
        "proxyIsin": "SE0000810798"
    },
    {
        "contractName": "Tesla",
        "isin": "US88160R1014",
        "proxyName": "Skandia USA",
        "proxyIsin": "SE0000810798"
    },
    {
        "contractName": "Visa 'A'",
        "isin": "US92826C8394",
        "proxyName": "Skandia USA",
        "proxyIsin": "SE0000810798"
    },
    {
        "contractName": "Pepsico",
        "isin": "US7134481081",
        "proxyName": "Skandia USA",
        "proxyIsin": "SE0000810798"
    },
    {
        "contractName": "Microsoft",
        "isin": "US5949181045",
        "proxyName": "Skandia USA",
        "proxyIsin": "SE0000810798"
    },
    {
        "contractName": "Apple",
        "isin": "US0378331005",
        "proxyName": "Skandia USA",
        "proxyIsin": "SE0000810798"
    },
    {
        "contractName": "Nibe Industrier",
        "isin": "SE0015988019",
        "proxyName": "Lannebo Sverige Plus",
        "proxyIsin": "SE0002686584"
    },
    {
        "contractName": "Evolution",
        "isin": "SE0012673267",
        "proxyName": "Lannebo Sverige Plus",
        "proxyIsin": "SE0002686584"
    },
    {
        "contractName": "Volvo B",
        "isin": "SE0000115446",
        "proxyName": "Lannebo Sverige Plus",
        "proxyIsin": "SE0002686584"
    },
    {
        "contractName": "McDonald's",
        "isin": "US5801351017",
        "proxyName": "Skandia USA",
        "proxyIsin": "SE0000810798"
    },
    {
        "contractName": "Avanza Global",
        "isin": "SE0011527613",
        "proxyName": "Skandia Time Global",
        "proxyIsin": "SE0001112715"
    },
    {
        "contractName": "Avanza Zero",
        "isin": "SE0001718388",
        "proxyName": "Lannebo Sverige Plus",
        "proxyIsin": "SE0002686584"
    },
    {
        "contractName": "Nvidia",
        "isin": "US67066G1040",
        "proxyName": "Skandia USA",
        "proxyIsin": "SE0000810798"
    },
    {
        "contractName": "Avanza Ränta Global",
        "isin": "SE0021021417",
        "proxyName": "Spiltan Hograntefond",
        "proxyIsin": "SE0005798329"
    },
    {
        "contractName": "Bank Invest Emerging Markets OBL Lokval A",
        "isin": "DK0060012037",
        "proxyName": "Spiltan Hograntefond",
        "proxyIsin": "SE0005798329"
    },
    {
        "contractName": "Coeli SICAV I FRTR Markets Fixed Income R SEK",
        "isin": "LU2273159629",
        "proxyName": "Spiltan Hograntefond",
        "proxyIsin": "SE0005798329"
    },
    {
        "contractName": "Senegal 2018 6 3/4% 13/03/48 Regulation S",
        "isin": "XS1790134362",
        "proxyName": "Spiltan Hograntefond",
        "proxyIsin": "SE0005798329"
    },
    {
        "contractName": "Nordea Hypotek 2021 1% 16/06/27 5537",
        "isin": "SE0013358447",
        "proxyName": "Spiltan Hograntefond",
        "proxyIsin": "SE0005798329"
    },
    {
        "contractName": "Avanza Emerging Markets A",
        "isin": "SE0012454338",
        "proxyName": "Carnegie Worldwide Emerging Mts Eqs 1A",
        "proxyIsin": "LU0086737482"
    },
    {
        "contractName": "Avanza Sverige",
        "isin": "SE0014956850",
        "proxyName": "Lannebo Sverige Plus",
        "proxyIsin": "SE0002686584"
    },
    {
        "contractName": "Avanza Fastighet by Norhammar",
        "isin": "SE0017084296",
        "proxyName": "Carnegie Swedish Small Cap 1A",
        "proxyIsin": "LU0424682077"
    },
    {
        "contractName": "Investor B",
        "isin": "SE0015811963",
        "proxyName": "Lannebo Sverige Plus",
        "proxyIsin": "SE0002686584"
    },
    {
        "contractName": "Castellum",
        "isin": "SE0000379190",
        "proxyName": "Lannebo Sverige Plus",
        "proxyIsin": "SE0002686584"
    },
    {
        "contractName": "Swedbank A",
        "isin": "SE0000242455",
        "proxyName": "Lannebo Sverige Plus",
        "proxyIsin": "SE0002686584"
    },
    {
        "contractName": "Axfood",
        "isin": "SE0006993770",
        "proxyName": "Lannebo Sverige Plus",
        "proxyIsin": "SE0002686584"
    },
    {
        "contractName": "Telia Company",
        "isin": "SE0000667925",
        "proxyName": "Lannebo Sverige Plus",
        "proxyIsin": "SE0002686584"
    },
    {
        "contractName": "Coca Cola",
        "isin": "US1912161007",
        "proxyName": "Skandia USA",
        "proxyIsin": "SE0000810798"
    },
    {
        "contractName": "Johnson and Johnson",
        "isin": "US4781601046",
        "proxyName": "Skandia USA",
        "proxyIsin": "SE0000810798"
    },
    {
        "contractName": "Procter and Gamble",
        "isin": "US7427181091",
        "proxyName": "Skandia USA",
        "proxyIsin": "SE0000810798"
    },
    {
        "contractName": "Rivian Automotive A",
        "isin": "US76954A1034",
        "proxyName": "Skandia USA",
        "proxyIsin": "SE0000810798"
    }
]