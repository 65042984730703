import { ProductCodeContract, ProductCodeType } from "../api_client";

export function includeOnlySpecificIsins(funds:ProductCodeContract[], fundsToRemove: ProductCodeContract[]):ProductCodeContract[]
{
	return funds
    .filter(f => f.codeType === ProductCodeType.Custom || fundsInStatic.find(s => s === f?.code) !== undefined || otherValidCodes.includes(f?.code))
    .filter(f=> fundsToRemove === undefined || !fundsToRemove.map(ff=>ff?.code).includes(f?.code));
}

export const staticFundData = {
	"funds":
		[
			{
				"name": "Swedbank Robur Access Asien A",
				"isin": "SE0007074117",
				"yearly_fee_pp": 0.23,
				"currecy": "SEK",
				"risk": 6,
				"morningstar_rating": 3,
				"geographical_regions":
					[
						{
							"name": "Asien tillväxt",
							"percentage": 58.15
						},
						{
							"name": "Asien",
							"percentage": 41.30
						},
						{
							"name": "USA",
							"percentage": 0.12
						},
						{
							"name": "Australasien",
							"percentage": 0.08
						}
					],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 99.3
						},
						{
							"name": "Kort ränta",
							"percentage": 0.7
						}
					],
				"sectors":
					[
						{
							"name": "Teknik",
							"percentage": 22.62
						},
						{
							"name": "Finans",
							"percentage": 20.99
						},
						{
							"name": "Konsument, cyklisk",
							"percentage": 17.52
						},
						{
							"name": "Kommunikation",
							"percentage": 9.91
						},
						{
							"name": "Industri",
							"percentage": 7.43
						},
						{
							"name": "Fastigheter",
							"percentage": 5.74
						},
						{
							"name": "Råvaror",
							"percentage": 5.72
						},
						{
							"name": "Konsument, stabil",
							"percentage": 4.45
						},
						{
							"name": "Sjukvård",
							"percentage": 4.31
						},
						{
							"name": "Allmännyttig",
							"percentage": 1.30
						}
					],
				"holdings":
					[
						{
							"name": "Taiwan Semiconductor Manufacturing Co Ltd",
							"percentage": 6.52
						},
						{
							"name": "MSCI AC Asia ex Japan Future Mar 23",
							"percentage": 4.91
						},
						{
							"name": "Tencent Holdings Ltd",
							"percentage": 4.30
						},
						{
							"name": "Alibaba Group Holding Ltd Ordinary Shares",
							"percentage": 3.45
						},
						{
							"name": "Samsung Electronics Co Ltd",
							"percentage": 3.27
						},
						{
							"name": "AIA Group Ltd",
							"percentage": 2.18
						},
						{
							"name": "Meituan Class B",
							"percentage": 1.86
						},
						{
							"name": "Infosys Ltd",
							"percentage": 1.17
						},
						{
							"name": "JD.com Inc Ordinary Shares - Class A",
							"percentage": 1.09
						},
						{
							"name": "Housing Development Finance Corp Ltd",
							"percentage": 1.03
						}
					],
				"description": "Access Asien är en indexnära aktiefond som ger exponering mot stora och medelstora bolag i olika branscher i Asien exklusive Japan. Fonden förvaltas med en kvantitativ strategi som syftar till att skapa en portfölj med hög hållbarhetsprofil samtidigt som den i sin sammansättning ska efterlikna karaktären på sitt jämförelseindex. Fonden följer Swedbank Roburs policy om ansvarsfulla investeringar samt väljer bort enligt Utökad nivå, läs mer i fondens Informationsbroschyr och på swedbankrobur.se. Fonden är för dig som vill ha en prisvärd och bred aktieexponering mot Asien. Den rekommenderade placeringshorisonten är minst 5 år. Fonden finns i flera andelsklasser, läs mer i fondens Informationsbroschyr om vad som skiljer dem åt."
			},
			{
				"name": "Handelsbanken Ruotsi Selective",
				"isin": "SE0005965639",
				"yearly_fee_pp": 1.85,
				"currecy": "SEK",
				"risk": 4,
				"morningstar_rating": 5,
				"geographical_regions":
					[
						{
							"name": "Europa exkl. euroland",
							"percentage": 100
						}
					],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 95.6
						},
						{
							"name": "Kort ränta",
							"percentage": 4.4
						}
					],
				"sectors":
					[
						{
							"name": "Industri",
							"percentage": 57.37
						},
						{
							"name": "Teknik",
							"percentage": 13.66
						},
						{
							"name": "Konsument, cyklisk",
							"percentage": 8.94
						},
						{
							"name": "Konsument, stabil",
							"percentage": 8.17
						},
						{
							"name": "Finans",
							"percentage": 6.26
						},
						{
							"name": "Sjukvård",
							"percentage": 5.60
						}
					],
				"holdings":
					[
						{
							"name": "Volvo, AB ser. B",
							"percentage": 8.67
						},
						{
							"name": "Atlas Copco AB ser. A",
							"percentage": 7.46
						},
						{
							"name": "ASSA ABLOY AB ser. B",
							"percentage": 6.24
						},
						{
							"name": "Svenska Handelsbanken ser. A",
							"percentage": 5.99
						},
						{
							"name": "Hexagon AB ser. B",
							"percentage": 5.92
						},
						{
							"name": "ABB LTD Rg",
							"percentage": 4.90
						},
						{
							"name": "Epiroc AB ser. A",
							"percentage": 4.68
						},
						{
							"name": "Essity AB ser. B",
							"percentage": 4.49
						},
						{
							"name": "Autoliv Inc. SDB",
							"percentage": 4.19
						},
						{
							"name": "Novo Nordisk B A/S",
							"percentage": 4.01
						}
					],
				"description": "Fonden placerar i huvudsak i företag på den svenska aktiemarknaden. Fondens placeringar koncentrerar innehaven till ett mindre antal företag och placeringar sker normalt i 16 till 35 företag. Placeringarna är inriktade mot företag som historiskt har bevisat att de har en stabil vinstutveckling, starka balansräkningar, sund affärsverksamhet och utdelningstillväxt. Denna fond förvaltas med utgångspunkt i fondbolagets Hållbarhetsstrategi. Fonden är en aktivt förvaltad fond. Med aktivt förvaltad avses att förvaltaren fattar egna aktiva placeringsbeslut med målsättningen att långsiktigt överträffa jämförelseindexets avkastning. Fondens jämförelseindex är SIX SRI Sweden Index GI. Jämförelseindexet är relevant då det återspeglar fondens placeringsuniversum så som det är beskrivet i fondens placeringsinriktning."
			},
			{
				"name": "Spiltan Högräntefond",
				"isin": "SE0005798329",
				"yearly_fee_pp": 0.75,
				"currecy": "SEK",
				"risk": 3,
				"morningstar_rating": 4,
				"geographical_regions":
					[],
				"asset_classes":
					[
						{
							"name": "Lång ränta",
							"percentage": 98.1
						},
						{
							"name": "Kort ränta",
							"percentage": 1.9
						}
					],
				"sectors":
					[],
				"holdings":
					[
						{
							"name": "NBHB 5534",
							"percentage": 8.01
						},
						{
							"name": "SHYB1589",
							"percentage": 7.48
						},
						{
							"name": "SCBC 145",
							"percentage": 7.48
						},
						{
							"name": "SWHO193",
							"percentage": 4.65
						},
						{
							"name": "STOR 2125",
							"percentage": 4.04
						},
						{
							"name": "SWHO194",
							"percentage": 3.43
						},
						{
							"name": "Corem Property Grp 5.608%",
							"percentage": 2.66
						},
						{
							"name": "Samhallsbyggnadsbolaget I Norden AB 6.419%",
							"percentage": 2.60
						},
						{
							"name": "HEIM 3",
							"percentage": 2.55
						},
						{
							"name": "HEIM 5",
							"percentage": 2.48
						}
					],
				"description": "Spiltan Högräntefond är aktivt förvaltad och placerar huvudsakligen i företagsobligationer men kan även investera i andra värdepapper med betydande ränteinslag. Fonden får placera i nordiska bolag men tar ingen valutarisk. Den genomsnittliga räntelöptiden för placeringarna, mätt på den totala fondförmögenheten, kan variera mellan noll till tio år. Fondens målsättning är att erbjuda dubbelt så hög avkastning som traditionella korträntefonder."
			},
			{
				"name": "Lynx dynamic",
				"isin": "SE0002866772",
				"yearly_fee_pp": 1.50,
				"currecy": "SEK",
				"risk": 6,
				"morningstar_rating": 0,
				"geographical_regions":
					[],
				"asset_classes":
					[],
				"sectors":
					[],
				"holdings":
					[],
				"description": "Fondens placeringsuniversum utgörs av aktier, obligationer, valutor och råvaror. Investeringarna görs främst i terminsinstrument som handlas på börser runt om i världen. Genom att sprida investeringarna över olika tillgångsslag ökar sannolikheten att ett dåligt resultat i en marknad uppvägs av ett bra resultat i en annan marknad. Därmed kan svängningarna, eller risken, i fondens totala portfölj minska utan att avkastningen blir lägre."
			},
			{
				"name": "Lannebo Sverige Plus",
				"isin": "SE0002686584",
				"yearly_fee_pp": 1.03,
				"currecy": "SEK",
				"risk": 6,
				"morningstar_rating": 5,
				"geographical_regions":
					[
						{
							"name": "Europa exkl. euroland",
							"percentage": 85.08
						},
						{
							"name": "USA",
							"percentage": 7.77
						},
						{
							"name": "Euroland",
							"percentage": 7.15
						}
					],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 95.5
						},
						{
							"name": "Kort ränta",
							"percentage": 4.5
						}
					],
				"sectors":
					[
						{
							"name": "Finans",
							"percentage": 30.29
						},
						{
							"name": "Sjukvård",
							"percentage": 15.75
						},
						{
							"name": "Teknik",
							"percentage": 15.18
						},
						{
							"name": "Kommunikation",
							"percentage": 14.64
						},
						{
							"name": "Industri",
							"percentage": 9.87
						},
						{
							"name": "Konsument, cyklisk",
							"percentage": 9.87
						},
						{
							"name": "Konsument, stabil",
							"percentage": 2.90
						},
						{
							"name": "Råvaror",
							"percentage": 1.50
						}
					],
				"holdings":
					[
						{
							"name": "Swedbank AB ser A",
							"percentage": 8.53
						},
						{
							"name": "Svenska Handelsbanken ser. A",
							"percentage": 7.84
						},
						{
							"name": "Ericsson, Telefonab. L M ser. B",
							"percentage": 7.79
						},
						{
							"name": "Millicom International Cellular S.A. SDB",
							"percentage": 7.43
						},
						{
							"name": "Nokia Corporation",
							"percentage": 5.38
						},
						{
							"name": "Autoliv Inc. SDB",
							"percentage": 4.78
						},
						{
							"name": "Tele2 AB ser. B",
							"percentage": 4.75
						},
						{
							"name": "SKF, AB ser. B",
							"percentage": 4.70
						},
						{
							"name": "Nordea Bank Abp",
							"percentage": 4.69
						},
						{
							"name": "Sandvik AB",
							"percentage": 4.69
						}
					],
				"description": "En investering i fonden ger dig en fokuserad inriktning mot svenska börsbolag med globala marknadspositioner. Fonden förvaltas av ett erfaret förvaltarteam som till skillnad från en vanlig aktiefond har möjlighet att ta korta positioner som balanseras med ytterligare långa positioner. Målsättningen är att skapa meravkastning genom att förstärka över- och undervikter gentemot jämförelseindex. Förvaltningen är långsiktig och riskmedveten, och bedrivs inom Lannebo som är ett helt oberoende och självständigt fondbolag. Fonden investerar inte i bolag inom branscher så som fossila bränslen, tobak, pornografi och vapen. Lannebo har som fondbolag högsta möjliga hållbarhetsbetyg för sin aktieförvaltning, A+, från UN PRI."
			},
			{
				"name": "Skandia Världen",
				"isin": "SE0001112723",
				"yearly_fee_pp": 1.40,
				"currecy": "SEK",
				"risk": 5,
				"morningstar_rating": 4,
				"geographical_regions":
					[
						{
							"name": "USA",
							"percentage": 56.38
						},
						{
							"name": "Euroland",
							"percentage": 10.32
						},
						{
							"name": "Europa exkl. euroland",
							"percentage": 7.63
						},
						{
							"name": "Storbritannien",
							"percentage": 6.60
						}
					],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 99
						},
						{
							"name": "Kort ränta",
							"percentage": 1
						}
					],
				"sectors":
					[
						{
							"name": "Finans",
							"percentage": 19.08
						},
						{
							"name": "Teknik",
							"percentage": 17.04
						},
						{
							"name": "Konsument, stabil",
							"percentage": 15.41
						},
						{
							"name": "Industri",
							"percentage": 15.17
						},
						{
							"name": "Konsument, cyklisk",
							"percentage": 12.45
						},
						{
							"name": "Sjukvård",
							"percentage": 11.70
						},
						{
							"name": "Kommunikation",
							"percentage": 9.16
						}
					],
				"holdings":
					[
						{
							"name": "Microsoft",
							"percentage": 3.37
						},
						{
							"name": "Alphabet A",
							"percentage": 3.19
						},
						{
							"name": "Amazon.com",
							"percentage": 2.58
						},
						{
							"name": "Thermo Fisher Scientific",
							"percentage": 2.51
						},
						{
							"name": "Accenture A",
							"percentage": 2.34
						},
						{
							"name": "IQVIA",
							"percentage": 2.25
						},
						{
							"name": "Aon",
							"percentage": 2.25
						},
						{
							"name": "Wolters Kluwer NV",
							"percentage": 2.19
						},
						{
							"name": "National Bank Of Canada",
							"percentage": 2.12
						},
						{
							"name": "Johnson & Johnson",
							"percentage": 2.11
						}
					],
				"description": "Skandia Världen är en aktiefond med en bred inriktning. Fonden investerar i globala aktier. Investeringarna fördelas över olika länder och branscher, främst i de större börsföretagen. Fonden investerar inte i bolag som bryter mot internationella hållbarhetsrelaterade normer (t ex FN Global Compact) utan att visa förändringsvilja, är involverade i kontroversiella vapen eller producerar tobak. Fonden investerar inte heller i bolag som är involverade i cannabis för rekreationsbruk och har en mycket restriktiv hållning gentemot bolag involverade i medicinsk cannabis. Vidare avstår fonden från att investera i bolag som utvinner kol för energiändamål."
			},
			{
				"name": "Lannebo Mixfond",
				"isin": "SE0000740706",
				"yearly_fee_pp": 1.63,
				"currecy": "SEK",
				"risk": 5,
				"morningstar_rating": 4,
				"geographical_regions":
					[
						{
							"name": "Europa exkl. euroland",
							"percentage": 82.31
						},
						{
							"name": "Euroland",
							"percentage": 12.10
						},
						{
							"name": "Storbritannien",
							"percentage": 5.59
						}
					],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 60.1
						},
						{
							"name": "Kort ränta",
							"percentage": 23.2
						},
						{
							"name": "Lång ränta",
							"percentage": 16.8
						}
					],
				"sectors":
					[
						{
							"name": "Industri",
							"percentage": 30.79
						},
						{
							"name": "Finans",
							"percentage": 26.60
						},
						{
							"name": "Allmännyttigt",
							"percentage": 9.02
						},
						{
							"name": "Sjukvård",
							"percentage": 9
						},
						{
							"name": "Konsument, cyklisk",
							"percentage": 8.93
						},
						{
							"name": "Råvaror",
							"percentage": 6.62
						},
						{
							"name": "Teknik",
							"percentage": 3.58
						},
						{
							"name": "Fastigheter",
							"percentage": 2.82
						},
						{
							"name": "Konsument, stabil",
							"percentage": 2.64
						}
					],
				"holdings":
					[
						{
							"name": "Bravida Holding AB",
							"percentage": 3.92
						},
						{
							"name": "Investor AB ser. B",
							"percentage": 3.84
						},
						{
							"name": "NIBE Industrier AB ser. B",
							"percentage": 3.84
						},
						{
							"name": "AstraZeneca",
							"percentage": 3.36
						},
						{
							"name": "OX2 AB",
							"percentage": 3.05
						},
						{
							"name": "Swedbank AB ser A",
							"percentage": 2.93
						},
						{
							"name": "Sandvik AB",
							"percentage": 2.87
						},
						{
							"name": "Sampo Plc A",
							"percentage": 2.59
						},
						{
							"name": "Novo Nordisk B A/S",
							"percentage": 2.52
						},
						{
							"name": "Alfa Laval AB",
							"percentage": 2.45
						},
						{
							"name": "Ørsted A/S",
							"percentage": 2.36
						},
						{
							"name": "DNB BANK ASA",
							"percentage": 2.35
						}
					],
				"description": "En investering i fonden ger dig en bred inriktning mot såväl aktier som räntebärande värdepapper. Lannebo Mixfond är en aktivt förvaltad blandfond där det erfarna förvaltarteamet beroende på sin marknadssyn växlar fördelningen mellan tillgångsslagen. Aktieandelen tillåts variera mellan 0 till 100 procent. Förvaltningen är långsiktig och riskmedveten, och bedrivs inom Lannebo som är ett helt oberoende och självständigt fondbolag. Fonden investerar inte i bolag inom branscher så som fossila bränslen, tobak, pornografi och vapen. Lannebo har som fondbolag högsta möjliga hållbarhetsbetyg för sin aktieförvaltning, A+, från UN PRI."
			},
			{
				"name": "Skandia Nordamerika Exponering",
				"isin": "SE0005065505",
				"yearly_fee_pp": 0.25,
				"currecy": "SEK",
				"risk": 6,
				"morningstar_rating": 3,
				"geographical_regions":
					[
						{
							"name": "USA",
							"percentage": 94.07
						},
						{
							"name": "Kanada",
							"percentage": 4.50
						},
						{
							"name": "Storbritannien",
							"percentage": 0.72
						},
						{
							"name": "Europa exkl. euroland",
							"percentage": 0.39
						},
						{
							"name": "Euroland",
							"percentage": 0.16
						}
					],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 99.8
						},
						{
							"name": "Kort ränta",
							"percentage": 0.2
						}
					],
				"sectors":
					[
						{
							"name": "Teknik",
							"percentage": 26.85
						},
						{
							"name": "Sjukvård",
							"percentage": 16.83
						},
						{
							"name": "Finans",
							"percentage": 15.07
						},
						{
							"name": "Konsument, cyklisk",
							"percentage": 12.40
						},
						{
							"name": "Kommunikation",
							"percentage": 9.08
						},
						{
							"name": "Konsument, stabil",
							"percentage": 6.91
						},
						{
							"name": "Fastigheter",
							"percentage": 3.39
						},
						{
							"name": "Råvaror",
							"percentage": 1.48
						},
						{
							"name": "Allmännyttigt",
							"percentage": 0.49
						}
					],
				"holdings":
					[
						{
							"name": "Apple",
							"percentage": 6.91
						},
						{
							"name": "Microsoft",
							"percentage": 5.34
						},
						{
							"name": "Amazon.com",
							"percentage": 2.74
						},
						{
							"name": "Alphabet A",
							"percentage": 1.72
						},
						{
							"name": "Alphabet C",
							"percentage": 1.65
						},
						{
							"name": "NVIDIA",
							"percentage": 1.61
						},
						{
							"name": "UnitedHealth Group",
							"percentage": 1.48
						},
						{
							"name": "Tesla",
							"percentage": 1.36
						},
						{
							"name": "Johnson & Johnson",
							"percentage": 1.35
						},
						{
							"name": "JP Morgan Chase",
							"percentage": 1.22
						}
					],
				"description": "Skandia Nordamerika Exponering är en aktiefond med en bred inriktning och hållbart fokus. Fonden investerar i nordamerikanska aktier över olika branscher, främst i de större börsföretagen. Förvaltningen av fonden är indexnära och strävar efter att efterbilda avkastningen på sitt skräddarsydda jämförelseindex, vilket utesluter bolag som utvinner eller genererar kraft från fossila bränslen, producerar vapen, tobak, cannabis eller kränker internationella normer. I tillägg premieras bolag som förvaltaren bedömer vara ledande inom sina branscher på att hantera relevanta hållbarhetsrisker och möjligheter."
			},
			{
				"name": "ODIN Small Cap C SEK",
				"isin": "SE0009495633",
				"yearly_fee_pp": 1.5,
				"currecy": "SEK",
				"risk": 4,
				"morningstar_rating": 4,
				"geographical_regions":
					[
						{
							"name": "Europa exkl. euroland",
							"percentage": 90.84
						},
						{
							"name": "Euroland",
							"percentage": 1.4
						}
					],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 98.6
						},
						{
							"name": "Kort ränta",
							"percentage": 1.4
						}
					],
				"sectors":
					[
						{
							"name": "Industri",
							"percentage": 36.35
						},
						{
							"name": "Teknik",
							"percentage": 29.26
						},
						{
							"name": "Konsument, cyklisk",
							"percentage": 9.17
						},
						{
							"name": "Kommunikation",
							"percentage": 6.83
						},
						{
							"name": "Sjukvård",
							"percentage": 5.99
						},
						{
							"name": "Fastigheter",
							"percentage": 5.95
						},
						{
							"name": "Finans",
							"percentage": 5.39
						},
						{
							"name": "Råvaror",
							"percentage": 1.06
						}
					],
				"holdings":
					[
						{
							"name": "CTT Systems AB",
							"percentage": 5.05
						},
						{
							"name": "NCAB Group AB",
							"percentage": 5.05
						},
						{
							"name": "Absolent Air Care Group AB",
							"percentage": 4.91
						},
						{
							"name": "Mips AB",
							"percentage": 4.76
						},
						{
							"name": "OEM International AB ser. B",
							"percentage": 4.38
						},
						{
							"name": "Bravida Holding AB",
							"percentage": 4.25
						},
						{
							"name": "Vaisala Corporation A",
							"percentage": 4.21
						},
						{
							"name": "Troax Group AB",
							"percentage": 3.96
						},
						{
							"name": "Fortnox AB",
							"percentage": 3.79
						},
						{
							"name": "Bufab AB",
							"percentage": 3.73
						}
					],
				"description": "Fondens tillgångar ska placeras i små och medelstora företag baserade i Norden med tonvikt på Sverige."
			},
			{
				"name": "Nordea Småbolagsfond Norden",
				"isin": "FI0008813365",
				"yearly_fee_pp": 1.61,
				"currecy": "SEK",
				"risk": 4,
				"morningstar_rating": 5,
				"geographical_regions":
					[
						{
							"name": "Europa exkl. euroland",
							"percentage": 79.73
						},
						{
							"name": "Euroland",
							"percentage": 20.24
						},
						{
							"name": "Kanada",
							"percentage": 0.03
						}
					],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 98.1
						},
						{
							"name": "Kort ränta",
							"percentage": 1.9
						}
					],
				"sectors":
					[
						{
							"name": "Industri",
							"percentage": 34.63
						},

						{
							"name": "Konsument, cyklisk",
							"percentage": 11.25
						},
						{
							"name": "Teknik",
							"percentage": 10.91
						},
						{
							"name": "Sjukvård",
							"percentage": 10.15
						},
						{
							"name": "Finans",
							"percentage": 8.12
						},
						{
							"name": "Råvaror",
							"percentage": 7.23
						},
						{
							"name": "Fastigheter",
							"percentage": 7.23
						},
						{
							"name": "Konsument, stabil",
							"percentage": 5.32
						},
						{
							"name": "Kommunikation",
							"percentage": 4.74
						},
						{
							"name": "Allmännyttigt",
							"percentage": 0.39
						},
						{
							"name": "Energi",
							"percentage": 0.03
						}
					],
				"holdings":
					[
						{
							"name": "SECTRA AB ser B",
							"percentage": 6.58
						},
						{
							"name": "Fenix Outdoor International AG Ser. B",
							"percentage": 3.51
						},
						{
							"name": "Castellum AB",
							"percentage": 3.03
						},
						{
							"name": "Vaisala Corporation A",
							"percentage": 2.92
						},
						{
							"name": "Holmen AB ser. B",
							"percentage": 2.75
						},
						{
							"name": "Jyske Bank A/S",
							"percentage": 2.65
						},
						{
							"name": "NKT A/S",
							"percentage": 2.60
						},
						{
							"name": "Solar B A/S",
							"percentage": 2.13
						},
						{
							"name": "Uponor Oyj",
							"percentage": 1.99
						},
						{
							"name": "OEM International AB ser. B",
							"percentage": 1.94
						}
					],
				"description": "En aktiefond som huvudsakligen placerar i små och medelstora företag på de nordiska aktiemarknaderna. Med små och medelstora företag menas företag med ett marknadsvärde på högst 0,5 procent av de nordiska aktiemarknadernas sammanlagda marknadsvärde. Aktierna väljs ut med stor omsorg och möten med företagen är en viktig del av förvaltningsteamets analysarbete. Aktieurvalet bygger på fundamental analys och syftar inte till att uppnå en viss sammansättning mellan olika sektorer eller länder. Förvaltaren söker efter företag som har en tydlig affärsmodell, en stark ledning, starka ägare och en god ekonomi. Fonden har innehav i ett brett urval av företag. Fonden omfattas av Nordeas policy för Ansvarsfulla investeringar."
			},
			{
				"name": "C Worldwide Sweden Small Cap 1A",
				"isin": "LU0424682077",
				"yearly_fee_pp": 1.66,
				"currecy": "SEK",
				"risk": 5,
				"morningstar_rating": 3,
				"geographical_regions":
					[
						{
							"name": "Europa exkl. euroland",
							"percentage": 100
						}
					],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 100
						}
					],
				"sectors": [
					{
						"name": "Industri",
						"percentage": 39.37
					},
					{
						"name": "Sjukvård",
						"percentage": 15.11
					},
					{
						"name": "Konsument, cyklisk",
						"percentage": 14.65
					},
					{
						"name": "Teknik",
						"percentage": 11.81
					},
					{
						"name": "Fastigheter",
						"percentage": 7.43
					},
					{
						"name": "Finans",
						"percentage": 7.14
					},
					{
						"name": "Kommunikation",
						"percentage": 4.49
					}
				],
				"holdings": [
					{
						"name": "Addtech AB ser. B",
						"percentage": 5.77
					},
					{
						"name": "NCAB Group AB",
						"percentage": 5.62
					},
					{
						"name": "Electrolux Professional AB ser. B",
						"percentage": 5.14
					},
					{
						"name": "Systemair AB",
						"percentage": 5.00
					},
					{
						"name": "Nordnet AB",
						"percentage": 4.96
					},
					{
						"name": "Munters Group AB",
						"percentage": 4.66
					},
					{
						"name": "Beijer Alma AB ser. B",
						"percentage": 4.66
					},
					{
						"name": "Wihlborgs Fastigheter AB",
						"percentage": 4.33
					},
					{
						"name": "Thule Group AB",
						"percentage": 4.13
					},
					{
						"name": "INVISIO AB",
						"percentage": 4.11
					}
				],
				"description": "Vårt mål är att skapa en långsiktig tillväxt i ditt investerade kapital genom att investera i aktier i ett begränsat antal små och medelstora bolag noterade i Sverige. Andelsklassen kan dock investera upp till 10% av sina nettotillgångar i aktier i bolag noterade i Danmark, Finland eller Norge. Andelsklassen skall investera sina tillgångar i bolag vars marknadsvärde vid investeringstillfället uppgår till maximalt 1 procent av den totala reglerade svenska aktiemarknaden. Vi fattar våra investeringsbeslut utifrån en grundlig analys av bolagens intäkter, vinster, vinstmarginaler och övrig information. Analysen kombineras med en utvärdering av de finansiella marknaderna genom att identifiera globala trender. Detta möjliggör att vi kan bygga en koncentrerad portfölj av bolag vilka vi har en detaljkunskap om och som enligt vår övertygelse utgör de mest lovande investeringsmöjligheterna på lång sikt. Vi kan fritt välja vilka investeringar vi vill göra utifrån alla tillgängliga möjligheter."
			},
			{
				"name": "Spiltan Aktiefond Investmentbolag",
				"isin": "SE0004297927",
				"yearly_fee_pp": 0.20,
				"currecy": "SEK",
				"risk": 5,
				"morningstar_rating": 5,
				"geographical_regions":
					[
						{
							"name": "Europa exkl. euroland",
							"percentage": 95.45
						},
						{
							"name": "Storbritannien",
							"percentage": 4.43
						},
						{
							"name": "Euroland",
							"percentage": 0.13
						}
					],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 99.1
						},
						{
							"name": "Kort ränta",
							"percentage": 0.9
						}
					],
				"sectors": [
					{
						"name": "Finans",
						"percentage": 70.36
					},
					{
						"name": "Industri",
						"percentage": 15.20
					},
					{
						"name": "Sjukvård",
						"percentage": 4.47
					},
					{
						"name": "Teknik",
						"percentage": 4.47
					},
					{
						"name": "Konsument, stabil",
						"percentage": 3.06
					},
					{
						"name": "Råvaror",
						"percentage": 2.06
					},
					{
						"name": "Konsument, cyklisk",
						"percentage": 0.22
					},
					{
						"name": "Kommunikation",
						"percentage": 0.15
					},
					{
						"name": "Fastigheter",
						"percentage": 0.01
					}
				],
				"holdings": [
					{
						"name": "Investor AB ser. B",
						"percentage": 28.32
					},
					{
						"name": "Industrivärden, AB ser. C",
						"percentage": 17.37
					},
					{
						"name": "Kinnevik AB ser. B",
						"percentage": 7.46
					},
					{
						"name": "Lundbergföretagen AB, L E ser. B",
						"percentage": 7.41
					},
					{
						"name": "Indutrade AB",
						"percentage": 4.42
					},
					{
						"name": "Lifco AB ser.B",
						"percentage": 4.29
					},
					{
						"name": "AstraZeneca",
						"percentage": 4.22
					},
					{
						"name": "Hexagon AB ser. B",
						"percentage": 4.06
					},
					{
						"name": "XACT OMXS30 ESG (UCITS ETF)",
						"percentage": 3.76
					},
					{
						"name": "EQT AB",
						"percentage": 3.47
					}
				],
				"description": "Spiltan Aktiefond Investmentbolag är en aktiefond som i huvudsak placerar i en koncentrerad portfölj av svenska investmentbolag samt företag med investmentbolagskaraktär, inklusive konglomerat. Fonden ger en bred exponering mot Stockholmsbörsen och är ett alternativ till att investera i en traditionell svensk indexfond. Förvaltningen är passiv vilket möjliggör fondens låga avgift på 0,2% per år. Målet med fonden är att ge en bättre avkastning över tid än Stockholmsbörsen till en avgift i nivå med traditionella indexfonder."
			},
			{
				"name": "Öhman Sweden Micro Cap A",
				"isin": "SE0000432809",
				"yearly_fee_pp": 1.53,
				"currecy": "SEK",
				"risk": 5,
				"morningstar_rating": 3,
				"geographical_regions":
					[
						{
							"name": "Europa exkl. euroland",
							"percentage": 97.95
						},
						{
							"name": "Kanada",
							"percentage": 1.48
						},
						{
							"name": "Euroland",
							"percentage": 0.58
						}
					],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 95.4
						},
						{
							"name": "Kort ränta",
							"percentage": 4.6
						}
					],
				"sectors": [
					{
						"name": "Industri",
						"percentage": 30.95
					},
					{
						"name": "Sjukvård",
						"percentage": 24.54
					},
					{
						"name": "Teknik",
						"percentage": 12.86
					},
					{
						"name": "Fastigheter",
						"percentage": 11.73
					},
					{
						"name": "Råvaror",
						"percentage": 8.32
					},
					{
						"name": "Konsument, cyklisk",
						"percentage": 4.55
					},
					{
						"name": "Finans",
						"percentage": 3.67
					},
					{
						"name": "Kommunikation",
						"percentage": 2.59
					},
					{
						"name": "Konsument, stabil",
						"percentage": 0.79
					}
				],
				"holdings": [
					{
						"name": "Beijer Ref AB ser. B",
						"percentage": 4.51
					},
					{
						"name": "Lindab International AB",
						"percentage": 3.73
					},
					{
						"name": "Nordnet AB",
						"percentage": 3.51
					},
					{
						"name": "HMS Networks AB",
						"percentage": 3.30
					},
					{
						"name": "Systemair AB",
						"percentage": 3.24
					},
					{
						"name": "SSAB AB ser. A",
						"percentage": 2.92
					},
					{
						"name": "NORDIC SEMICONDUCTOR",
						"percentage": 2.86
					},
					{
						"name": "Beijer Alma AB ser. B",
						"percentage": 2.85
					},
					{
						"name": "Mycronic AB",
						"percentage": 2.72
					},
					{
						"name": "Camurus AB",
						"percentage": 2.63
					}
				],
				"description": "Fonden är en aktiefond med målsättning att uppnå högsta möjliga kapitaltillväxt givet fondens placeringsinriktning. Fonden förvaltas aktivt och hållbart. Fonden placerar i mindre bolag med tonvikt på Sverige. Fonden investerar i bolag vars börsvärde är högst 0,5 procent av det totala värdet på Stockholmsbörsen. Fonden kan investera upp till 10 procent av fondens värde på övriga nordiska aktiemarknader. Hållbarhetsaspekter beaktas i ekonomiska bolagsanalyser och investeringsbeslut, vilket får effekt men behöver inte vara avgörande för vilka bolag som väljs in i fonden. Hållbarhetsaspekter är uttryckligen en del av investeringsprocessen, analyseras kontinuerligt och påverkar fondens investeringar."
			},
			{
				"name": "Nordea Klimatfond BP SEK",
				"isin": "LU0348926527",
				"yearly_fee_pp": 1.78,
				"currecy": "SEK",
				"risk": 4,
				"morningstar_rating": 5,
				"geographical_regions":
					[
						{
							"name": "USA",
							"percentage": 54.56
						},
						{
							"name": "Euroland",
							"percentage": 17.43
						},
						{
							"name": "Japan",
							"percentage": 7.43
						},
						{
							"name": "Storbritannien",
							"percentage": 6.58
						},
						{
							"name": "Kanada",
							"percentage": 4.45
						}
					],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 97.3
						},
						{
							"name": "Kort ränta",
							"percentage": 2.7
						}
					],
				"sectors": [
					{
						"name": "Industri",
						"percentage": 33.36
					},
					{
						"name": "Teknik",
						"percentage": 21.61
					},
					{
						"name": "Råvaror",
						"percentage": 16.30
					},
					{
						"name": "Allmännyttigt",
						"percentage": 11.21
					},
					{
						"name": "Konsument, cyklisk",
						"percentage": 10.74
					},
					{
						"name": "Finans",
						"percentage": 3.61
					},
					{
						"name": "Konsument, stabil",
						"percentage": 3.18
					}
				],
				"holdings": [
					{
						"name": "Linde PLC",
						"percentage": 3.89
					},
					{
						"name": "Air Liquide SA",
						"percentage": 3.86
					},
					{
						"name": "Republic Services",
						"percentage": 3.75
					},
					{
						"name": "Muenchener Rueckversicherungs-Gesellschaft AG",
						"percentage": 3.01
					},
					{
						"name": "Waste Management",
						"percentage": 2.99
					},
					{
						"name": "Synopsys",
						"percentage": 2.81
					},
					{
						"name": "Fortis",
						"percentage": 2.67
					},
					{
						"name": "Eversource Energy",
						"percentage": 2.60
					},
					{
						"name": "National Grid PLC",
						"percentage": 2.51
					},
					{
						"name": "Emerson Electric",
						"percentage": 2.38
					}
				],
				"description": "En aktiefond som placerar på de globala börserna. Placeringarna görs i företag som direkt eller indirekt är involverade i satsningar som leder till en effektivare användning av världens resurser. Exempel på sådana områden är resurseffektiva tekniker, utsläppskontroll, vatten- och avfallshantering, förnyelsebar energi, avancerade material, biobränsle och jordbruk. Fonden har innehav i ca 50 företag. Antalet kan variera. Fonden omfattas av Nordeas policy för Ansvarsfulla investeringar."
			},
			{
				"name": "Schroder ISF Glbl Sust Gr A Acc USD",
				"isin": "LU0557290698",
				"yearly_fee_pp": 1.64,
				"currecy": "USD",
				"risk": 4,
				"morningstar_rating": 5,
				"geographical_regions":
					[
						{
							"name": "USA",
							"percentage": 36.58
						},
						{
							"name": "Storbritannien",
							"percentage": 15.95
						},
						{
							"name": "Euroland",
							"percentage": 11.21
						},
						{
							"name": "Europa exkl. euroland",
							"percentage": 10.63
						},
						{
							"name": "Asien",
							"percentage": 9.00
						}
					],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 97.2
						},
						{
							"name": "Kort ränta",
							"percentage": 2.8
						}
					],
				"sectors": [
					{
						"name": "Finans",
						"percentage": 22.17
					},
					{
						"name": "Teknik",
						"percentage": 20.00
					},
					{
						"name": "Sjukvård",
						"percentage": 19.65
					},
					{
						"name": "Industri",
						"percentage": 12.25
					},
					{
						"name": "Konsument, stabil",
						"percentage": 10.05
					},
					{
						"name": "Konsument, cyklisk",
						"percentage": 8.22
					},
					{
						"name": "Kommunikation",
						"percentage": 6.14
					},
					{
						"name": "Råvaror",
						"percentage": 1.52
					}
				],
				"holdings": [
					{
						"name": "Microsoft",
						"percentage": 5.53
					},
					{
						"name": "Schneider Electric SE",
						"percentage": 3.58
					},
					{
						"name": "Alphabet A",
						"percentage": 3.45
					},
					{
						"name": "Booking",
						"percentage": 3.29
					},
					{
						"name": "Thermo Fisher Scientific",
						"percentage": 3.25
					},
					{
						"name": "DBS Group Holdings Ltd",
						"percentage": 3.19
					},
					{
						"name": "Toronto Dominion Bank",
						"percentage": 3.14
					},
					{
						"name": "Texas Instruments",
						"percentage": 3.00
					},
					{
						"name": "AIA Group Ltd",
						"percentage": 3.00
					},
					{
						"name": "AstraZeneca",
						"percentage": 2.81
					}
				],
				"description": "Minst två tredjedelar av fonden (exklusive kontanta medel) kommer att investeras i aktier i företag över hela världen. Fonden har ingen inriktning mot företag av en viss storlek. Vi investerar i företag som förväntas dra nytta av den positiva ekonomiska effekten av demografiska trender i världsekonomin. Fonden riktar in sig på företag i båda ändarna av det demografiska spektrumet, d.v.s. åldrande utvecklade marknadsekonomier och utvecklingsekonomier med tillväxt och ökande välstånd. Vi investerar vanligtvis i företag i konsument-, hälsovårds- och finanssektorn"
			},
			{
				"name": "Coeli Global Select R SEK",
				"isin": "LU1133292463",
				"yearly_fee_pp": 1.57,
				"currecy": "SEK",
				"risk": 4,
				"morningstar_rating": 4,
				"geographical_regions":
					[
						{
							"name": "USA",
							"percentage": 51.63
						},
						{
							"name": "Europa exkl. euroland",
							"percentage": 23.44
						},
						{
							"name": "Euroland",
							"percentage": 18.34
						},
						{
							"name": "Asien tillväxt",
							"percentage": 5.39
						},
						{
							"name": "Latinamerika",
							"percentage": 1.09
						}
					],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 97.4
						},
						{
							"name": "Kort ränta",
							"percentage": 2.6
						}
					],
				"sectors": [
					{
						"name": "Teknik",
						"percentage": 25.11
					},
					{
						"name": "Finans",
						"percentage": 22.17
					},
					{
						"name": "Sjukvård",
						"percentage": 22.00
					},
					{
						"name": "Industri",
						"percentage": 9.78
					},
					{
						"name": "Råvaror",
						"percentage": 7.13
					},
					{
						"name": "Konsument, cyklisk",
						"percentage": 4.05
					},
					{
						"name": "Konsument, stabil",
						"percentage": 3.68
					},
					{
						"name": "Fastigheter",
						"percentage": 3.05
					},
					{
						"name": "Kommunikation",
						"percentage": 3.02
					}
				],
				"holdings": [
					{
						"name": "Mastercard",
						"percentage": 4.27
					},
					{
						"name": "S&P Global",
						"percentage": 4.21
					},
					{
						"name": "HCA Healthcare",
						"percentage": 4.17
					},
					{
						"name": "Adobe",
						"percentage": 4.08
					},
					{
						"name": "AutoNation",
						"percentage": 3.90
					},
					{
						"name": "UnitedHealth Group",
						"percentage": 3.89
					},
					{
						"name": "Martin Marietta Materials",
						"percentage": 3.79
					},
					{
						"name": "Atlas Copco AB ser. B",
						"percentage": 3.63
					},
					{
						"name": "Thermo Fisher Scientific",
						"percentage": 3.62
					},
					{
						"name": "L'Oreal",
						"percentage": 3.54
					}
				],
				"description": "Coeli Global Select är en aktivt förvaltad aktiefond med global inriktning. Fondens målsättning är att skapa en hög positiv avkastning över tid för andelsägarna, till en relativt låg risk. Förvaltningsstrategin fokuserar på att hitta runt 30 bolag av hög kvalitet med goda framtidsutsikter och en attraktiv värdering. En viktig del i arbetet med att hitta de bästa bolagen är att göra företagsbesök och träffa ledningen och andra företagsrepresentanter. Mer information finns på coeli.se/global"
			},
			{
				"name": "Skandia USA",
				"isin": "SE0000810798",
				"yearly_fee_pp": 1.40,
				"currecy": "SEK",
				"risk": 4,
				"morningstar_rating": 3,
				"geographical_regions":
					[
						{
							"name": "USA",
							"percentage": 100.00
						}
					],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 96.3
						},
						{
							"name": "Kort ränta",
							"percentage": 3.7
						}
					],
				"sectors": [
					{
						"name": "Teknik",
						"percentage": 27.49
					},
					{
						"name": "Sjukvård",
						"percentage": 25.09
					},
					{
						"name": "Finans",
						"percentage": 15.33
					},
					{
						"name": "Industri",
						"percentage": 12.40
					},
					{
						"name": "Konsument, cyklisk",
						"percentage": 7.88
					},
					{
						"name": "Konsument, stabil",
						"percentage": 6.58
					},
					{
						"name": "Fastigheter",
						"percentage": 2.74
					},
					{
						"name": "Råvaror",
						"percentage": 1.26
					},
					{
						"name": "Kommunikation",
						"percentage": 1.23
					}
				],
				"holdings": [
					{
						"name": "Microsoft",
						"percentage": 9.62
					},
					{
						"name": "Visa",
						"percentage": 3.93
					},
					{
						"name": "UnitedHealth Group",
						"percentage": 3.68
					},
					{
						"name": "Analog Devices",
						"percentage": 3.23
					},
					{
						"name": "Johnson & Johnson",
						"percentage": 3.11
					},
					{
						"name": "PNC Financial Services Group",
						"percentage": 2.99
					},
					{
						"name": "Procter & Gamble",
						"percentage": 2.90
					},
					{
						"name": "Commerce Bancshares",
						"percentage": 2.75
					},
					{
						"name": "Danaher",
						"percentage": 2.68
					},
					{
						"name": "Salesforce",
						"percentage": 2.68
					}
				],
				"description": "Skandia USA är en aktiefond med en bred inriktning. Fonden investerar i amerikanska aktier. Investeringarna fördelas över olika branscher, främst i de större börsföretagen. Fonden har hållbarhetsfokus och investerar företrädelsevis i bolag som arbetar för miljö och samhälle, både på kort och på lång sikt. Fonden väljer bort bolag som kränker internationella normer, utvinner eller genererar kraft från fossila bränslen, producerar tobak eller vapen. Fonden investerar inte heller i bolag som är involverade i cannabis för rekreationsbruk och har en mycket restriktiv hållning gentemot bolag involverade i medicinsk cannabis."
			},
			{
				"name": "Carnegie Listed Private Equity A",
				"isin": "SE0003039874",
				"yearly_fee_pp": 1.50,
				"currecy": "SEK",
				"risk": 4,
				"morningstar_rating": 4,
				"geographical_regions":
					[
						{
							"name": "USA",
							"percentage": 62.46
						},
						{
							"name": "Euroland",
							"percentage": 12.32
						},
						{
							"name": "Kanada",
							"percentage": 8.78
						},
						{
							"name": "Storbritannien",
							"percentage": 6.35
						},
						{
							"name": "Japan",
							"percentage": 4.61
						}
					],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 64.3
						},
						{
							"name": "Övrigt",
							"percentage": 33.1
						},
						{
							"name": "Kort ränta",
							"percentage": 3.7
						}
					],
				"sectors": [
					{
						"name": "Finans",
						"percentage": 88.87
					},
					{
						"name": "Allmännyttigt",
						"percentage": 3.77
					},
					{
						"name": "Kommunikation",
						"percentage": 3.52
					},
					{
						"name": "Industri",
						"percentage": 1.53
					},
					{
						"name": "Sjukvård",
						"percentage": 1.37
					},
					{
						"name": "Råvaror",
						"percentage": 0.95
					}
				],
				"holdings": [
					{
						"name": "Apollo Gobal Management",
						"percentage": 7.41
					},
					{
						"name": "KKR & Co",
						"percentage": 7.19
					},
					{
						"name": "Blackstone",
						"percentage": 7.05
					},
					{
						"name": "Oakley Capital Investments Ord",
						"percentage": 6.06
					},
					{
						"name": "Berkshire Hathaway B",
						"percentage": 5.46
					},
					{
						"name": "HgCapital Trust Ord",
						"percentage": 4.97
					},
					{
						"name": "3i Group PLC",
						"percentage": 4.90
					},
					{
						"name": "Ares Management A",
						"percentage": 4.66
					},
					{
						"name": "Harbourvest Global Private Equity",
						"percentage": 4.01
					},
					{
						"name": "EURAZEO",
						"percentage": 3.95
					}
				],
				"description": "Carnegie Listed Private Equity investerar globalt i börsnoterade private equity-bolag, med stor spridning över olika branscher och geografier."
			},
			{
				"name": "Fidelity Asia Pacific Opps A-Acc-EUR",
				"isin": "LU0345361124",
				"yearly_fee_pp": 1.90,
				"currecy": "EUR",
				"risk": 4,
				"morningstar_rating": 5,
				"geographical_regions":
					[
						{
							"name": "Asien tillväxt",
							"percentage": 30.76
						},
						{
							"name": "Asien",
							"percentage": 28.06
						},
						{
							"name": "Australasien",
							"percentage": 11.92
						},
						{
							"name": "Euroland",
							"percentage": 11.91
						},
						{
							"name": "Kanada",
							"percentage": 7.57
						}
					],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 94.5
						},
						{
							"name": "Kort ränta",
							"percentage": 5.5
						}
					],
				"sectors": [
					{
						"name": "Teknik",
						"percentage": 29.8
					},
					{
						"name": "Finans",
						"percentage": 16.69
					},
					{
						"name": "Råvaror",
						"percentage": 16.24
					},
					{
						"name": "Industri",
						"percentage": 8.9
					},
					{
						"name": "Konsument, cyklisk",
						"percentage": 6.42
					},
					{
						"name": "Kommunikation",
						"percentage": 6.01
					},
					{
						"name": "Sjukvård",
						"percentage": 5.73
					},
					{
						"name": "Konsument, stabil",
						"percentage": 5.59
					},
					{
						"name": "Energi",
						"percentage": 4.61
					}
				],
				"holdings": [
					{
						"name": "Taiwan Semiconductor Manufacturing Co Ltd",
						"percentage": 7.7
					},
					{
						"name": "Franco-Nevada",
						"percentage": 7.16
					},
					{
						"name": "ASML Holding NV",
						"percentage": 6.4
					},
					{
						"name": "Kweichow Moutai Co Ltd Class A",
						"percentage": 4.67
					},
					{
						"name": "SK Hynix Inc",
						"percentage": 4.66
					},
					{
						"name": "HDFC Bank Ltd",
						"percentage": 4.37
					},
					{
						"name": "CSL Ltd",
						"percentage": 4.16
					},
					{
						"name": "Techtronic Industries Co Ltd",
						"percentage": 3.98
					},
					{
						"name": "AIA Group Ltd",
						"percentage": 3.86
					},
					{
						"name": "Fidelity ILF - USD A Acc",
						"percentage": 3.76
					}
				],
				"description": "Minst 70 procent investeras i aktier i företag med säte eller huvuddelen av sin verksamhet i länder i Asien/Stillahavsregionen, med undantag för Japan. Fonden får investera utanför fondens huvudsakliga geografiska områden, marknadssektorer, industrier eller tillgångsklasser."
			},
			{
				"name": "Invesco Pacific Equity A USD AD",
				"isin": "LU1775963454",
				"yearly_fee_pp": 1.99,
				"currecy": "USD",
				"risk": 4,
				"morningstar_rating": 4,
				"geographical_regions":
					[
						{
							"name": "Asien tillväxt",
							"percentage": 35.99
						},
						{
							"name": "Japan",
							"percentage": 32.08
						},
						{
							"name": "Asien",
							"percentage": 25.76
						},
						{
							"name": "Australasien",
							"percentage": 6.17
						}
					],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 98.1
						},
						{
							"name": "Övrigt",
							"percentage": 1.5
						},
						{
							"name": "Kort ränta",
							"percentage": 0.3
						}
					],
				"sectors": [
					{
						"name": "Teknik",
						"percentage": 22.42
					},
					{
						"name": "Finans",
						"percentage": 21.23
					},
					{
						"name": "Konsument, cyklisk",
						"percentage": 16.34
					},
					{
						"name": "Kommunikation",
						"percentage": 13.7
					},
					{
						"name": "Industri",
						"percentage": 9.41
					},
					{
						"name": "Råvaror",
						"percentage": 6.61
					},
					{
						"name": "Fastigheter",
						"percentage": 4.01
					},
					{
						"name": "Sjukvård",
						"percentage": 2.59
					},
					{
						"name": "Energi",
						"percentage": 1.85
					},
					{
						"name": "Konsument, stabil",
						"percentage": 1.84
					}
				],
				"holdings": [
					{
						"name": "Taiwan Semiconductor Manufacturing Co Ltd",
						"percentage": 5.57
					},
					{
						"name": "Samsung Electronics Co Ltd",
						"percentage": 4.46
					},
					{
						"name": "Tencent Holdings Ltd",
						"percentage": 4.23
					},
					{
						"name": "Alibaba Group Holding Ltd Ordinary Shares",
						"percentage": 2.63
					},
					{
						"name": "NetEase Inc Ordinary Shares",
						"percentage": 2.61
					},
					{
						"name": "Sumitomo Mitsui Financial Group Inc",
						"percentage": 2.39
					},
					{
						"name": "Kasikornbank Public Co Ltd",
						"percentage": 2.31
					},
					{
						"name": "Sony Group Corp",
						"percentage": 2.27
					},
					{
						"name": "Shin-Etsu Chemical Co Ltd",
						"percentage": 2.23
					},
					{
						"name": "Murata Manufacturing Co Ltd",
						"percentage": 2.15
					}
				],
				"description": "The Fund aims to achieve long-term capital growth. The Fund seeks to achieve its objective by investing primarily in equity and equity related securities of (i) companies having their registered office in the Asia Pacific region, (ii) companies with their registered office outside this region carrying out their business activities predominantly in the Asia Pacific region, or (iii) holding companies, the interests of which are predominantly invested in companies with their registered office in the Asia Pacific region."
			},
			{
				"name": "Skandia Asien",
				"isin": "SE0000810756",
				"yearly_fee_pp": 1.66,
				"currecy": "SEK",
				"risk": 4,
				"morningstar_rating": 4,
				"geographical_regions":
					[
						{
							"name": "Asien tillväxt",
							"percentage": 66.19
						},
						{
							"name": "Asien",
							"percentage": 33.81
						}
					],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 99.3
						},
						{
							"name": "Kort ränta",
							"percentage": 0.7
						}
					],
				"sectors": [
					{
						"name": "Teknik",
						"percentage": 21.18
					},
					{
						"name": "Finans",
						"percentage": 19.45
					},
					{
						"name": "Konsument, cyklisk",
						"percentage": 17.35
					},
					{
						"name": "Industri",
						"percentage": 12.77
					},
					{
						"name": "Kommunikation",
						"percentage": 10.42
					},
					{
						"name": "Konsument, stabil",
						"percentage": 6.47
					},
					{
						"name": "Fastigheter",
						"percentage": 5.63
					},
					{
						"name": "Sjukvård",
						"percentage": 3.68
					},
					{
						"name": "Energi",
						"percentage": 3.05
					}
				],
				"holdings": [
					{
						"name": "Taiwan Semiconductor Manufacturing Co Ltd",
						"percentage": 9.05
					},
					{
						"name": "Samsung Electronics Co Ltd",
						"percentage": 7.55
					},
					{
						"name": "Tencent Holdings Ltd",
						"percentage": 6.88
					},
					{
						"name": "Alibaba Group Holding Ltd Ordinary Shares",
						"percentage": 4.50
					},
					{
						"name": "Reliance Industries Ltd",
						"percentage": 3.00
					},
					{
						"name": "ICICI Bank Ltd",
						"percentage": 2.78
					},
					{
						"name": "Meituan Class B",
						"percentage": 2.61
					},
					{
						"name": "PT Bank Central Asia Tbk",
						"percentage": 2.18
					},
					{
						"name": "Trip.com Group Ltd",
						"percentage": 2.08
					},
					{
						"name": "China Pacific Insurance (Group) Co Ltd Class H",
						"percentage": 2.04
					}
				],
				"description": "Skandia Asien är en aktiefond med en bred inriktning. Fonden investerar i bolag som har sin huvudsakliga verksamhet i Asien, exklusive Japan. Investeringarna fördelas över olika branscher och länder, främst i de större börsföretagen. Fonden investerar inte i bolag som bryter mot internationella hållbarhetsrelaterade normer (t ex FN Global Compact) utan att visa förändringsvilja, är involverade i kontroversiella vapen eller producerar tobak. Fonden investerar inte heller i bolag som är involverade i cannabis för rekreationsbruk och har en mycket restriktiv hållning gentemot bolag involverade i medicinsk cannabis. Vidare avstår fonden från att investera i bolag som utvinner kol för energiändamål."
			},
			{
				"name": "C WorldWide Emerging Markets 1A",
				"isin": "LU0086737482",
				"yearly_fee_pp": 1.95,
				"currecy": "SEK",
				"risk": 5,
				"morningstar_rating": 3,
				"geographical_regions":
					[
						{
							"name": "Asien tillväxt",
							"percentage": 56.04
						},
						{
							"name": "Asien",
							"percentage": 28.63
						},
						{
							"name": "Latinamerika",
							"percentage": 13.80
						},
						{
							"name": "USA",
							"percentage": 1.53
						}
					],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 100
						}
					],
				"sectors": [
					{
						"name": "Teknik",
						"percentage": 25.57
					},
					{
						"name": "Finans",
						"percentage": 19.75
					},
					{
						"name": "Konsument, cyklisk",
						"percentage": 19.16
					},
					{
						"name": "Industri",
						"percentage": 19.01
					},
					{
						"name": "Kommunikation",
						"percentage": 6.88
					},
					{
						"name": "Fastigheter",
						"percentage": 3.02
					},
					{
						"name": "Råvaror",
						"percentage": 2.59
					},
					{
						"name": "Konsument, stabil",
						"percentage": 2.02
					},
					{
						"name": "Sjukvård",
						"percentage": 2.00
					}
				],
				"holdings": [
					{
						"name": "Samsung Electronics Co Ltd Participating Preferred",
						"percentage": 7.62
					},
					{
						"name": "Taiwan Semiconductor Manufacturing Co Ltd",
						"percentage": 6.69
					},
					{
						"name": "Tencent Holdings Ltd",
						"percentage": 6.15
					},
					{
						"name": "PT Bank Rakyat Indonesia (Persero) Tbk Class B",
						"percentage": 3.62
					},
					{
						"name": "Airtac International Group",
						"percentage": 3.48
					},
					{
						"name": "Chailease Holding Co Ltd",
						"percentage": 3.15
					},
					{
						"name": "Jiangsu Hengli Hydraulic Co Ltd Class A",
						"percentage": 2.73
					},
					{
						"name": "Banco BTG Pactual S.A. Units Cons of 1 Sh ' 2 Pfd Shs -A-",
						"percentage": 2.45
					},
					{
						"name": "HDFC Bank ADR",
						"percentage": 2.44
					},
					{
						"name": "ICICI Bank ADR",
						"percentage": 2.36
					}
				],
				"description": "C WorldWide Emerging Markets Equities ger en exponering mot tillväxtmarknader över hela världen som kännetecknas av höga tillväxttal drivet av reformer och en ung och växande befolkning. C WorldWide Emerging Markets kan investera i utvecklingsekonomier i Latinamerika, Central- och Östeuropa, Afrika, Mellanöstern och Asien (exklusive Japan)."
			},
			{
				"name": "BGF Asian Dragon A2",
				"isin": "LU0072462343",
				"yearly_fee_pp": 1.90,
				"currecy": "USD",
				"risk": 4,
				"morningstar_rating": 4,
				"geographical_regions":
					[
						{
							"name": "Asien tillväxt",
							"percentage": 56.90
						},
						{
							"name": "Asien",
							"percentage": 37.68
						},
						{
							"name": "USA",
							"percentage": 2.84
						},
						{
							"name": "Storbritannien",
							"percentage": 2.58
						}
					],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 96.8
						},
						{
							"name": "Kort ränta",
							"percentage": 3.2
						}
					],
				"sectors": [
					{
						"name": "Finans",
						"percentage": 24.48
					},
					{
						"name": "Teknik",
						"percentage": 24.00
					},
					{
						"name": "Kommunikation",
						"percentage": 12.67
					},
					{
						"name": "Konsument, cyklisk",
						"percentage": 12.51
					},
					{
						"name": "Industri",
						"percentage": 6.53
					},
					{
						"name": "Energi",
						"percentage": 4.57
					},
					{
						"name": "Fastigheter",
						"percentage": 3.91
					},
					{
						"name": "Allmännyttigt",
						"percentage": 3.78
					},
					{
						"name": "Konsument, stabil",
						"percentage": 3.72
					},
					{
						"name": "Råvaror",
						"percentage": 2.71
					},
					{
						"name": "Sjukvård",
						"percentage": 1.13
					}
				],
				"holdings": [
					{
						"name": "Taiwan Semiconductor Manufacturing Co Ltd",
						"percentage": 9.08
					},
					{
						"name": "Samsung Electronics Co Ltd",
						"percentage": 5.39
					},
					{
						"name": "BlackRock ICS USD LiqEnvtlyAwr Agcy Inc",
						"percentage": 4.64
					},
					{
						"name": "Tencent Holdings Ltd",
						"percentage": 4.62
					},
					{
						"name": "Alibaba Group Holding Ltd Ordinary Shares",
						"percentage": 4.13
					},
					{
						"name": "NetEase Inc Ordinary Shares",
						"percentage": 2.75
					},
					{
						"name": "Meituan Class B",
						"percentage": 2.74
					},
					{
						"name": "China Mengniu Dairy Co Ltd",
						"percentage": 2.35
					},
					{
						"name": "Bank Of China Ltd Class H",
						"percentage": 2.10
					},
					{
						"name": "Li Ning Co Ltd",
						"percentage": 2.09
					}
				],
				"description": "Fonden investerar minst 70 procent av de totala tillgångarna i värdepapper (t.ex. aktier) i företag hemmahörande, eller som bedriver sin huvudsakliga verksamhet i Asien utom Japan. Många av dessa länder är utvecklingsländer."
			},
			{
				"name": "Öhman Emerging Markets A",
				"isin": "SE0004578615",
				"yearly_fee_pp": 0.93,
				"currecy": "SEK",
				"risk": 4,
				"morningstar_rating": 4,
				"geographical_regions":
					[
						{
							"name": "Asien tillväxt",
							"percentage": 45.02
						},
						{
							"name": "Asien",
							"percentage": 35.77
						},
						{
							"name": "Latinamerika",
							"percentage": 11.10
						},
						{
							"name": "Afrika",
							"percentage": 5.97
						},
						{
							"name": "Europa tillväxt",
							"percentage": 1.50
						}
					],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 100
						}
					],
				"sectors": [
					{
						"name": "Finans",
						"percentage": 25.37
					},
					{
						"name": "Teknik",
						"percentage": 23.42
					},
					{
						"name": "Kommunikation",
						"percentage": 13.61
					},
					{
						"name": "Konsument, cyklisk",
						"percentage": 12.97
					},
					{
						"name": "Råvaror",
						"percentage": 6.54
					},
					{
						"name": "Industri",
						"percentage": 6.15
					},
					{
						"name": "Sjukvård",
						"percentage": 4.24
					},
					{
						"name": "Konsument, stabil",
						"percentage": 4.23
					},
					{
						"name": "Fastigheter",
						"percentage": 2.17
					},
					{
						"name": "Allmännyttigt",
						"percentage": 1.30
					}
				],
				"holdings": [
					{
						"name": "Taiwan Semiconductor Manufacturing Co Ltd",
						"percentage": 7.03
					},
					{
						"name": "Samsung Electronics Co Ltd",
						"percentage": 5.15
					},
					{
						"name": "Tencent Holdings Ltd",
						"percentage": 5.06
					},
					{
						"name": "Alibaba Group Holding Ltd Ordinary Shares",
						"percentage": 3.23
					},
					{
						"name": "Meituan Class B",
						"percentage": 2.17
					},
					{
						"name": "China Construction Bank Corp Class H",
						"percentage": 1.86
					},
					{
						"name": "ICICI Bank ADR",
						"percentage": 1.71
					},
					{
						"name": "Infosys ADR",
						"percentage": 1.52
					},
					{
						"name": "Ping An Insurance (Group) Co. of China Ltd Class H",
						"percentage": 1.32
					}
				],
				"description": "Fonden är en indexnära aktiefond vars målsättning är att med god riskspridning, på lång sikt, spegla utvecklingen för världens tillväxtmarknader, definierade som fondens jämförelseindex. Fonden förvaltas hållbart. Fonden har specifika och uttalade kriterier för att investera i bolag utifrån miljö, sociala aspekter och bolagsstyrning (ESG). Analys av bolagens hållbarhetsarbete är avgörande för valet av bolag i fonden. Mer information om hållbarhetsarbetet finns i fondens informationsbroschyr. Fondens jämförelseindex är MSCI Emerging Markets Index."
			},
			{
				"name": "Fidelity Global Technology A-Dis-USD",
				"isin": "LU1046421795",
				"yearly_fee_pp": 1.88,
				"currecy": "USD",
				"risk": 5,
				"morningstar_rating": 5,
				"geographical_regions":
					[
						{
							"name": "USA",
							"percentage": 62.22
						},
						{
							"name": "Asien",
							"percentage": 10.87
						},
						{
							"name": "Euroland",
							"percentage": 9.21
						},
						{
							"name": "Japan",
							"percentage": 6.46
						},
						{
							"name": "Storbritannien",
							"percentage": 4.49
						}
					],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 97.6
						},
						{
							"name": "Kort ränta",
							"percentage": 2.3
						},
						{
							"name": "Övrigt",
							"percentage": 0.1
						}
					],
				"sectors": [
					{
						"name": "Teknik",
						"percentage": 65.51
					},
					{
						"name": "Kommunikation",
						"percentage": 12.50
					},
					{
						"name": "Konsument, cyklisk",
						"percentage": 10.84
					},
					{
						"name": "Industri",
						"percentage": 5.38
					},
					{
						"name": "Finans",
						"percentage": 2.37
					},
					{
						"name": "Energi",
						"percentage": 1.72
					},
					{
						"name": "Fastigheter",
						"percentage": 1.38
					},
					{
						"name": "Konsument, stabil",
						"percentage": 0.31
					}
				],
				"holdings": [
					{
						"name": "Microsoft",
						"percentage": 5.23
					},
					{
						"name": "Apple",
						"percentage": 4.97
					},
					{
						"name": "Ericsson, Telefonab. L M ser. B",
						"percentage": 3.12
					},
					{
						"name": "Alphabet A",
						"percentage": 3.00
					},
					{
						"name": "Amazon.com",
						"percentage": 2.98
					},
					{
						"name": "Salesforce",
						"percentage": 2.88
					},
					{
						"name": "QUALCOMM",
						"percentage": 2.77
					},
					{
						"name": "Fidelity ILF - USD A Acc",
						"percentage": 2.10
					},
					{
						"name": "Rolls-Royce Holdings PLC",
						"percentage": 2.07
					},
					{
						"name": "Sap SE",
						"percentage": 1.94
					}
				],
				"description": "Fonden investerar i företag som har utvecklat eller kommer att utveckla teknologirelaterade produkter, processer och tjänster, samt i företag som kan förväntas dra nytta av tekniska framsteg. Fonden är bred och investerar bland annat i delområden såsom datorer, halvledare, teknisk utrustning och Internetaccess. Förvaltaren har ingen specifik inriktning avseende värde eller tillväxt."
			},
			{
				"name": "Skandia Time Global",
				"isin": "SE0001112715",
				"yearly_fee_pp": 1.40,
				"currecy": "SEK",
				"risk": 4,
				"morningstar_rating": 5,
				"geographical_regions": [
					{
						"name": "USA",
						"percentage": 63.47
					},
					{
						"name": "Europa exkl. euroland",
						"percentage": 16.86
					},
					{
						"name": "Euroland",
						"percentage": 11.57
					},
					{
						"name": "Japan",
						"percentage": 4.69
					},
					{
						"name": "Mellanöstern",
						"percentage": 1.86
					}
				],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 97.2
						},
						{
							"name": "Kort ränta",
							"percentage": 2.8
						}
					],
				"sectors": [
					{
						"name": "Teknik",
						"percentage": 59.87
					},
					{
						"name": "Kommunikation",
						"percentage": 31.67
					},
					{
						"name": "Finans",
						"percentage": 5.68
					},
					{
						"name": "Konsument, cyklisk",
						"percentage": 2.78
					}
				],
				"holdings": [
					{
						"name": "Microsoft",
						"percentage": 9.26
					},
					{
						"name": "Alphabet A",
						"percentage": 7.03
					},
					{
						"name": "Ericsson, Telefonab. L M ser. B",
						"percentage": 6.29
					},
					{
						"name": "Meta Platforms A",
						"percentage": 5.52
					},
					{
						"name": "Salesforce",
						"percentage": 4.12
					},
					{
						"name": "Apple",
						"percentage": 3.40
					},
					{
						"name": "Sony Group Corp",
						"percentage": 3.28
					},
					{
						"name": "Take-Two Interactive Software",
						"percentage": 2.94
					},
					{
						"name": "Nokia Corporation",
						"percentage": 2.74
					},
					{
						"name": "Visa",
						"percentage": 2.74
					}
				],
				"description": "Skandia Time Global är en aktiefond. Fonden investerar globalt i företag inom TIME-sektorerna telekom, information, media och underhållning (entertainment). Investeringarna fördelas över olika länder, främst i marknadsledande företag. Fonden investerar inte i bolag som bryter mot internationella hållbarhetsrelaterade normer (t ex FN Global Compact) utan att visa förändringsvilja, är involverade i kontroversiella vapen eller producerar tobak. Fonden investerar inte heller i bolag som är involverade i cannabis för rekreationsbruk och har en mycket restriktiv hållning gentemot bolag involverade i medicinsk cannabis. Vidare avstår fonden från att investera i bolag som utvinner kol för energiändamål."
			},
			{
				"name": "BGF Next Generation Technology A2",
				"isin": "LU1861215975",
				"yearly_fee_pp": 1.80,
				"currecy": "USD",
				"risk": 5,
				"morningstar_rating": 1,
				"geographical_regions": [
					{
						"name": "USA",
						"percentage": 52.33
					},
					{
						"name": "Asien tillväxt",
						"percentage": 12.80
					},
					{
						"name": "Euroland",
						"percentage": 10.96
					},
					{
						"name": "Asien",
						"percentage": 5.11
					},
					{
						"name": "Storbritannien",
						"percentage": 4.81
					}
				],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 94.1
						},
						{
							"name": "Kort ränta",
							"percentage": 3.1
						},
						{
							"name": "Övrig",
							"percentage": 2.7
						}
					],
				"sectors": [
					{
						"name": "Teknik",
						"percentage": 74.30
					},
					{
						"name": "Konsument, cyklisk",
						"percentage": 9.11
					},
					{
						"name": "Kommunikation",
						"percentage": 8.83
					},
					{
						"name": "Industri",
						"percentage": 6.30
					},
					{
						"name": "Fastigheter",
						"percentage": 0.75
					},
					{
						"name": "Sjukvård",
						"percentage": 0.71
					}
				],
				"holdings": [
					{
						"name": "Synopsys",
						"percentage": 3.24
					},
					{
						"name": "BlackRock ICS USD LiqEnvtlyAwr Agcy Inc",
						"percentage": 2.77
					},
					{
						"name": "NVIDIA",
						"percentage": 2.33
					},
					{
						"name": "ASM International NV",
						"percentage": 2.31
					},
					{
						"name": "Lattice Semiconductor",
						"percentage": 2.22
					},
					{
						"name": "Lasertec Corp",
						"percentage": 2.15
					},
					{
						"name": "Informa PLC",
						"percentage": 2.08
					},
					{
						"name": "Wolfspeed",
						"percentage": 2.04
					},
					{
						"name": "Pure Storage A",
						"percentage": 2.01
					},
					{
						"name": "Samsung SDI Co Ltd",
						"percentage": 2.00
					}
				],
				"description": "The Fund seeks to maximise total return. The Fund invests at least 70% of its total assets in the equity securities of companies globally whose predominant economic activity comprises the research, development, production and/or distribution of new and emerging technology."
			},
			{
				"name": "Allianz Global Artfcl Intlgc AT USD",
				"isin": "LU1548497426",
				"yearly_fee_pp": 2.10,
				"currecy": "USD",
				"risk": 5,
				"morningstar_rating": 3,
				"geographical_regions": [
					{
						"name": "USA",
						"percentage": 83.44
					},
					{
						"name": "Asien tillväxt",
						"percentage": 5.05
					},
					{
						"name": "Asien",
						"percentage": 3.46
					},
					{
						"name": "Kanada",
						"percentage": 3.08
					},
					{
						"name": "Euroland",
						"percentage": 2.71
					}
				],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 96.8
						},
						{
							"name": "Kort ränta",
							"percentage": 2.9
						},
						{
							"name": "Lång ränta",
							"percentage": 0.1
						}
					],
				"sectors": [
					{
						"name": "Teknik",
						"percentage": 64.26
					},
					{
						"name": "Kommunikation",
						"percentage": 13.85
					},
					{
						"name": "Konsument, cyklisk",
						"percentage": 13.40
					},
					{
						"name": "Sjukvård",
						"percentage": 6.40
					},
					{
						"name": "Industri",
						"percentage": 1.67
					},
					{
						"name": "Finans",
						"percentage": 0.34
					},
					{
						"name": "Råvaror",
						"percentage": 0.05
					},
					{
						"name": "Fastigheter",
						"percentage": 0.02
					},
					{
						"name": "Allmännyttigt",
						"percentage": 0.02
					}
				],
				"holdings": [
					{
						"name": "NVIDIA",
						"percentage": 5.21
					},
					{
						"name": "Tesla",
						"percentage": 5.02
					},
					{
						"name": "Meta Platforms A",
						"percentage": 4.97
					},
					{
						"name": "Microsoft",
						"percentage": 4.91
					},
					{
						"name": "ON Semiconductor",
						"percentage": 4.71
					},
					{
						"name": "Amazon.com",
						"percentage": 4.03
					},
					{
						"name": "Marvell Technology",
						"percentage": 3.41
					},
					{
						"name": "Enphase Energy",
						"percentage": 2.81
					},
					{
						"name": "Alibaba Group Holding Ltd Ordinary Shares",
						"percentage": 2.79
					},
					{
						"name": "Anthem",
						"percentage": 2.48
					}
				],
				"description": "Long-term capital growth by investing in the global equity markets with a focus on the evolution of artificial intelligence."
			},
			{
				"name": "Lannebo Teknik",
				"isin": "SE0000740672",
				"yearly_fee_pp": 1.60,
				"currecy": "SEK",
				"risk": 4,
				"morningstar_rating": 4,
				"geographical_regions": [
					{
						"name": "USA",
						"percentage": 87.59
					},
					{
						"name": "Euroland",
						"percentage": 5.97
					},
					{
						"name": "Japan",
						"percentage": 3.37
					},
					{
						"name": "Europa exkl. euroland",
						"percentage": 3.06
					}
				],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 95.2
						},
						{
							"name": "Kort ränta",
							"percentage": 4.8
						}
					],
				"sectors": [
					{
						"name": "Teknik",
						"percentage": 68.59
					},
					{
						"name": "Kommunikation",
						"percentage": 15.72
					},
					{
						"name": "Konsument, cyklisk",
						"percentage": 6.54
					},
					{
						"name": "Finans",
						"percentage": 4.40
					},
					{
						"name": "Sjukvård",
						"percentage": 2.73
					},
					{
						"name": "Industri",
						"percentage": 2.02
					}
				],
				"holdings": [
					{
						"name": "Microsoft",
						"percentage": 8.12
					},
					{
						"name": "Alphabet A",
						"percentage": 5.84
					},
					{
						"name": "Palo Alto Networks",
						"percentage": 5.12
					},
					{
						"name": "Apple",
						"percentage": 4.64
					},
					{
						"name": "Microchip Technology",
						"percentage": 4.60
					},
					{
						"name": "Salesforce",
						"percentage": 4.53
					},
					{
						"name": "Amazon.com",
						"percentage": 4.37
					},
					{
						"name": "Mastercard",
						"percentage": 4.19
					},
					{
						"name": "IBM",
						"percentage": 3.96
					},
					{
						"name": "Adobe",
						"percentage": 3.70
					}
				],
				"description": "En investering i fonden ger dig en bred inriktning mot globala, marknadsledande teknikbolag. Lannebo Teknik har mer än 20 års historik och är en aktivt förvaltad fond med fokus på långsiktighet och riskmedvetenhet. Fondens förvaltare investerar i ledande teknikbolag över hela världen inom områden så som hälsa, säkerhet, resurseffektivisering och digitalt innehåll. Målsättningen är att genom förvaltarteamets områdesexpertis och fundamental aktieanalys skapa möjlighet till en god riskjusterad avkastning. Förvaltningen bedrivs inom Lannebo som är ett självständigt och helt oberoende fondbolag. Fonden investerar inte i bolag inom branscher så som fossila bränslen, tobak, pornografi och vapen. Lannebo har som fondbolag högsta möjliga hållbarhetsbetyg för sin aktieförvaltning, A+, från UN PRI."
			},
			{
				"name": "T. Rowe Price US Lg Cap Gr Eq A USD",
				"isin": "LU0174119429",
				"yearly_fee_pp": 1.60,
				"currecy": "USD",
				"risk": 6,
				"morningstar_rating": 3,
				"geographical_regions": [
					{
						"name": "USA",
						"percentage": 96.51
					},
					{
						"name": "Euroland",
						"percentage": 2.39
					},
					{
						"name": "Asien",
						"percentage": 0.56
					},
					{
						"name": "Japan",
						"percentage": 0.54
					}
				],
				"asset_classes":
					[
						{
							"name": "Aktier",
							"percentage": 99.6
						},
						{
							"name": "Kort ränta",
							"percentage": 0.4
						}
					],
				"sectors": [
					{
						"name": "Teknik",
						"percentage": 45.76
					},
					{
						"name": "Sjukvård",
						"percentage": 18.99
					},
					{
						"name": "Konsument, cyklisk",
						"percentage": 12.71
					},
					{
						"name": "Kommunikation",
						"percentage": 12.15
					},
					{
						"name": "Finans",
						"percentage": 5.66
					},
					{
						"name": "Industri",
						"percentage": 2.81
					},
					{
						"name": "Konsument, stabil",
						"percentage": 1.59
					},
					{
						"name": "Råvaror",
						"percentage": 0.33
					}
				],
				"holdings": [
					{
						"name": "Microsoft",
						"percentage": 9.68
					},
					{
						"name": "Apple",
						"percentage": 9.66
					},
					{
						"name": "Alphabet A",
						"percentage": 6.22
					},
					{
						"name": "Amazon.com",
						"percentage": 5.84
					},
					{
						"name": "UnitedHealth Group",
						"percentage": 4.30
					},
					{
						"name": "NVIDIA",
						"percentage": 4.06
					},
					{
						"name": "Intuit",
						"percentage": 3.73
					},
					{
						"name": "Fiserv",
						"percentage": 3.33
					},
					{
						"name": "Mastercard",
						"percentage": 3.03
					},
					{
						"name": "Cigna",
						"percentage": 2.80
					}
				],
				"description": "Fondens mål är att öka andelarnas värde genom att värdet på investeringarna ökar. På diskretionär grund investerar fonden främst i aktier och aktierelaterade värdepapper i stora amerikanska företag med en normalt sett måttlig risk och avkastning, och med ett totalt marknadsvärde inom eller över det intervall som täcks av Russell 1000 Index. Fonden inriktar sig främst på investeringar i företag som visar potential för hållbar vinsttillväxt över genomsnittet."
			}
		]
}

const otherValidCodes = [
    "SE0011527613", "SE0001718388", "US67066G1040", "SE0021021417", "DK0060012037",
    "LU2273159629", "XS1790134362", "SE0013358447", "LU1028961909", "SE0012454338",
    "SE0014956850", "SE0017084296", "SE0015811963", "SE0000379190", "SE0000242455",
    "SE0006993770", "SE0000667925", "US1912161007", "US4781601046", "US7427181091",
    "US7561091049", "US5801351017", "SE0000115446", "SE0012673267", "SE0015988019",
    "US0378331005", "US5949181045", "US7134481081", "US92826C8394", "SE0015961909",
    "US88160R1014", "US0231351067", "LU1778762911", "US69608A1088", "US8522341036",
    "SE0015346135", "US76954A1034", "SE0013358447", "DK0060012037", "XS1790134362"
];

const fundsInStatic = staticFundData.funds.map((f: any) => f.isin);
